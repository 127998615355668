import React from "react";
import { UserStatusCssClasses, UserStatusTitles } from "./TeamUIHelpers";

export default function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      row && row.is_active
        ? UserStatusCssClasses[row.is_active]
        : UserStatusCssClasses[0]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row && row.is_active ? UserStatusTitles[row.is_active] : UserStatusTitles[0]}
    </span>
  );
}
