import React from "react";
import {
  UserStatusCssClasses,
  UserStatusTitles,
} from "./UsersUIHelpers";

export default function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      (row && row.status ? UserStatusCssClasses[row.status] : UserStatusCssClasses[0])
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row && row.status ? UserStatusTitles[row.status] : UserStatusTitles[0]}
    </span>
  );
}