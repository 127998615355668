/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import UserDialog from "./UserDialog";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
// eslint-disable-next-line no-restricted-imports
import Popover from "@material-ui/core/Popover";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import CategoryFilterForm from "./CategoryFilterForm";

import SVG from "react-inlinesvg";

// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import { actions as intakeFormActions } from "../redux/intakeFormRedux";
import ActionsColumnFormatter from "./ActionsColumnFormatter";
// import StatusColumnFormatter from "./ActionsColumnFormatter";
// import TypeColumnFormatter from "./ActionsColumnFormatter";

export default function MoldingDoorList({ activetab }) {
  const dispatch = useDispatch();
  const {
    // getCategoriesDataLoading,
    listLoading,
    categoryList,
    totalCategoryRecords,
    currentPage,
    maxCategoryPerPage,
    categorySortField,
    categorySortOrder,
    intakeType,
  } = useSelector((state) => state.intakeForm);

  const [cookies] = useCookies(["accessToken"]);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showModal, setShowModal] = useState(false);  

  useEffect(() => {
    if (activetab === "molding-door-enhancement") {
      if (currentPage) {
        dispatch(
          intakeFormActions.changeCurrentPage(
            0,
            maxCategoryPerPage,
            cookies.accessToken
          )
        );
      } else {
        dispatch(
          intakeFormActions.getCategoryList(
            categorySortField,
            categorySortOrder,
            currentPage,
            maxCategoryPerPage,
            cookies.accessToken,
            activetab
          )
        );
      }
    }
  }, [activetab, categorySortField, categorySortOrder, maxCategoryPerPage]);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalCategoryRecords ? totalCategoryRecords : 0,
    sizePerPage: maxCategoryPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if(page !== 0){
        dispatch(
          intakeFormActions.changeCurrentPage(
            page - 1,
            maxCategoryPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== categorySortField || sortOrder !== categorySortOrder) {
        dispatch(
          intakeFormActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };
  const applyFilter = () => {
    dispatch(intakeFormActions.setFilter(true));
    dispatch(intakeFormActions.setCurrentPage(0));
    dispatch(
      intakeFormActions.getCategoryList(
        categorySortField,
        categorySortOrder,
        0,
        maxCategoryPerPage,
        cookies.accessToken,
        intakeType
      )
    );
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(intakeFormActions.closeCategoryDialog());
  };

  const openEditDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const deleteCategoryOption = (id) => {
    if (id) {
      if (window.confirm("Are you sure want to delete?")) {
        dispatch(
          intakeFormActions.deleteCategoryOption(id, cookies.accessToken)
        );
      }
    }
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: function(cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "visibility",
      text: "Visibility",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "assigned_to_all_client",
      text: "Is Default",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditDialog: (id) => openEditDialog(true, id),
        deleteCategoryOption: (id) => deleteCategoryOption(id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;
  return (
    <Card>
      <CardHeader style={{ border: 0 }} title="">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <CategoryFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-black-primary"
            onClick={() => openEditDialog(false, null)}
          >
            Add New
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter /> */}
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <Table
          listLoading={listLoading}
          entities={categoryList ? categoryList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalCategoryRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{
            dataField: categorySortField,
            order: categorySortOrder,
          }}
        />
      </CardBody>
      <UserDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
        headerTitle = {"Molding & Door Enhancements"}
      />
    </Card>
  );
}
