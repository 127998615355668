/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

import {
  Input,
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { actions as feedbackFormActions } from "../redux/feedbackFormRedux";
import Table from "../../../components/Table";
import FeedbackClientDialog from "../components/FeedbackClientDialog";
import FeedbackStatusColumnFormatter from "../components/FeedbackStatusColumnFormatter";

const FeedbackLinkSchema = () =>
  Yup.object().shape({
    feedbackLink: Yup.string().url("Feedback Link must be a valid URL"),
  });

export default function FeedbackLink() {
  const {
    feedbackLinkLoading,
    feedbackLink,
    nextFeedbackScheduled,
    feedbackListLoading,
    feedbackList,
    totalFeedbackRecords,
    maxFeedbackPerPage,
    currentPage,
    feedbackSortField,
    feedbackSortOrder,
  } = useSelector((state) => state.feedbackForm);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalFeedbackRecords || 0,
    sizePerPage: maxFeedbackPerPage,
    page: currentPage + 1,
  };

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const [selectedRow, setSelectedRow] = React.useState(null);

  useEffect(() => {
    dispatch(feedbackFormActions.getFeedbackLink(cookies.accessToken));
    dispatch(
      feedbackFormActions.getNextFeedbackScheduledDate(cookies.accessToken)
    );
  }, []);

  useEffect(() => {
    dispatch(
      feedbackFormActions.getFeedbackList(
        feedbackSortField,
        feedbackSortOrder,
        currentPage,
        maxFeedbackPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const saveEmailNotificationData = () => {
    dispatch(
      feedbackFormActions.updateFeedbackLinkData(
        feedbackLink,
        cookies.accessToken
      )
    );
  };

  const handleFormChange = (e) => {
    dispatch(feedbackFormActions.setFeedbackLink(e.target.value));
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      // formatter: function(cell, row) {
      //   return row.DT_RowIndex;
      // },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "title",
      text: "Title",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "scheduled_at",
      text: "Schedule at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "executed_at",
      text: "Executed at",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "clients_count",
      text: "Number of clients",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: FeedbackStatusColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <div className="action-buttons">
            {row.status === "success" || row.status === "fail" ? (
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => {
                  setSelectedRow(row);
                  dispatch(
                    feedbackFormActions.toggleFeedbackMoreDetailPopup(true)
                  );
                }}
              >
                View More Details
              </button>
            ) : null}
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          feedbackFormActions.changeCurrentPage(
            page - 1,
            maxFeedbackPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== feedbackSortField || sortOrder !== feedbackSortOrder) {
        dispatch(
          feedbackFormActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader title={"Feedback Form"}>
          {nextFeedbackScheduled && (
            <div style={{ display: "flex", height: 70, alignItems: "center" }}>
              <small>
                Next Scheduled: <b>{nextFeedbackScheduled}</b>
              </small>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Formik
            enableReinitialize={true}
            validationSchema={FeedbackLinkSchema}
            initialValues={{ feedbackLink }}
            handleChange={(e) => handleFormChange(e)}
            onSubmit={() => {
              saveEmailNotificationData();
            }}
          >
            {({ handleSubmit }) => (
              <>
                {feedbackLinkLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col">
                      <Field
                        type="text"
                        value={feedbackLink}
                        name="feedbackLink"
                        component={Input}
                        placeholder="Feedback Link"
                        label="Feedback Link"
                        onChange={(e) => handleFormChange(e)}
                        disabled={feedbackLinkLoading}
                      />
                    </div>
                    <div className="col-xs-2 col-lg-2">
                      <label htmlFor="feedbackLink-submit">&nbsp;</label>
                      <br />
                      <button
                        type="submit"
                        id="feedbackLink-submit"
                        onClick={() => !feedbackLinkLoading && handleSubmit()}
                        className="btn btn-primary btn-elevate"
                        disabled={feedbackLinkLoading}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <Table
                      listLoading={feedbackListLoading}
                      entities={feedbackList || []}
                      columns={tableColumns}
                      paginationOptions={paginationOptions}
                      totalCount={totalFeedbackRecords}
                      handleTableChange={(type, newState) =>
                        handleTableChange(type, newState)
                      }
                      sortState={{
                        dataField: feedbackSortField,
                        order: feedbackSortOrder,
                      }}
                    />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </CardBody>
      </Card>
      {selectedRow ? <FeedbackClientDialog row={selectedRow} setRow={setSelectedRow} /> : null}
    </>
  );
}
