import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form } from "react-bootstrap";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import Autosuggest from "react-autosuggest";
import ClientResourceList from "../components/ClientResourceList";
import _ from "lodash";

export default function ClientResources({ id, activetab }) {
  const dispatch = useDispatch();
  const {
    getClientApiLoading,
    addClientResourceLoading,
    addClientResourceSuccess,
    resourceSuggessionArr,
    resourceSuggession,
    removeClientResourceLoading,
    setPMClientResourceLoading,
  } = useSelector((state) => state.manageClients);
  const [cookies] = useCookies(["accessToken"]);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    if (
      addClientResourceLoading ||
      getClientApiLoading ||
      removeClientResourceLoading ||
      setPMClientResourceLoading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [
    addClientResourceLoading,
    getClientApiLoading,
    removeClientResourceLoading,
    setPMClientResourceLoading,
  ]);

  useEffect(() => {
    if (addClientResourceSuccess) {
      setSuggestion("");
      setValue("");
    }
  }, [addClientResourceSuccess]);

  const [value, setValue] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [pmChecked, setpmChecked] = useState(0);
  // const [reload, setReload] = useState(false);

  const callApi = (value) => {
    let searchValue = value;
    if (searchValue !== "") {
      dispatch(
        manageClientsActions.getResourceSuggession(value, cookies.accessToken)
      );
    } else {
      dispatch(manageClientsActions.setResourceSuggession([], []));
    }
  };
  const [debouncedCallApi] = useState(() => _.debounce(callApi, 1000));

  const onChangeSearch = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedCallApi(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {};
  const inputProps = {
    placeholder: "Search Resource",
    value,
    onChange: onChangeSearch,
  };

  const getSuggestionValue = (suggestion) => {
    setSuggestion(suggestion);
    return suggestion;
  };
  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
  const checkAsPM = (e) => {
    if (e.target.checked) {
      setpmChecked(1);
    } else {
      setpmChecked(0);
    }
  };
  const addResource = () => {
    let getId;
    for (var i = 0; i < resourceSuggession.length; i++) {
      if (resourceSuggession[i]["name"] === suggestion) {
        getId = resourceSuggession[i]["id"];
      }
    }
    if (getId) {
      dispatch(
        manageClientsActions.addClientResource(
          id,
          getId,
          pmChecked,
          cookies.accessToken
        )
      );
    }
  };

  return (
    <>
      <Modal.Body
        style={{ paddingTop: 0 }}
        className="overlay overlay-block cursor-default"
      >
        {formLoading && (
          <div
            style={{ zIndex: 999999 }}
            className="overlay-layer bg-transparent"
          >
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}

        <div className="form-group row">
          <Form>
            <Form.Group>
              <Form.Label>Resource:</Form.Label>
              <Autosuggest
                className="form-control"
                suggestions={resourceSuggessionArr}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                onClick={checkAsPM}
                type="checkbox"
                label="Make this resource a Project Manager for this client"
              />
            </Form.Group>
            <button
              type="button"
              onClick={addResource}
              className="btn btn-primary"
              disabled={formLoading}
            >
              Add Resource
            </button>
          </Form>
        </div>
        <div>
          <ClientResourceList clientID={id} activetab={activetab} />
        </div>
      </Modal.Body>
    </>
  );
}
