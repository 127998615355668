import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Input } from "../../../../_metronic/_partials/controls";
import { actions as manageRolesActions } from "../redux/manageRolesRedux";
import { useCookies } from "react-cookie";

export default function RoleFilterForm({ onHide, applyFilter }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const { rolesFilterData } = useSelector((state) => state.manageRoles);

  const handleFormChange = (e) => {
    let data = rolesFilterData;
    data[e.target.name] = e.target.value;
    dispatch(manageRolesActions.changeRolesFilterData(data));
  };

  const handleClearFilter = () => {
    // let data = {
    //   display_name: "",
    //   status: null,
    // };
    dispatch(manageRolesActions.setCurrentPage(0));
    dispatch(manageRolesActions.clearRolesFilterData(cookies.accessToken));
    onHide();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={rolesFilterData}
        handleChange={(e) => handleFormChange(e)}
        onSubmit={() => {
          applyFilter();
          onHide();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <div style={{ padding: "20px 10px 0px 10px" }}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-12">
                    <Field
                      value={rolesFilterData.display_name}
                      name="display_name"
                      component={Input}
                      placeholder="Role Name"
                      label="Role Name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                flexDirection: "row",
                paddingRight: "10px",
              }}
            >
              <button
                style={{ marginRight: "10px" }}
                type="button"
                onClick={() => handleClearFilter()}
                className="btn btn-light btn-elevate"
              >
                Clear Filter
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Apply
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
