/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Nav, Col, Row, Modal } from "react-bootstrap";
import CabinetBrandsList from "../components/CabinetBrandsList";
import DoorStylesList from "../components/DoorStylesList";
import SinkList from "../components/SinkList";
import MoldingDoorList from "../components/MoldingDoorList";
import StorageAndOrganizerList from "../components/StorageAndOrganizerList";
import AppliancesList from "../components/AppliancesList";
import LooseApplianceList from "../components/LooseApplianceList";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import ApplianceSubCategory from "../components/ApplianceSubCategoryList";

import {
  Card,
  CardBody,
  CardHeader,
  // CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { actions as intakeFormActions } from "../redux/intakeFormRedux";

export default function ManageConfig(props) {
  const { getCategoriesDataLoading, categories } = useSelector(
    (state) => state.intakeForm
  );

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(intakeFormActions.getCategories(cookies.accessToken));
    dispatch(
        intakeFormActions.getCabinateBrandOption(cookies.accessToken)
      );
    dispatch(
        intakeFormActions.getApplianceOptions(cookies.accessToken)
      );
    dispatch(intakeFormActions.getClientsDropdown(cookies.accessToken));
  }, []);

  useEffect(() => {
    categories.forEach((element) => {
      if (element.slug === "cabinet-brand") {
        dispatch(intakeFormActions.setCategoryId(element.id));
      }
    });
  }, [categories]);

  const [activeTab, setActiveTab] = useState("cabinet-brand");
  const tabSelect = (k) => {
    if(k == activeTab){
      return;
    }
    if (k === "cabinet-brand") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("cabinet-brand"));
      setActiveTab("cabinet-brand");
      categories.forEach((element) => {
        if (element.slug === "cabinet-brand") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }

    if (k === "molding-door-enhancement") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("molding-door-enhancement"));
      setActiveTab("molding-door-enhancement");
      categories.forEach((element) => {
        if (element.slug === "molding-door-enhancement") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "door-style") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("door-style"));
      setActiveTab("door-style");
      categories.forEach((element) => {
        if (element.slug === "door-style") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "sink") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("sink"));
      setActiveTab("sink");
      categories.forEach((element) => {
        if (element.slug === "sink") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "storage-organizer") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("storage-organizer"));
      setActiveTab("storage-organizer");
      categories.forEach((element) => {
        if (element.slug === "storage-organizer") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "appliances") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("appliances"));
      setActiveTab("appliances");
      categories.forEach((element) => {
        if (element.slug === "appliances") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "appliances-sub-category") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("appliances-sub-category"));
      setActiveTab("appliances-sub-category");
      categories.forEach((element) => {
        if (element.slug === "appliances-sub-category") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
    if (k === "loose-appliances") {
      dispatch(intakeFormActions.setCategoryList([]));
      dispatch(
        intakeFormActions.clearCategoryFilterData(cookies.accessToken, true)
      );
      dispatch(intakeFormActions.setIntakeType("loose-appliances"));
      setActiveTab("loose-appliances");
      categories.forEach((element) => {
        if (element.slug === "loose-appliances") {
          dispatch(intakeFormActions.setCategoryId(element.id));
        }
      });
    }
  };
  return (
    <Card>
      <CardHeader title={"Intake Form"}></CardHeader>
      <CardBody>
        <Modal.Body
          style={{ padding: 0 }}
          className="overlay overlay-block cursor-default"
        >
          {getCategoriesDataLoading && (
            <div
              style={{ zIndex: 9999999 }}
              className="overlay-layer bg-transparent"
            >
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <Tab.Container
            onSelect={tabSelect}
            id="left-tabs-example"
            defaultActiveKey="cabinet-brand"
            activeKey={activeTab}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="cabinet-brand">Cabinet Brands</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="door-style">Door Styles</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sink">Sink</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="molding-door-enhancement">
                      Molding & Door Enhancements
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="storage-organizer">
                      Storage & Organizer
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="appliances">Standard Appliances</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="loose-appliances">Loose Appliances</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="appliances-sub-category">Appliance Sub Category</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="cabinet-brand">
                    <CabinetBrandsList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="door-style">
                    <DoorStylesList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="sink">
                    <SinkList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="molding-door-enhancement">
                    <MoldingDoorList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="storage-organizer">
                    <StorageAndOrganizerList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="appliances">
                    <AppliancesList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="loose-appliances">
                    <LooseApplianceList activetab={activeTab} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="appliances-sub-category">
                    <ApplianceSubCategory activetab={activeTab} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
      </CardBody>
    </Card>
  );
}
