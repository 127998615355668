/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/feedbackFormRedux";
import { useCookies } from "react-cookie";
import Table from "../../../components/Table";
import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import PropTypes from "prop-types";

const FeedbackClientDialog = ({ row = {}, setRow }) => {
  const client_id = row?.id;
  const status = row?.status;
  const [cookies] = useCookies(["accessToken"]);
  const dispatch = useDispatch();
  const feedbackForm = useSelector((state) => state.feedbackForm);

  const {
    moreDetailPopup,
    feedbackClientListLoading,
    feedbackClientList,
    feedbackClientSortField,
    feedbackClientSortOrder,
    currentClientPage,
    maxFeedbackClientPerPage,
    totalFeedbackClientRecords,
    feedbackClientFilterData,
  } = feedbackForm;

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalFeedbackClientRecords || 0,
    sizePerPage: maxFeedbackClientPerPage,
    page: currentClientPage + 1,
  };

  useEffect(() => {
    if (status === "success" && moreDetailPopup && client_id) {
      dispatch(
        actions.getFeedbackClientList(
          client_id,
          feedbackClientSortField,
          feedbackClientSortOrder,
          currentClientPage,
          maxFeedbackClientPerPage,
          cookies.accessToken
        )
      );
    }
  }, [status, moreDetailPopup, client_id, dispatch]);

  const handlePopupClose = () => {
    dispatch(actions.toggleFeedbackMoreDetailPopup(false));
    dispatch(actions.changeFeedbackClientFilterData({
      filterEmail: "",
    }));
    setRow({});
  };

  const tableColumns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          actions.changeCurrentClientPage(
            client_id,
            page - 1,
            maxFeedbackClientPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== feedbackClientSortField ||
        sortOrder !== feedbackClientSortOrder
      ) {
        dispatch(
          actions.changeClientSort(
            client_id,
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const applyFilter = () => {
    dispatch(actions.setClientFilter(true));
    dispatch(actions.setCurrentClientPage(0));
    dispatch(
      actions.getFeedbackClientList(
        client_id,
        feedbackClientSortField,
        feedbackClientSortOrder,
        currentClientPage,
        maxFeedbackClientPerPage,
        cookies.accessToken
      )
    );
  };

  const handleEmailSearch = (e) => {
    dispatch(
      actions.changeFeedbackClientFilterData({
        filterEmail: e.target.value,
      })
    );
    clearTimeout(window.filterTimeout);
    window.filterTimeout = setTimeout(() => {
      applyFilter();
    }, 500);
  };

  return (
    <Modal size="lg" show={moreDetailPopup} onHide={()=>handlePopupClose()}>
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          More Details
        </Modal.Title>
      </Modal.Header>

      {row?.status === "success" ? (
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6" />
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div className="form-group" style={{ margin: 0 }}>
                <input
                  className="form-control form-control-sm"
                  placeholder="Search by Email"
                  type="text"
                  name="filterEmail"
                  value={feedbackClientFilterData.filterEmail}
                  onChange={handleEmailSearch}
                  // onBlur={() => applyFilter()}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <Table
              listLoading={feedbackClientListLoading}
              entities={feedbackClientList || []}
              columns={tableColumns}
              paginationOptions={paginationOptions}
              totalCount={totalFeedbackClientRecords}
              handleTableChange={(type, newState) =>
                handleTableChange(type, newState)
              }
              sortState={{
                dataField: feedbackClientSortField,
                order: feedbackClientSortOrder,
              }}
            />
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>{row?.failed_reason}</Modal.Body>
      )}
      <Modal.Footer>
        <button
          type="button"
          onClick={!feedbackClientListLoading ? handlePopupClose : undefined}
          className="btn btn-light btn-elevate"
        >
          Cancel
        </button>
      </Modal.Footer>
    </Modal>
  );
};

FeedbackClientDialog.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
  }),
  setRow: PropTypes.func.isRequired,
};

export default memo(FeedbackClientDialog);
