/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as intakeFormReduxActions } from "../redux/intakeFormRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import { CustomMultiSelect } from "../../../components/CustomMultiSelect";
import ShortUniqueId from "short-unique-id";
// import { isValid } from "date-fns";
const uid = new ShortUniqueId();

// Validation schema
const CategoryEditSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

export function CategoryForm({ userID, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const {
    // selectedClients,
    clientsDropdownFailed,
    clientsDropdownLoading,
    clients,
    categoryData,
    getCategoryLoading,
    // getCategorySuccess,
    getCategoryFailed,
    saveCategoryDataLoading,
    saveCategoryDataFailed,
    saveCategoryDataSuccess,
    updateCategoryFailed,
    updateCategorySuccess,
    updateCategoryLoading,
    // selectAllClients,
    intakeType,
    cabinateBrandOption,
    getCabinateBrandOptionLoading,
    applianceOption,
    isDefaultCient,
    standardClients, enterpriseClients
  } = useSelector((state) => state.intakeForm);
  categoryData.visibility = enterpriseClients.concat(standardClients);
  // const [changePassword, setChangePassword] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  // const [rolesSuccess, setRolesSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState("standard");
  const [isInvalid, setIsInvalid] = useState(false);
  
  const handleFormChange = (e) => {
    let data = categoryData;
    data[e.target.name] = e.target.value;
    dispatch(intakeFormReduxActions.changeCategoryData(data));
  };
  useEffect(() => {
    if (
      clientsDropdownLoading ||
      getCategoryLoading ||
      saveCategoryDataLoading ||
      updateCategoryLoading ||
      getCabinateBrandOptionLoading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [
    clientsDropdownLoading,
    getCategoryLoading,
    saveCategoryDataLoading,
    updateCategoryLoading,
    getCabinateBrandOptionLoading,
  ]);

  useEffect(() => {
    if (userID && edit) {
      dispatch(intakeFormReduxActions.getCategory(userID, cookies.accessToken));
    } 
    
    dispatch(intakeFormReduxActions.clearCategoryData());
    dispatch(intakeFormReduxActions.getClientsDropdown(cookies.accessToken));
  }, []);

  useEffect(() => {
    if (
      !saveCategoryDataLoading &&
      !saveCategoryDataFailed &&
      saveCategoryDataSuccess
    ) {
      onHide();
    }
  }, [
    saveCategoryDataFailed,
    saveCategoryDataLoading,
    saveCategoryDataSuccess,
  ]);

  useEffect(() => {
    if (
      !updateCategoryLoading &&
      !updateCategoryFailed &&
      updateCategorySuccess
    ) {
      onHide();
    }
  }, [updateCategoryFailed, updateCategoryLoading, updateCategorySuccess]);
  useEffect(() => {
    if (intakeType === "door-style") {
      dispatch(
        intakeFormReduxActions.getCabinateBrandOption(cookies.accessToken)
      );
    }
    if (intakeType === "appliances-sub-category") {
      dispatch(
        intakeFormReduxActions.getApplianceOptions(cookies.accessToken)
      );
    }
  }, [intakeType]);
  const cabinetBrandOptions = () => {
    if (intakeType === "door-style") {
      return (
        <div className="form-group row">
          <div className="col-lg-4">
            <div>
              <Select
                value={categoryData.cabinet_brand_id}
                name="cabinet_brand_id"
                label="Cabinet Brand"
                onChange={(e) => handleFormChange(e)}
                disabled={formLoading}
              >
                {cabinateBrandOption &&
                  cabinateBrandOption.length !== 0 &&
                  cabinateBrandOption.map((cabinateBrandData) => (
                    <option
                      key={cabinateBrandData.id}
                      value={cabinateBrandData.id}
                    >
                      {cabinateBrandData.name}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      );
    }
  };

  // const applianceTypeOptions = () => {
  //   if (intakeType === "appliances") {
  //     return (
  //       <div className="form-group row">
  //         <div className="col-lg-4">
  //           <div>
  //             <Select
  //               value={categoryData.option_type}
  //               name="option_type"
  //               label="Appliance Type"
  //               onChange={(e) => handleFormChange(e)}
  //               disabled={formLoading}
  //             >
  //               <option
  //                     key={1}
  //                     value={1}
  //                   >
  //                     Standard Appliances
  //                   </option>
  //                   <option
  //                     key={2}
  //                     value={2}
  //                   >
  //                     Loose Appliances
  //                   </option>
  //             </Select>
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  const applianceOptions = () => {
    if (intakeType === "appliances-sub-category") {
      return (
        <div className="form-group row">
          <div className="col-lg-4">
            <div>
              <Select
                required
                value={categoryData.appliance_id}
                name="appliance_id"
                label="Appliance"
                onChange={(e) => handleFormChange(e)}
                disabled={formLoading}
              >
                <option
                      
                      value=""
                    >
                      Select
                </option>
                {applianceOption &&
                  applianceOption.length !== 0 &&
                  applianceOption.map((applianceData) => (
                    <option
                      key={applianceData.id}
                      value={applianceData.id}
                    >
                      {applianceData.name}
                    </option>
                  ))}
              </Select>
            </div>
          </div>
        </div>
      );
    }
  };
  
  const saveCategory = () => {
    let selectAll = 0;
    if (intakeType === "appliances-sub-category") {
      if(categoryData.appliance_id == ""){
        dispatch(generalActions.addToast(
          "Form error",
          "Please select Appliance",
          "error",
          uid()
        ))
        return;
      }
    }
    if(standardClients.length==0&&enterpriseClients.length ==0){
      setIsInvalid(true);
      return;
    }else{
      setIsInvalid(false);
    }
    
    if (userID && edit) {
      if (clients.standard.length+clients.enterprise.length == standardClients.length+enterpriseClients.length) {
        selectAll = 1;
      }
      dispatch(
        intakeFormReduxActions.updateCategory(
          userID,
          categoryData,
          cookies.accessToken,
          selectAll
        )
      );
    } else {
      if (clients.standard.length+clients.enterprise.length == standardClients.length+enterpriseClients.length) {
        selectAll = 1;
      }
      dispatch(
        intakeFormReduxActions.saveCategory(
          categoryData,
          cookies.accessToken,
          selectAll
        )
      );
    }
  };

  // const setSelectedClients = (val) => {
  //   let data = categoryData;
  //   data.visibility = val;
  //   dispatch(intakeFormReduxActions.changeCategoryData(data));
  //   dispatch(intakeFormReduxActions.setSelectedClients(val));
  // };

  const changeDefaultClient = (e) =>{
    dispatch(intakeFormReduxActions.setDefaultClient(e.target.checked));
  }

  const changeClients = (v)=>{
   
    setActiveTab(v);
}

  if (
    (!clientsDropdownLoading && clientsDropdownFailed) ||
    (!getCategoryLoading && getCategoryFailed)
  ) {
    return (
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <i className="flaticon-warning"></i>
        <p>
          Something went wrong. Please check your internet connection and try
          again.
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={categoryData}
          validationSchema={CategoryEditSchema}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveCategory();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {formLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <Field
                        value={categoryData.name}
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      />
                    </div>
                  </div>
                  {cabinetBrandOptions()}
                  {applianceOptions()}
                  
                  
                  <div className="form-group row">
                  <div className="col-lg-12">
                  <label>Visibility To</label>
                  <br></br>
                  <div className="form-check form-check-inline">
                    <button className={activeTab == 'standard'?"btn btn-primary":"btn client-tab"} onClick={()=>{changeClients('standard')}} type="button">Standard{standardClients.length ? <span className="client-count">{standardClients.length}</span>:null}</button>
                    </div>
                    <div className="form-check form-check-inline">
                    <button className={activeTab == 'enterprise'?"btn btn-primary":"btn client-tab"} onClick={()=>{changeClients('enterprise')}} type="button">Enterprise{enterpriseClients.length ? <span className="client-count">{enterpriseClients.length}</span>:null}</button>
                    </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-4 visibility-to">
                              
                    {activeTab == 'standard' && <div className={isInvalid?"form-group invalid":"form-group"}><Field
                            label="Clients"
                            labelledBy={"Select"}
                            options={clients && clients.standard? clients.standard:[]}
                            value={standardClients}
                            onChange={(v)=>{
                              dispatch(
                                intakeFormReduxActions.setStandardClients(v)
                              );
                            }}
                            name="visibility"
                            component={CustomMultiSelect}
                            overrideStrings={{
                              selectSomeItems: "Select...",
                              allItemsAreSelected: "All clients are selected.",
                              selectAll: "Select All",
                              search: "Search",
                              clearSearch: "Clear Search",
                            }}
                            closeOnChangedValue={true}
                          />
                          {isInvalid && <div className="invalid-feedback">Visibility To is required</div>}
                          </div> }

          {activeTab == 'enterprise' &&<div className={isInvalid?"form-group invalid":"form-group"}><Field
                labelledBy={"Select"}
                options={clients && clients.enterprise? clients.enterprise:[]}
                value={enterpriseClients}
                onChange={(v)=>{
                  dispatch(
                    intakeFormReduxActions.setEnterPriseClients(v)
                  );
                }}
                name="visibilityi"
                component={CustomMultiSelect}
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All clients are selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              />
              {isInvalid && <div className="invalid-feedback">Visibility To is required</div>}
              </div> }
          </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field label={"Is default"} type="checkbox" name="isdefault" onChange={changeDefaultClient} checked={isDefaultCient}/>
                      <span style={{marginLeft: "5px", position: "relative", bottom: "3px"}}>Is default</span>
                    </div>
                   </div> 
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => !formLoading && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
