/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as feedbackSettingActions } from "../redux/feedbackSettingRedux";

const FeedbackReOpen = ({ clientID }) => {
  const [cookies] = useCookies(["accessToken"]);
  const dispatch = useDispatch();
  const { feedbackReopen } = useSelector((state) => state.feedbackSettings);
  console.log(feedbackReopen);

  useEffect(() => {
    if (clientID) {
      console.log(clientID);
      dispatch(
        feedbackSettingActions.checkFeedbackReOpen(
          clientID,
          cookies.accessToken
        )
      );
    }
  }, [clientID]);

  const handleReopen = () => {
    dispatch(
      feedbackSettingActions.updateFeedbackReopen(clientID, cookies.accessToken)
    );
  };

  if (!feedbackReopen) return null;

  return (
    <div className="reopen-button">
      <button
        type="button"
        className="btn btn-outline-primary"
        onClick={() => handleReopen()}
      >
        Feedback Reopen
      </button>
      &nbsp; &nbsp;
    </div>
  );
};
FeedbackReOpen.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default memo(FeedbackReOpen);
