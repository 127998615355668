import React from "react";
import PropTypes from "prop-types";

const ResponsesCount = ({ reports_count = [], questionId }) => {
  const filteredResponse = reports_count?.filter(
    (report) => report?.feedback_question_id === questionId
  );
  const reportArray = Array.from({ length: 5 })
    .map((item, index) => {
      const rating = index + 1;
      const cnt = filteredResponse?.find(
        (report) => Number(report?.response) === rating
      )?.cnt;
      return {
        cnt: cnt || 0,
        questionId,
        rating: index + 1,
      };
    })
    ?.sort((a, b) => b.rating - a.rating);

  return (
    <>
      {reportArray.map((report) => (
        <div className="level-count-row" key={report?.rating}>
          <span>Star: {report?.rating}</span>
          <div className={report?.cnt > 0 ? "line filled" : "line empty"} />
          <strong>{report?.cnt || ""}</strong>
        </div>
      ))}
    </>
  );
};

ResponsesCount.propTypes = {
  reports_count: PropTypes.array,
  questionId: PropTypes.number,
};

export default ResponsesCount;
