/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { CustomPhoneInput } from "../../../components/CustomPhoneInput";
import ClientFormMaxRevisionInput from "./ClientFormMaxRevisionInput";

const ClientUpdateSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company_name: Yup.string().required("Company Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(new RegExp("[0-9]{7}"), "Please enter valid Phone."),
    max_revision_request: Yup.number()
      .nullable(true)
      .max(99, "Max Revisions must be less than or equal to 99"),
  });
};

export default function ClientEditForm({ id }) {
  const dispatch = useDispatch();
  const {
    clientData,
    getClientLoading,
    updateClientDataLoading,
    resendEmailLoading,
  } = useSelector((state) => state.manageClients);
  const [cookies] = useCookies(["accessToken"]);
  const [formLoading, setFormLoading] = useState(false);
  const [prioritizationSequence, setPrioritizationSequence] = useState("");
  
  useEffect(() => {
    dispatch(manageClientsActions.getClientData(id, cookies.accessToken));
  }, [cookies.accessToken, dispatch, id]);

  useEffect(() => {
    if (getClientLoading || updateClientDataLoading || resendEmailLoading) {
      setFormLoading(true);
    } else {
      let prioritizationemp = clientData.is_all_tab_prioritisation_enabled?1:0;
      setPrioritizationSequence(prioritizationemp);
      setFormLoading(false);
    }
  }, [getClientLoading, updateClientDataLoading, resendEmailLoading]);
  useEffect(() => {
    setPrioritizationSequence(clientData.is_all_tab_prioritisation_enabled);
  },[])
  const handleFormChange = (e) => {
    let data = clientData;
    if(data['client_type'] == 'enterprise' && data?.free_sample_designs?.data?.statistics?.remaining==0){
      data['is_allow_free_sample_design'] = 0;
      data['max_free_sample_design'] = 3;
      data['free_sample_designs'] = "";
    }
    if(e.target.name == 'max_free_sample_design'){
      if(data.free_sample_designs.status == 1){
        if(data.free_sample_designs.data.statistics.remaining>e.target.value){
          return;
        }
      }
    }
    data[e.target.name] = e.target.type == "checkbox"?e.target.checked:e.target.value;
    dispatch(manageClientsActions.changeClientData(data));
  };

  const changePhone = (v, c) => {
    let data = clientData;
    data["phone"] = v;
    data["phone_code"] = c["dialCode"];
    dispatch(manageClientsActions.changeClientData(data));
  };

  const updateClient = () => {
    let is_all_tab_prioritisation_enabled = clientData.is_all_tab_prioritisation_enabled?1:0;
      if(clientData.is_archieve == 1){
        if(window.confirm("Are you sure you want to Archive "+clientData.company_name+" this will stop displaying all the information regrading this client in front end")){
          dispatch(manageClientsActions.updateClientDataLoading(true));
          dispatch(
            manageClientsActions.updateClientData(id, clientData, cookies.accessToken)
          );
        }
      }else if(is_all_tab_prioritisation_enabled !== prioritizationSequence){
        if(window.confirm("Are you sure you want to change the sequencing method as it will impact the Prioritized view for Client & Internal Team")){
          dispatch(manageClientsActions.updateClientDataLoading(true));
          dispatch(
            manageClientsActions.updateClientData(id, clientData, cookies.accessToken)
          );
        }
      }else{
        dispatch(manageClientsActions.updateClientDataLoading(true));
        dispatch(
          manageClientsActions.updateClientData(id, clientData, cookies.accessToken)
        );
      }
    
  };

  const resendEmail = () => {
    dispatch(
      manageClientsActions.resendEmail(clientData.id, cookies.accessToken)
    );
  };

  const renderPendingInvitation = () => {
    if (clientData.status === 2) {
      return (
        <div>
          <label>Status</label>
          <p>
            <span>
              <b>Pending Invitation</b>
            </span>{" "}
            <span>
              <button
                type="button"
                onClick={resendEmail}
                disabled={formLoading}
                className="btn btn-primary"
              >
                Resend Email
              </button>
            </span>
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <Select
            id="status"
            onChange={(e) => handleFormChange(e)}
            value={clientData.status}
            name="status"
            label="Status"
            disabled={formLoading}
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </Select>
        </div>
      );
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={clientData}
        validationSchema={ClientUpdateSchema(clientData.phone)}
        handleChange={(e) => handleFormChange(e)}
        onSubmit={() => {
          updateClient();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body
              style={{ paddingTop: 0 }}
              className="overlay overlay-block cursor-default"
            >
              {formLoading && (
                <div
                  style={{ zIndex: 999999 }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-4">
                    <Field
                      value={clientData.name}
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      onChange={(e) => handleFormChange(e)}
                      disabled={formLoading}
                    />
                  </div>
                  {/* Login */}
                  <div className="col-lg-4">
                    <Field
                      value={clientData.company_name}
                      onChange={(e) => handleFormChange(e)}
                      name="company_name"
                      component={Input}
                      placeholder="Company Name"
                      label="Company Name"
                      disabled={formLoading}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      value={clientData.email}
                      onChange={(e) => handleFormChange(e)}
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      disabled={formLoading}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      value={clientData.phone}
                      country={"us"}
                      onChange={(v, c, e, f) => changePhone(v, c, e, f)}
                      name="phone"
                      component={CustomPhoneInput}
                      label="Phone"
                      disabled={formLoading}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">{renderPendingInvitation()}</div>
                  <div className="col-lg-4">
                    {/* Max Revision Count */}
                    <ClientFormMaxRevisionInput
                      Value={clientData.max_revision_request}
                      handleFormChange={handleFormChange}
                      formLoading={formLoading}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4"> <Select
                    id="client_type"
                    onChange={(e) => handleFormChange(e)}
                    value={clientData.client_type}
                    name="client_type"
                    label="Client Type"
                    disabled={formLoading}
                  >
                    <option value="standard">Standard</option>
                    <option value="enterprise">Enterprise</option>
                  </Select></div>
                  <div className="col-lg-4">
                    <label>Is test user?</label>
                     <Select
                      id="is_sandbox"
                      onChange={(e) => handleFormChange(e)}
                      value={clientData.is_sandbox}
                      name="is_sandbox"
                      disabled={formLoading}
                    >
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </Select>
                  <div class="feedback">Please select</div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                      <label>Archive</label>
                      <Select
                        id="is_archieve"
                        onChange={(e) => handleFormChange(e)}
                        value={clientData.is_archieve}
                        name="is_archieve"
                        disabled={formLoading}
                      >
                      <option value={0}>No</option>
                      <option value={1}>Yes</option>
                    </Select>
                    <div class="feedback">Please select</div>
                    </div>
                    <div className="col-lg-4">
                      <label>Enter Max Request Prioritization</label>
                      <Field
                          type="number"
                          min={0}
                          value={clientData.max_request_prioritisation}
                          onChange={(e) => handleFormChange(e)}
                          name="max_request_prioritisation"
                          component={Input}
                          placeholder="Max request prioritization"
                          disabled={formLoading}
                        />
                        <div class="feedback">Please enter <b>Max Request Prioritization</b></div>
                  </div>
                </div>
                <div className="form-group row">
                {clientData.client_type == "standard"&&<div className="col-lg-4">
                  <Field label={"Allow Sample Designs"} type="checkbox" name="is_allow_free_sample_design" onChange={(e) => handleFormChange(e)} 
                           checked={clientData.is_allow_free_sample_design == 1}/>
                      <span style={{marginLeft: "5px", position: "relative", bottom: "3px"}}>Allow Sample Designs</span>
                      {clientData.is_allow_free_sample_design == 1 ? <Field
                        type="number"
                        min={0}
                        value={clientData.max_free_sample_design}
                        onChange={(e) => handleFormChange(e)}
                        name="max_free_sample_design"
                        component={Input}
                        placeholder="Max free sample design"
                        disabled={formLoading}
                      />:null}
                      {clientData.free_sample_designs.status == 1?<p>Free Designs Consumed {clientData.free_sample_designs.data.statistics.remaining}/{clientData.free_sample_designs.data.statistics.total}</p>:null}
                  </div>}
                  <div className="col-lg-4">
                   <Field style={{marginTop: "5px",marginBottom: "8px"}} 
                        label={"Enable sequencing in All tab"} 
                        type="checkbox" 
                        name="is_all_tab_prioritisation_enabled" onChange={(e) => handleFormChange(e)} 
                           checked={clientData.is_all_tab_prioritisation_enabled == 1}/>
                      <span style={{marginLeft: "5px", position: "relative", bottom: "3px"}}>Enable sequencing in All tab</span>
                     </div>
                  </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <> </>
              <button
                type="submit"
                onClick={() => !formLoading && handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
