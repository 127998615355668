import React from "react";

export const feedbackStatusCssClasses = {
  0: "",
  success: "success",
  fail: "danger",
  pending: "info",
};

export const feedbackStatusTitles = {
  0: "",
  success: "Success",
  fail: "Failed",
  pending: "Pending",
};

export default function FeedbackStatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${
      row?.status
        ? feedbackStatusCssClasses[row.status]
        : feedbackStatusCssClasses[0]
    } label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {row?.status ? feedbackStatusTitles[row.status] : feedbackStatusTitles[0]}
    </span>
  );
}
