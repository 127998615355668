// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { actions as generalActions } from "../../redux/generalReducer";
import ToastComponent from "./ToastComponent";

export default function ToastsContainer() {
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.general);
  return (
    <div style={{display: 'flex', position: 'absolute', top: '10px', width: '100%', alignItems: 'center', flexDirection: 'column', zIndex: 999999 }}>
      {toasts.map((toastData) => (
        <ToastComponent
          headerText={toastData.headerText}
          text={toastData.text}
          type={toastData.type}
          key={toastData.id}
          removeToast={() => dispatch(generalActions.removeToast(toastData.id))} />
      ))}
    </div>
  );
}