/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Form, Col } from "react-bootstrap";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye} from '@fortawesome/free-solid-svg-icons'

export default function ClientApiAccessKey({ id, activetab }) {
  const dispatch = useDispatch();
  const { clientApiAccessKey, getClientApiLoading , getClientApiSuccess} = useSelector(
    (state) => state.manageClients
  );
  const [cookies] = useCookies(["accessToken"]);
  const [formLoading, setFormLoading] = useState(false);
  const[clientApiAccessKeyOnClickEye, setClientApiAccessKeyOnClickEye] = useState('');

  useEffect(() => {
    if (activetab === "apiaccesskeys") {
      dispatch(
        manageClientsActions.getClientApiAccessKey(id, cookies.accessToken)
      );
    }
  }, [activetab]);

  useEffect(() => {
    if (getClientApiLoading) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [getClientApiLoading]);

  useEffect(() => {
    if (getClientApiSuccess && clientApiAccessKeyOnClickEye) {
      setClientApiAccessKeyOnClickEye(clientApiAccessKey);
    } 
  }, [getClientApiSuccess,clientApiAccessKeyOnClickEye]);

  function regenerateAccessKey() {
    dispatch(manageClientsActions.regenerateAccessKey(id, cookies.accessToken));
  }
  // function copyToClipboard(e) {
  //   this.textArea.select();
  //   document.execCommand("copy");
  //   e.target.focus();
  // }
  const eyeIconClick = () => {
    if(!clientApiAccessKeyOnClickEye)
    setClientApiAccessKeyOnClickEye(clientApiAccessKey);
    else
    setClientApiAccessKeyOnClickEye('');
  }

  return (
    <>
      <Modal.Body
        style={{ paddingTop: 0 }}
        className="overlay overlay-block cursor-default"
      >
        {formLoading && (
          <div
            style={{ zIndex: 999999 }}
            className="overlay-layer bg-transparent"
          >
            <div className="spinner spinner-lg spinner-success" />
          </div>
        )}

        <div className="form-group row">
          <Form>
            <Form.Group>
              <Form.Label>
                Following is your access key to connect your website with our
                design portal:
              </Form.Label>
              <Form.Row>
              <Col sm={11}>
              <Form.Control
                value={clientApiAccessKeyOnClickEye}
                as="textarea"
                rows={3}
                readOnly
              />
              </Col>
              <Col>
              <FontAwesomeIcon icon={clientApiAccessKeyOnClickEye ? faEye : faEyeSlash} onClick={eyeIconClick}/>
              </Col>
              </Form.Row>
            </Form.Group>
            <button
              type="button"
              onClick={regenerateAccessKey}
              className="btn btn-primary"
            >
              Regenerate Access Key
            </button>
          </Form>
        </div>
      </Modal.Body>
    </>
  );
}
