/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  // CardHeaderToolbar,
  // Input, 
  Select 
} from "../../../../_metronic/_partials/controls";
import { actions as manageReportsActions } from "../redux/manageReportsRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import { Formik, Form, Field } from "formik";
import { CustomMultiSelect } from "../../../components/CustomMultiSelect";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import "./index.scss";
import ShortUniqueId from "short-unique-id";

export default function SearchReports() {
  const uid = new ShortUniqueId();
  const dispatch = useDispatch();
  const {
    reportsFilters,clientTypeDropDown,clientStatusDropDown,clientSelectedType,clientSelectedStatus,companiesDropDown,userStatusDropDown,companies,userStatus,
    currentPlanDropDown,currentPlan,requestStatusDropDown,requestStatus,pmDropDown,pm,designerDropDown,designer,createdOnStartDate,createdOnEndDate,excelData,apiLoading} = useSelector((state) => state.manageReports);
  const [reportType, setReportType] = useState("clients");
  const [cookies] = useCookies(["accessToken"]);
  const [maxEndDate, setMaxEndDate] = useState(null);

  useEffect(() => {
    dispatch(
      manageReportsActions.getFilters(cookies.accessToken)
    );
  }, []);

  useEffect(() => {
    if(reportsFilters.client_types){
      dispatch(
        manageReportsActions.setClientType(reportsFilters.client_types)
      );
      dispatch(
        manageReportsActions.setClientStatus(reportsFilters.client_statuses)
      );
      dispatch(
        manageReportsActions.setCompaniesDropDown(reportsFilters.companies)
      );
      dispatch(
        manageReportsActions.setUserStatusDropDown(reportsFilters.sub_client_statuses)
      );
      dispatch(
        manageReportsActions.setCurrentPlanDropDown(reportsFilters.subscription_plans)
      );
      dispatch(
        manageReportsActions.setRequestStatusDropdown(reportsFilters.request_statuses)
      );
      dispatch(
        manageReportsActions.setPmDropDown(reportsFilters.pms)
      );
      dispatch(
        manageReportsActions.setDeignerDropDown(reportsFilters.designers)
      );
    }
  },[reportsFilters])

  const changeReportType = (e) =>{
    if(e.target.value !== reportType){
      dispatch(
        manageReportsActions.clearReportFilter()
      );
    }
    setReportType(e.target.value)
  }

  const setSelectedClientsType = (val)=>{
    dispatch(
      manageReportsActions.setClientSelectedType(val)
    );
  }

  const setSelectedClientsStatus = (val)=>{
    dispatch(
      manageReportsActions.setClientSelectedStatus(val)
    );
  }

  const setCompanies = (val)=>{
    dispatch(
      manageReportsActions.setCompanies(val)
    );
  }

  const setUsersStatus = (val)=>{
    dispatch(
      manageReportsActions.setUserStatus(val)
    );
  }

  const setCurrentPlan = (val)=>{
    dispatch(
      manageReportsActions.setCurrentPlan(val)
    );
  }

  const setRequestStatus = (val)=>{
    dispatch(
      manageReportsActions.setRequestStatus(val)
    );
  }

  const setPm = (val)=>{
    dispatch(
      manageReportsActions.setPm(val)
    );
  }

  const setDeigner = (val)=>{
    dispatch(
      manageReportsActions.setDeigner(val)
    );
  }

  const addMonths=(date, months)=>{
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  const setCreatedStartDate = (val)=>{
    dispatch(
      manageReportsActions.setCreatedOnStartDate(val)
    );
    let futureDate = new Date(addMonths(new Date(val),3));
    let currentDate = new Date();

    if(futureDate.getTime() > currentDate.getTime() && val){
      dispatch(
        manageReportsActions.setCreatedOnEndDate(new Date())
      );
      setMaxEndDate(new Date())
    }else{
      if(val){
        dispatch(
          manageReportsActions.setCreatedOnEndDate(new Date(addMonths(new Date(val),3)))
        );
        setMaxEndDate(new Date(addMonths(new Date(val),3)))
      }else{
        dispatch(
          manageReportsActions.setCreatedOnEndDate(null)
        );
      }
    }
    
  }

  const setCreatedEndDate = (val)=>{
    dispatch(
      manageReportsActions.setCreatedOnEndDate(val)
    );
  }
  
  const downloadExcel = ()=>{
    let companiesTemp = [];
    companies.forEach(function(v){
      companiesTemp.push(v.value)
    })
    let designerTemp = [];
    designer.forEach(function(v){
      designerTemp.push(v.value)
    })
    let clientTypeTemp = [];
    clientSelectedType.forEach(function(v){
      clientTypeTemp.push(v.value)
    })
    let planTypeTemp = [];
    currentPlan.forEach(function(v){
      planTypeTemp.push(v.value)
    })
    
    let filterData = {};
    if(reportType == "clients"){
      if(clientTypeTemp.length || clientSelectedStatus){
        filterData = {
          type: reportType,
          client_types: clientTypeTemp.toString(),
          client_status:clientSelectedStatus,
        }
        dispatch(
          manageReportsActions.downloadExcel(filterData,cookies.accessToken)
        );
      }else{
        dispatch(generalActions.addToast(
          "Filter error",
          "Please select at least one filter",
          "error",
          uid()
        ))
      }
    }else if(reportType == "sub_users"){
      if(clientTypeTemp.length || companiesTemp.length || userStatus){
        filterData = {
          type: reportType,
          client_types: clientTypeTemp.toString(),
          company_ids:companiesTemp.toString(),
          sub_user_status:userStatus,
        }
        dispatch(
          manageReportsActions.downloadExcel(filterData,cookies.accessToken)
        );
      }else{
        dispatch(generalActions.addToast(
          "Filter error",
          "Please select at least one filter",
          "error",
          uid()
        ))
      }
      
    }else if(reportType == "requests"){
      const getFormattedDate = (d) => {
        var date = new Date(d),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-")
      }
      if(clientTypeTemp.length || companiesTemp.length || planTypeTemp.length || requestStatus || designerTemp.length || (createdOnStartDate && createdOnEndDate)){
        filterData = {
          type: reportType,
          client_types: clientTypeTemp.toString(),
          company_ids:companiesTemp.toString(),
          plan_ids:planTypeTemp.toString(),
          request_status_id:requestStatus,
          created_start_date:createdOnStartDate?getFormattedDate(createdOnStartDate):null,
          created_end_date:createdOnEndDate?getFormattedDate(createdOnEndDate):null,
          designer_ids:designerTemp.toString(),
        }
        dispatch(
          manageReportsActions.downloadExcel(filterData,cookies.accessToken)
        );
      }else{
        dispatch(generalActions.addToast(
          "Filter error",
          "Please select at least one filter",
          "error",
          uid()
        ))
      }
    }
  }
  return (
    <Card>
      <CardHeader title="Filters For Each Report Type">
      </CardHeader>
      <CardBody className="reports-filter">
        <div className="search-selection-title">
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="reportRadios" id="clients" value="clients" onChange={changeReportType} checked={reportType == "clients"}/>
            <label className="form-check-label" for="clients">
              Client Data Report
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="reportRadios" id="sub_users" value="sub_users" onChange={changeReportType} checked={reportType == "sub_users"}/>
            <label className="form-check-label" for="sub_users">
              Sub Users Data
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="reportRadios" id="requests" value="requests" onChange={changeReportType}  checked={reportType == "requests"}/>
            <label className="form-check-label" for="requests">
              Requests Data
            </label>
          </div>
      </div>
      <Modal.Body className="overlay overlay-block cursor-default">
          {apiLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
      <Formik>
      <Form className="form form-label-right">
       {reportType == "clients" &&<div className="form-group row client-data">
          <div className="col-lg-6">
            <Field
              labelledBy={"Select"}
              options={clientTypeDropDown}
              value={clientSelectedType}
              onChange={setSelectedClientsType}
              name="visibility"
              component={CustomMultiSelect}
              label="Client Type"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All client types are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div>
          <div className="col-lg-6">
          <label>Status</label>
          <Select
              onChange={(e) => setSelectedClientsStatus(e.target.value)}
              value={clientSelectedStatus}
            >
              <option value={""}>{"Select"}</option>
              {clientStatusDropDown.map((v)=>{
                return <option value={v.value}>{v.label}</option>
              })}
            </Select>
          </div>
        </div>}
        {reportType == "sub_users" &&<div className="form-group row sample-data-report">
          <div className="col-lg-4">
          <Field
              labelledBy={"Select"}
              options={clientTypeDropDown}
              value={clientSelectedType}
              onChange={setSelectedClientsType}
              name="visibility"
              component={CustomMultiSelect}
              label="Client Type"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All client types are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div>
          <div className="col-lg-4">
            <Field
              labelledBy={"Select"}
              options={companiesDropDown}
              value={companies}
              onChange={setCompanies}
              name="visibility"
              component={CustomMultiSelect}
              label="Company"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All companies are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div>
          <div className="col-lg-4">
          <label>User Status</label>
          <Select
              onChange={(e) => setUsersStatus(e.target.value)}
              value={userStatus}
            >
              <option value={""}>{"Select"}</option>
              {userStatusDropDown.map((v)=>{
                return <option value={v.value}>{v.label}</option>
              })}
            </Select>
          </div>
        </div>}
        {reportType == "requests" &&<div className="form-group row sample-data-report">
          <div className="col-lg-4">
          <Field
              labelledBy={"Select"}
              options={clientTypeDropDown}
              value={clientSelectedType}
              onChange={setSelectedClientsType}
              name="visibility"
              component={CustomMultiSelect}
              label="Client Type"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All client types are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div>
          <div className="col-lg-4">
          <Field
              labelledBy={"Select"}
              options={currentPlanDropDown}
              value={currentPlan}
              onChange={setCurrentPlan}
              name="visibility"
              component={CustomMultiSelect}
              label="Plan"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All plans are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          
          </div>
          <div className="col-lg-4">
            <Field
              labelledBy={"Select"}
              options={companiesDropDown}
              value={companies}
              onChange={setCompanies}
              name="visibility"
              component={CustomMultiSelect}
              label="Company"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All companies are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div>
          <div className="col-lg-4">
          <label>Status</label>
          <Select
              onChange={(e) => setRequestStatus(e.target.value)}
              value={requestStatus}
              name="status"
            >
              <option value={""}>{"Select"}</option>
              {requestStatusDropDown.map((v)=>{
                return <option value={v.value}>{v.label}</option>
              })}
            </Select>
          </div>
          {/* <div className="col-lg-4">
            <Field
              labelledBy={"Select"}
              options={pmDropDown}
              value={pm}
              onChange={setPm}
              name="visibility"
              component={CustomMultiSelect}
              label="PM"
              overrideStrings={{
                selectSomeItems: "Select...",
                allItemsAreSelected: "All plans are selected.",
                selectAll: "Select All",
                search: "Search",
                clearSearch: "Clear Search",
              }}
              closeOnChangedValue={true}
            />
          </div> */}
          <div className="col-lg-8 request-create-data">
            <label>Created On</label>
            <br></br>
            <div className="request-datepicker">
           <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={createdOnStartDate}
              onChange={(date) => setCreatedStartDate(date)}
              selectsStart
              startDate={createdOnStartDate}
              endDate={createdOnEndDate}
              maxDate={new Date()}
            />
            <span>to</span>
            <DatePicker
              disabled={createdOnStartDate? false:true}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={createdOnEndDate}
              onChange={(date) => setCreatedEndDate(date)}
              selectsEnd
              startDate={createdOnStartDate}
              endDate={createdOnEndDate}
              minDate={createdOnStartDate}
              maxDate={maxEndDate}
            />
            </div>
            <p className="datarangenote">Date Range can be selected for 3 months only</p>
          </div>
          <div className="col-lg-4 design-field">
            <Field
                labelledBy={"Select"}
                options={designerDropDown}
                value={designer}
                onChange={setDeigner}
                name="visibility"
                component={CustomMultiSelect}
                label="Designer"
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All designers are selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              />
          </div>
        </div>}
      </Form>
      </Formik>
      </Modal.Body>
      <div className="data-button-block">
        <button type="button" class="btn btn-black-primary" onClick={downloadExcel}>Download Data Excel</button>
      </div>
      </CardBody>
     
    </Card>
  );
}
