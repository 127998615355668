import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import SearchReports from "./pages/SearchReports";

function ReportsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/reports"
          to="/reports/search"
        />
        <ContentRoute
          path="/reports/search"
          component={SearchReports}
        />
      </Switch>
    </div>
  );
}

export default ReportsPage;
