import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_ROLES_LIST: "GET_ROLES_LIST",
  SET_ROLES_LIST: "SET_ROLES_LIST",
  SET_LIST_LOADING: "SET_LIST_LOADING",
  CREATE_ROLE: "CREATE_ROLE",
  GET_ROLE: "GET_ROLE",
  UPDATE_ROLE: "UPDATE_ROLE",
  PERMISSION_LIST: "PERMISSION_LIST",
  SET_PERMISSION_LIST: "SET_PERMISSION_LIST",
  SELECTED_PERMISSION: "SELECTED_PERMISSION",
  SAVE_ROLE_DATA_LOADING: "SAVE_ROLE_DATA_LOADING",
  SAVE_ROLE_DATA_FAILED: "SAVE_ROLE_DATA_FAILED",
  SAVE_ROLE_DATA_SUCCESS: "SAVE_ROLE_DATA_SUCCESS",
  GET_ROLE_LOADING: "GET_ROLE_LOADING",
  GET_ROLE_FAILED: "GET_ROLE_FAILED",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  CHANGE_ROLE_DATA: "CHANGE_ROLE_DATA",
  ROLE_DISPLAY_NAME: "ROLE_DISPLAY_NAME",
  CHANGE_ROLES_FILTER_DATA: "CHANGE_ROLES_FILTER_DATA",
  CLEAR_ROLES_FILTER_DATA: "CLEAR_ROLES_FILTER_DATA",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_ROLE_PAGE",
  CHANGE_SORT: "CHANGE_ROLES_SORT",
  CLOSE_ROLE_DIALOG: "CLOSE_ROLE_DIALOG",
  SET_FILTER: "SET_FILTER_ROLE",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE_ROLE",
};

const initialRolesState = {
  roleList: undefined,
  totalRolesRecords: undefined,
  currentPage: 0,
  maxRolesPerPage: 10,
  newRoleId: undefined,
  permissionList: [],
  selectedPermission: [],
  roleData: {
    display_name: "",
    permission: [],
    status: 1,
  },

  listLoading: false,
  rolesSortField: "id",
  rolesSortOrder: "desc",
  rolesFilterData: {
    display_name: "",
    status: "",
  },
  saveRoleDataLoading: false,
  saveRoleDataSuccess: false,
  saveRoleDataFailed: false,
  getRoleDataLoading: false,
  getRoleDataSuccess: false,
  getRoleDataFailed: false,
  roleDisplayName: "",
  isfilter: false,
};

export const reducer = (state = initialRolesState, action) => {
  switch (action.type) {
    case actionTypes.SET_ROLES_LIST: {
      const { rolesData } = action.payload;

      const totalRolesRecords =
        state.isfilter && rolesData.recordsTotal
          ? rolesData.recordsFiltered
          : rolesData.recordsTotal;
      const rolesList = rolesData.data ? rolesData.data : undefined;
      return {
        ...state,
        totalRolesRecords,
        rolesList,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }

    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }

    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SAVE_ROLE_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveRoleDataLoading: flag,
      };
    }

    case actionTypes.SAVE_ROLE_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveRoleDataFailed: flag,
      };
    }

    case actionTypes.SAVE_ROLE_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveRoleDataSuccess: flag,
      };
    }

    case actionTypes.GET_ROLE_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getRoleDataLoading: flag,
      };
    }

    case actionTypes.GET_ROLE_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        getRoleDataSuccess: flag,
      };
    }

    case actionTypes.GET_ROLE_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        getRoleDataFailed: flag,
      };
    }

    case actionTypes.CHANGE_ROLE_DATA: {
      const { roleData } = action.payload;
      return {
        ...state,
        roleData: {
          display_name: roleData.display_name ? roleData.display_name : "",
          permission: roleData.permission ? roleData.permission : [],
          status: roleData.status ? roleData.status : 1,
        },
      };
    }

    case actionTypes.SET_PERMISSION_LIST: {
      const { permissionList } = action.payload;
      return {
        ...state,
        permissionList: permissionList,
      };
    }

    case actionTypes.SELECTED_PERMISSION: {
      const { selectedPermission } = action.payload;

      return {
        ...state,
        selectedPermission: selectedPermission,
      };
    }

    case actionTypes.ROLE_DISPLAY_NAME: {
      const { roleDisplayName } = action.payload;
      return {
        ...state,
        roleDisplayName: roleDisplayName,
      };
    }

    case actionTypes.CHANGE_ROLES_FILTER_DATA: {
      const { rolesFilterData } = action.payload;
      return {
        ...state,
        rolesFilterData: rolesFilterData,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        rolesSortField: field,
        rolesSortOrder: order,
      };
    }

    case actionTypes.CLOSE_ROLE_DIALOG: {
      return {
        ...state,
        roleData: {
          display_name: "",
          permission: [],
          status: 1,
        },
        saveRoleDataLoading: false,
        saveRoleDataSuccess: false,
        saveRoleDataFailed: false,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getRolesList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_ROLES_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setRolesList: (rolesData) => ({
    type: actionTypes.SET_ROLES_LIST,
    payload: { rolesData },
  }),
  createRole: (roleData, accessToken) => ({
    type: actionTypes.CREATE_ROLE,
    payload: { roleData, accessToken },
  }),
  updateRole: (roleId, roleData, accessToken) => ({
    type: actionTypes.UPDATE_ROLE,
    payload: { roleId, roleData, accessToken },
  }),
  getPermissionList: (accessToken) => ({
    type: actionTypes.PERMISSION_LIST,
    payload: { accessToken },
  }),
  setPermissionList: (permissionList) => ({
    type: actionTypes.SET_PERMISSION_LIST,
    payload: { permissionList },
  }),
  selectedPermissionList: (selectedPermission) => ({
    type: actionTypes.SELECTED_PERMISSION,
    payload: { selectedPermission },
  }),
  setRoleDisplayName: (roleDisplayName) => ({
    type: actionTypes.ROLE_DISPLAY_NAME,
    payload: { roleDisplayName },
  }),
  saveRoleDataLoading: (flag) => ({
    type: actionTypes.SAVE_ROLE_DATA_LOADING,
    payload: { flag },
  }),
  saveRoleDataFailed: (flag) => ({
    type: actionTypes.SAVE_ROLE_DATA_FAILED,
    payload: { flag },
  }),
  saveRoleDataSuccess: (flag) => ({
    type: actionTypes.SAVE_ROLE_DATA_SUCCESS,
    payload: { flag },
  }),
  changeRoleData: (roleData) => ({
    type: actionTypes.CHANGE_ROLE_DATA,
    payload: { roleData },
  }),
  getRole: (id, accessToken) => ({
    type: actionTypes.GET_ROLE,
    payload: { id, accessToken },
  }),
  getRoleLoading: (flag) => ({
    type: actionTypes.GET_ROLE_LOADING,
    payload: { flag },
  }),
  getRoleSuccess: (flag) => ({
    type: actionTypes.GET_ROLE_SUCCESS,
    payload: { flag },
  }),
  getRoleFailed: (flag) => ({
    type: actionTypes.GET_ROLE_FAILED,
    payload: { flag },
  }),
  changeRolesFilterData: (rolesFilterData) => ({
    type: actionTypes.CHANGE_ROLES_FILTER_DATA,
    payload: { rolesFilterData },
  }),
  clearRolesFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_ROLES_FILTER_DATA,
    payload: { accessToken },
  }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  closeUserDialog: () => ({ type: actionTypes.CLOSE_ROLE_DIALOG }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_ROLES_LIST, getRolesListSaga);
  yield takeLatest(actionTypes.PERMISSION_LIST, getPermissionListSaga);
  yield takeLatest(actionTypes.CREATE_ROLE, createRoleSaga);
  yield takeLatest(actionTypes.GET_ROLE, getRoleSaga);
  yield takeLatest(actionTypes.UPDATE_ROLE, updateRoleSaga);
  yield takeLatest(
    actionTypes.CLEAR_ROLES_FILTER_DATA,
    function* clearRolesFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        rolesSortField,
        rolesSortOrder,
        currentPage,
        maxRolesPerPage,
      } = yield select((state) => state.manageRoles);
      let rolesFilterData = {
        display_name: "",
        status: "",
      };
      yield put(actions.changeRolesFilterData(rolesFilterData));
      yield put(actions.setFilter(false));
      yield put(
        actions.getRolesList(
          rolesSortField,
          rolesSortOrder,
          currentPage,
          maxRolesPerPage,
          accessToken
        )
      );
    }
  );
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { rolesSortField, rolesSortOrder } = yield select(
      (state) => state.manageRoles
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getRolesList(
        rolesSortField,
        rolesSortOrder,
        page,
        length,
        accessToken
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxRolesPerPage } = yield select(
      (state) => state.manageRoles
    );
    yield put(
      actions.getRolesList(
        field,
        order,
        currentPage,
        maxRolesPerPage,
        accessToken
      )
    );
  });
}

function* getRolesListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setListLoading(true));
  const { rolesFilterData } = yield select((state) => state.manageRoles);
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]=display_name&columns[0][name]=&columns[0][searchable]=true&columns[0][orderable]=false&columns[0][search][value]=${rolesFilterData.display_name}&columns[0][search][regex]=false&columns[1][data]=status&columns[1][name]=&columns[1][searchable]=true&columns[1][orderable]=false&columns[1][search][value]=${rolesFilterData.status}&columns[1][search][regex]=false&columns[2][data]=${sortField}&columns[2][name]=&columns[2][searchable]=false&columns[2][orderable]=true&columns[2][search][value]&columns[2][search][regex]=false&order[0][column]=2&order[0][dir]=${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("ROLE_LIST_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setRolesList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setListLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Roles",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Get Roles",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Get Roles",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getPermissionListSaga(action) {
  let { accessToken } = action.payload;
  try {
    const response = yield call(
      getApiToken,
      apiUrl("PERMISSION_LIST_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        let permissonTemp = responseData.data.dataTable.data;
        let tempArr = [];
        permissonTemp.forEach(function(item) {
          tempArr.push({ value: item.id, label: item.display_name });
        });
        yield all([
          put(actions.setPermissionList(tempArr)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([put(actions.setListLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setListLoading(false))]);
        yield put(
          generalActions.addToast(
            "Create / Edit Role",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
  }
}

function* createRoleSaga(action) {
  let { roleData, accessToken } = action.payload;
  try {
    const response = yield call(
      postApiToken,
      apiUrl("CREATE_ROLE_URL"),
      accessToken,
      roleData
    );
    if (response.status === 200) {
      yield all([
        put(actions.saveRoleDataLoading(false)),
        put(actions.saveRoleDataSuccess(true)),
        put(actions.saveRoleDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Create Role",
          "Role saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveRoleDataLoading(false)),
          put(actions.saveRoleDataSuccess(false)),
          put(actions.saveRoleDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Create Role",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.saveRoleDataLoading(false)),
        put(actions.saveRoleDataSuccess(false)),
        put(actions.saveRoleDataFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveRoleDataLoading(false)),
      put(actions.saveRoleDataSuccess(false)),
      put(actions.saveRoleDataFailed(true)),
    ]);
  }
}

function* getRoleSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.setRoleDisplayName("")),
    put(actions.getRoleLoading(true)),
    put(actions.getRoleSuccess(false)),
    put(actions.getRoleFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("SHOW_ROLE_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        let permissonTemp = responseData.data.permission;
        let tempArr = [];
        permissonTemp.forEach(function(item) {
          tempArr.push({ value: item.id, label: item.display_name });
        });
        yield all([
          put(actions.changeRoleData(responseData.data)),
          put(actions.setRoleDisplayName(responseData.data.display_name)),
          put(actions.selectedPermissionList(tempArr)),
          put(actions.getRoleLoading(false)),
          put(actions.getRoleSuccess(true)),
          put(actions.getRoleFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getRoleLoading(false)),
          put(actions.getRoleSuccess(false)),
          put(actions.getRoleFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getRoleLoading(false)),
          put(actions.getRoleSuccess(false)),
          put(actions.getRoleFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Role",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getRoleLoading(false)),
        put(actions.getRoleSuccess(false)),
        put(actions.getRoleFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getRoleLoading(false)),
      put(actions.getRoleSuccess(false)),
      put(actions.getRoleFailed(true)),
    ]);
  }
}

function* updateRoleSaga(action) {
  let { roleId, roleData, accessToken } = action.payload;
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("UPDATE_ROLE_URL")}/${roleId}`,
      roleData,
      accessToken
    );
    if (response.status === 200) {
      yield all([
        put(actions.saveRoleDataLoading(false)),
        put(actions.saveRoleDataSuccess(true)),
        put(actions.saveRoleDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Update Role",
          "Role updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveRoleDataLoading(false)),
          put(actions.saveRoleDataSuccess(false)),
          put(actions.saveRoleDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Role",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.saveRoleDataLoading(false)),
        put(actions.saveRoleDataSuccess(false)),
        put(actions.saveRoleDataFailed(true)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveRoleDataLoading(false)),
      put(actions.saveRoleDataSuccess(false)),
      put(actions.saveRoleDataFailed(true)),
    ]);
  }
}
