import React from "react";
import { Modal } from "react-bootstrap";

import DesignerDialogHeader from "./DesignerDialogHeader";
import { DesignerForm } from "./DesignerForm";

export default function DesignerDialog({ id, onHide, show, edit, saveUser }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <DesignerDialogHeader userEdit={edit} id={id} />
      <DesignerForm
        id={id}
        actionsLoading={false}
        onHide={onHide}
        edit={edit}
      />
    </Modal>
  );
}
