/* eslint-disable no-unused-vars */
import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_LIST_LOADING: "SET_LIST_LOADING",
  GET_DESIGNERS_LIST: "GET_DESIGNERS_LIST",
  SET_DESIGNERS_LIST: "SET_DESIGNERS_LIST",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE_DESIGNERS",
  CLEAR_LIST: "CLEAR_LIST",
  GET_POSITIONS_DROPDOWN: "GET_POSITIONS_DROPDOWN",
  SET_POSITIONS_DROPDOWN: "SET_POSITIONS_DROPDOWN",
  SET_POSITIONS_DROPDOWN_LOADING: "SET_POSITIONS_DROPDOWN_LOADING",
  SET_POSITIONS_DROPDOWN_FAILED: "SET_POSITIONS_DROPDOWN_FAILED",
  CHANGE_DESIGNER_DATA: "CHANGE_DESIGNER_DATA",
  CLEAR_DESIGNER_DATA: "CLEAR_DESIGNER_DATA",
  SAVE_DESIGNER_DATA: "SAVE_DESIGNER_DATA",
  SAVE_DESIGNER_DATA_LOADING: "SAVE_DESIGNER_DATA_LOADING",
  SAVE_DESIGNER_DATA_FAILED: "SAVE_DESIGNER_DATA_FAILED",
  SAVE_DESIGNER_DATA_SUCCESS: "SAVE_DESIGNER_DATA_SUCCESS",
  CLOSE_DESIGNER_DIALOG: "CLOSE_DESIGNER_DIALOG",
  GET_DESIGNER: "GET_DESIGNER",
  GET_DESIGNER_LOADING: "GET_DESIGNER_LOADING",
  GET_DESIGNER_FAILED: "GET_DESIGNER_FAILED",
  GET_DESIGNER_SUCCESS: "GET_DESIGNER_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_DESIGNER: "UPDATE_DESIGNER",
  UPDATE_DESIGNER_LOADING: "UPDATE_DESIGNER_LOADING",
  UPDATE_DESIGNER_SUCCESS: "UPDATE_DESIGNER_SUCCESS",
  UPDATE_DESIGNER_FAILED: "UPDATE_DESIGNER_FAILED",
  CHANGE_SORT: "CHANGE_SORT_DESIGNERS",
  CHANGE_DESIGNERS_FILTER_DATA: "CHANGE_DESIGNERS_FILTER_DATA",
  CLEAR_DESIGNERS_FILTER_DATA: "CLEAR_DESIGNERS_FILTER_DATA",
  CLEAR_ALL: "CLEAR_ALL",
  SET_FILTER: "SET_FILTER",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE_CLIENT",
};

const initialDesirnersState = {
  designersList: undefined,
  totalDesignersRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxDesignersPerPage: 10,
  designersSortField: "id",
  designersSortOrder: "desc",
  designersFilterData: {
    name: "",
    email: "",
    status: "",
  },
  designerData: {
    name: "",
    email: "",
    password: "",
    position_id: undefined,
    status: "1",
  },
  positions: undefined,
  positionsDropdownLoading: false,
  positionsDropdownFailed: false,
  saveDesignerDataLoading: false,
  saveDesignerDataSuccess: false,
  saveDesignerDataFailed: false,
  updateDesignerSuccess: false,
  updateDesignerLoading: false,
  updateDesignerFailed: false,
  isfilter: false,
};

export const reducer = (state = initialDesirnersState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }

    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }

    case actionTypes.CLEAR_LIST: {
      return {
        ...state,
        totalDesignersRecords: undefined,
        designersList: undefined,
      };
    }

    case actionTypes.SET_DESIGNERS_LIST: {
      const { designersData } = action.payload;
      const totalDesignersRecords =
        state.isfilter && designersData.recordsTotal
          ? designersData.recordsFiltered
          : designersData.recordsTotal;
      const designersList = designersData.data ? designersData.data : undefined;
      return {
        ...state,
        totalDesignersRecords,
        designersList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        designersSortField: field,
        designersSortOrder: order,
      };
    }

    case actionTypes.CHANGE_DESIGNERS_FILTER_DATA: {
      const { designersFilterData } = action.payload;
      return {
        ...state,
        designersFilterData: designersFilterData,
      };
    }

    case actionTypes.SET_POSITIONS_DROPDOWN: {
      const { data } = action.payload;
      let positionsData = [];
      for (var key of Object.keys(data)) {
        let position = {
          id: key,
          name: data[key],
        };
        positionsData.push(position);
      }
      return {
        ...state,
        positions:
          positionsData && positionsData.length !== 0
            ? positionsData
            : undefined,
      };
    }

    case actionTypes.SET_POSITIONS_DROPDOWN_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        positionsDropdownLoading: flag,
      };
    }

    case actionTypes.SET_POSITIONS_DROPDOWN_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        positionsDropdownFailed: flag,
      };
    }

    case actionTypes.CHANGE_DESIGNER_DATA: {
      const { designerData } = action.payload;
      if (!designerData.position_id && designerData.positions) {
        designerData.position_id =
          designerData.positions &&
          designerData.positions[0] &&
          designerData.positions[0].id
            ? designerData.positions[0].id
            : undefined;
      }
      return {
        ...state,
        designerData: {
          name: designerData.name ? designerData.name : "",
          password: designerData.password ? designerData.password : "",
          email: designerData.email ? designerData.email : "",
          position_id: designerData.position_id
            ? designerData.position_id
            : undefined,
          status:
            designerData.status !== null && designerData.status !== undefined
              ? designerData.status
              : "1",
        },
      };
    }

    case actionTypes.CLEAR_DESIGNER_DATA: {
      return {
        ...state,
        designerData: {
          name: "",
          password: "",
          email: "",
          status: "1",
          position_id: undefined,
        },
      };
    }

    case actionTypes.SAVE_DESIGNER_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveDesignerDataLoading: flag,
      };
    }

    case actionTypes.SAVE_DESIGNER_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveDesignerDataFailed: flag,
      };
    }

    case actionTypes.SAVE_DESIGNER_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveDesignerDataSuccess: flag,
      };
    }

    case actionTypes.CLOSE_DESIGNER_DIALOG: {
      return {
        ...state,
        designerData: {
          name: "",
          password: "",
          email: "",
          status: "1",
          position_id: undefined,
        },
        positions: undefined,
        positionsDropdownLoading: false,
        positionsDropdownFailed: false,
        saveDesignerDataLoading: false,
        saveDesignerDataSuccess: false,
        saveDesignerDataFailed: false,
        updateDesignerFailed: false,
        updateDesignerLoading: false,
        updateDesignerSuccess: false,
      };
    }

    case actionTypes.CHANGE_PASSWORD: {
      const { password } = action.payload;
      return {
        ...state,
        designerData: {
          ...state.designerData,
          password: password,
        },
      };
    }

    case actionTypes.UPDATE_DESIGNER_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateDesignerSuccess: flag,
      };
    }

    case actionTypes.UPDATE_DESIGNER_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateDesignerFailed: flag,
      };
    }

    case actionTypes.UPDATE_DESIGNER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateDesignerLoading: flag,
      };
    }

    case actionTypes.CLEAR_ALL: {
      return {
        designersList: undefined,
        totalDesignersRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxDesignersPerPage: 10,
        designersSortField: "id",
        designersSortOrder: "desc",
        designersFilterData: {
          name: "",
          email: "",
          status: "",
        },
        designerData: {
          name: "",
          password: "",
          email: "",
          status: "1",
          position_id: undefined,
        },
        positions: undefined,
        positionsDropdownLoading: false,
        positionsDropdownFailed: false,
        saveDesignerDataLoading: false,
        saveDesignerDataSuccess: false,
        saveDesignerDataFailed: false,
        updateDesignerSuccess: false,
        updateDesignerLoading: false,
        updateDesignerFailed: false,
        isfilter: false,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getDesignersList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_DESIGNERS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setDesignersList: (designersData) => ({
    type: actionTypes.SET_DESIGNERS_LIST,
    payload: { designersData },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken },
  }),
  changeDesignersFilterData: (designersFilterData) => ({
    type: actionTypes.CHANGE_DESIGNERS_FILTER_DATA,
    payload: { designersFilterData },
  }),
  clearDesignersFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_DESIGNERS_FILTER_DATA,
    payload: { accessToken },
  }),
  getPositionsDropdown: (accessToken) => ({
    type: actionTypes.GET_POSITIONS_DROPDOWN,
    payload: { accessToken },
  }),
  setPositionsDropdown: (data) => ({
    type: actionTypes.SET_POSITIONS_DROPDOWN,
    payload: { data },
  }),
  setPositionsDropdownLoading: (flag) => ({
    type: actionTypes.SET_POSITIONS_DROPDOWN_LOADING,
    payload: { flag },
  }),
  setPositionsDropdownFailed: (flag) => ({
    type: actionTypes.SET_POSITIONS_DROPDOWN_FAILED,
    payload: { flag },
  }),
  changeDesignerData: (designerData) => ({
    type: actionTypes.CHANGE_DESIGNER_DATA,
    payload: { designerData },
  }),
  clearDesignerData: () => ({ type: actionTypes.CLEAR_DESIGNER_DATA }),
  saveDesigner: (designerData, accessToken) => ({
    type: actionTypes.SAVE_DESIGNER_DATA,
    payload: { designerData, accessToken },
  }),
  saveDesignerDataLoading: (flag) => ({
    type: actionTypes.SAVE_DESIGNER_DATA_LOADING,
    payload: { flag },
  }),
  saveDesignerDataFailed: (flag) => ({
    type: actionTypes.SAVE_DESIGNER_DATA_FAILED,
    payload: { flag },
  }),
  saveDesignerDataSuccess: (flag) => ({
    type: actionTypes.SAVE_DESIGNER_DATA_SUCCESS,
    payload: { flag },
  }),
  closeDesignerDialog: () => ({ type: actionTypes.CLOSE_DESIGNER_DIALOG }),
  getDesigner: (id, accessToken) => ({
    type: actionTypes.GET_DESIGNER,
    payload: { id, accessToken },
  }),
  getDesignerLoading: (flag) => ({
    type: actionTypes.GET_DESIGNER_LOADING,
    payload: { flag },
  }),
  getDesignerSuccess: (flag) => ({
    type: actionTypes.GET_DESIGNER_SUCCESS,
    payload: { flag },
  }),
  getDesignerFailed: (flag) => ({
    type: actionTypes.GET_DESIGNER_FAILED,
    payload: { flag },
  }),
  changePassword: (password) => ({
    type: actionTypes.CHANGE_PASSWORD,
    payload: { password },
  }),
  updateDesigner: (id, designerData, accessToken) => ({
    type: actionTypes.UPDATE_DESIGNER,
    payload: { id, designerData, accessToken },
  }),
  updateDesignerSuccess: (flag) => ({
    type: actionTypes.UPDATE_DESIGNER_SUCCESS,
    payload: { flag },
  }),
  updateDesignerLoading: (flag) => ({
    type: actionTypes.UPDATE_DESIGNER_LOADING,
    payload: { flag },
  }),
  updateDesignerFailed: (flag) => ({
    type: actionTypes.UPDATE_DESIGNER_FAILED,
    payload: { flag },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL }),
};

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { designersSortField, designersSortOrder } = yield select(
      (state) => state.manageDesigners
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getDesignersList(
        designersSortField,
        designersSortOrder,
        page,
        length,
        accessToken
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxDesignersPerPage } = yield select(
      (state) => state.manageDesigners
    );
    yield put(
      actions.getDesignersList(
        field,
        order,
        currentPage,
        maxDesignersPerPage,
        accessToken
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_DESIGNERS_FILTER_DATA,
    function* clearDesignersFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        designersSortField,
        designersSortOrder,
        currentPage,
        maxDesignersPerPage,
      } = yield select((state) => state.manageDesigners);
      let designersFilterData = {
        name: "",
        email: "",
        status: "",
      };
      yield put(actions.changeDesignersFilterData(designersFilterData));
      yield put(
        actions.getDesignersList(
          designersSortField,
          designersSortOrder,
          currentPage,
          maxDesignersPerPage,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.GET_DESIGNERS_LIST, getDesignersListSaga);

  yield takeLatest(actionTypes.GET_POSITIONS_DROPDOWN, getPositionsListSaga);

  yield takeLatest(actionTypes.SAVE_DESIGNER_DATA, saveDesignerSaga);

  yield takeLatest(actionTypes.GET_DESIGNER, getDesignerSaga);

  yield takeLatest(actionTypes.UPDATE_DESIGNER, updateDesignerSaga);
}

function* getDesignersListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setListLoading(true));
  const { designersFilterData } = yield select(
    (state) => state.manageDesigners
  );
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]=name&columns[0][name]=&columns[0][searchable]=true&columns[0][orderable]=false&columns[0][search][value]=${designersFilterData.name}&columns[0][search][regex]=false&columns[1][data]=email&columns[1][name]=&columns[1][searchable]=true&columns[1][orderable]=true&columns[1][search][value]=${designersFilterData.email}&columns[1][search][regex]=false&columns[2][data]=status&columns[2][name]=&columns[2][searchable]=true&columns[2][orderable]=true&columns[2][search][value]=${designersFilterData.status}&columns[2][search][regex]=false&order[0][column]=1&order[0][dir]=${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("DESIGNER_LIST_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setDesignersList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Designers List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Designers List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Designers List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getPositionsListSaga(action) {
  let { accessToken } = action.payload;
  yield all([
    put(actions.setPositionsDropdownLoading(true)),
    put(actions.setPositionsDropdownFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_POSITIONS_DROPDOWN_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setPositionsDropdown(responseData.data)),
          put(actions.setPositionsDropdownLoading(false)),
          put(actions.setPositionsDropdownFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setPositionsDropdownLoading(false)),
          put(actions.setPositionsDropdownFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.setPositionsDropdownLoading(false)),
          put(actions.setPositionsDropdownFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Positions Dropdown",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.setPositionsDropdownLoading(false)),
        put(actions.setPositionsDropdownFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Positions Dropdown",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setPositionsDropdownLoading(false)),
      put(actions.setPositionsDropdownFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Positions Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveDesignerSaga(action) {
  let { designerData, accessToken } = action.payload;
  yield all([
    put(actions.saveDesignerDataLoading(true)),
    put(actions.saveDesignerDataSuccess(false)),
    put(actions.saveDesignerDataFailed(false)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SAVE_DESIGNER_URL"),
      accessToken,
      queryString.stringify(designerData)
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxDesignersPerPage,
        designersSortField,
        designersSortOrder,
      } = yield select((state) => state.manageDesigners);
      yield all([
        put(
          actions.getDesignersList(
            designersSortField,
            designersSortOrder,
            currentPage,
            maxDesignersPerPage,
            accessToken
          )
        ),
        put(actions.saveDesignerDataLoading(false)),
        put(actions.saveDesignerDataSuccess(true)),
        put(actions.saveDesignerDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Save Designer",
          "Designer saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveDesignerDataLoading(false)),
          put(actions.saveDesignerDataSuccess(false)),
          put(actions.saveDesignerDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save Designer",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.saveDesignerDataLoading(false)),
        put(actions.saveDesignerDataSuccess(false)),
        put(actions.saveDesignerDataFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Save Designer",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveDesignerDataLoading(false)),
      put(actions.saveDesignerDataSuccess(false)),
      put(actions.saveDesignerDataFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Save Designer",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getDesignerSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getDesignerLoading(true)),
    put(actions.getDesignerSuccess(false)),
    put(actions.getDesignerFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_DESIGNER_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.changeDesignerData(responseData.data)),
          put(actions.getDesignerLoading(false)),
          put(actions.getDesignerSuccess(true)),
          put(actions.getDesignerFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getDesignerLoading(false)),
          put(actions.getDesignerSuccess(false)),
          put(actions.getDesignerFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getDesignerLoading(false)),
          put(actions.getDesignerSuccess(false)),
          put(actions.getDesignerFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Designer",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getDesignerLoading(false)),
        put(actions.getDesignerSuccess(false)),
        put(actions.getDesignerFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Get Designer",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getDesignerLoading(false)),
      put(actions.getDesignerSuccess(false)),
      put(actions.getDesignerFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Get Designer",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateDesignerSaga(action) {
  let { id, designerData, accessToken } = action.payload;
  let body = { ...designerData };
  if (!body.password || body.password === "") {
    delete body.password;
  }

  yield all([
    put(actions.updateDesignerLoading(true)),
    put(actions.updateDesignerSuccess(false)),
    put(actions.updateDesignerFailed(false)),
  ]);
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("UPDATE_DESIGNER_URL")}/${id}`,
      queryString.stringify(body),
      accessToken
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxDesignersPerPage,
        designersSortField,
        designersSortOrder,
      } = yield select((state) => state.manageDesigners);
      yield all([
        put(
          actions.getDesignersList(
            designersSortField,
            designersSortOrder,
            currentPage,
            maxDesignersPerPage,
            accessToken
          )
        ),
        put(actions.updateDesignerSuccess(true)),
        put(actions.updateDesignerLoading(false)),
        put(actions.updateDesignerFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Update Designer",
          "Designer updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.updateDesignerLoading(false)),
          put(actions.updateDesignerSuccess(false)),
          put(actions.updateDesignerFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Designer",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.updateDesignerLoading(false)),
        put(actions.updateDesignerSuccess(false)),
        put(actions.updateDesignerFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Update Designer",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateDesignerLoading(false)),
      put(actions.updateDesignerSuccess(false)),
      put(actions.updateDesignerFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Update Designer",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
