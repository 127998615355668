/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
// import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import StatusColumnFormatter from "../components/StatusColumnFormatter";
// import TypeColumnFormatter from "../components/TypeColumnFormatter";
import DesignerDialog from "../components/DesignerDialog";
// import apiUrl from "../../../../configs/urls";
// import { getApiToken } from "../../../../redux/apis";
import { actions as manageDesignersActions } from "../redux/manageDesignersRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import DesignersFilterForm from "../components/DesignersFilterForm";

export default function ManageDesigners() {
  const dispatch = useDispatch();
  const {
    listLoading,
    designersList,
    totalDesignersRecords,
    currentPage,
    maxDesignersPerPage,
    designersSortField,
    designersSortOrder,
  } = useSelector((state) => state.manageDesigners);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(
      manageDesignersActions.getDesignersList(
        designersSortField,
        designersSortOrder,
        currentPage,
        maxDesignersPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalDesignersRecords ? totalDesignersRecords : 0,
    sizePerPage: maxDesignersPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if(page !== 0){
      dispatch(
        manageDesignersActions.changeCurrentPage(
          page - 1,
          maxDesignersPerPage,
          cookies.accessToken
        )
      );}
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== designersSortField ||
        sortOrder !== designersSortOrder
      ) {
        dispatch(
          manageDesignersActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const applyFilter = () => {
    dispatch(manageDesignersActions.setFilter(true));
    dispatch(manageDesignersActions.setCurrentPage(0));
    dispatch(
      manageDesignersActions.getDesignersList(
        designersSortField,
        designersSortOrder,
        0,
        maxDesignersPerPage,
        cookies.accessToken
      )
    );
  };

  const onHideDesignerDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageDesignersActions.closeDesignerDialog());
  };

  const openEditDesignerDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: function(cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "position_name",
      text: "Position",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditDesignerDialog: (id) => openEditDesignerDialog(true, id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Designers list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <DesignersFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-black-primary"
            onClick={() => openEditDesignerDialog(false, null)}
          >
            New Designer
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter /> */}
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <Table
          listLoading={listLoading}
          entities={designersList ? designersList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalDesignersRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{
            dataField: designersSortField,
            order: designersSortOrder,
          }}
        />
      </CardBody>
      <DesignerDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideDesignerDialog()}
      />
    </Card>
  );
}
