import React, { memo } from 'react';
import { Input } from "../../../../_metronic/_partials/controls";
import { Field } from "formik";

/**
 * This component only return `Max Revisions` Input field.
 * @param {Number} value 
 * @param {Function} handleFormChange 
 * @param {Boolean} formLoading 
 * @returns {Component<Props>}
 */
const ClientFormMaxRevisionInput = ({ Value, handleFormChange, formLoading }) => {

    const handleMaxRevisionKeyPress = e => {
        if (!(e.charCode >= 48 && e.charCode <= 57)) {
            // preventing only when pressed key is not a number.
            e.preventDefault();
        }
    }

    return (
        <div>
            <label>Enter Max Revisions</label>
            <Field
                value={Value}
                onChange={handleFormChange}
                type="number"
                name="max_revision_request"
                component={Input}
                placeholder="Max Revisions"
                step={1}
                min={0}
                max={99}
                disabled={formLoading}
                onKeyPress={handleMaxRevisionKeyPress}
            />
        <div class="feedback">Please enter <b>Max Revisions</b></div>
        </div>
        
    );
}

export default memo(ClientFormMaxRevisionInput);