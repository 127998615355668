import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_LIST_LOADING: "SET_LIST_LOADING",
  GET_TEAMS_LIST: "GET_TEAMS_LIST",
  SET_TEAMS_LIST: "SET_TEAMS_LIST",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE_TEAMS",
  CLEAR_LIST: "CLEAR_LIST",
  GET_TEAMS_FORM_DROPDOWN_DATA: "GET_TEAMS_FORM_DROPDOWN_DATA",
  SET_TEAMS_FORM_DROPDOWN_DATA: "SET_TEAMS_FORM_DROPDOWN_DATA",
  SET_TEAMS_FORM_DROPDOWN_DATA_LOADING: "SET_TEAMS_FORM_DROPDOWN_DATA_LOADING",
  SET_TEAMS_FORM_DROPDOWN_DATA_FAILED: "SET_TEAMS_FORM_DROPDOWN_DATA_FAILED",

  GET_EDIT_TEAMS_FORM_DROPDOWN_DATA: "GET_EDIT_TEAMS_FORM_DROPDOWN_DATA",

  CHANGE_TEAM_DATA: "CHANGE_TEAM_DATA",
  CLEAR_TEAM_DATA: "CLEAR_TEAM_DATA",
  SAVE_TEAM_DATA: "SAVE_TEAM_DATA",
  SAVE_TEAM_DATA_LOADING: "SAVE_TEAM_DATA_LOADING",
  SAVE_TEAM_DATA_FAILED: "SAVE_TEAM_DATA_FAILED",
  SAVE_TEAM_DATA_SUCCESS: "SAVE_TEAM_DATA_SUCCESS",
  CLOSE_TEAM_DIALOG: "CLOSE_TEAM_DIALOG",
  GET_TEAM: "GET_TEAM",
  GET_TEAM_LOADING: "GET_TEAM_LOADING",
  GET_TEAM_FAILED: "GET_TEAM_FAILED",
  GET_TEAM_SUCCESS: "GET_TEAM_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_TEAM: "UPDATE_TEAM",
  UPDATE_TEAM_LOADING: "UPDATE_TEAM_LOADING",
  UPDATE_TEAM_SUCCESS: "UPDATE_TEAM_SUCCESS",
  UPDATE_TEAM_FAILED: "UPDATE_TEAM_FAILED",
  CHANGE_SORT: "CHANGE_SORT_TEAMS",
  CHANGE_TEAMS_FILTER_DATA: "CHANGE_TEAMS_FILTER_DATA",
  CLEAR_TEAMS_FILTER_DATA: "CLEAR_TEAMS_FILTER_DATA",
  CLEAR_ALL: "CLEAR_ALL",
  SET_FILTER: "SET_FILTER",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE_CLIENT",
};

const initialTeamState = {
  teamsList: undefined,
  totalTeamsRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxTeamsPerPage: 10,
  teamsSortField: "id",
  teamsSortOrder: "desc",
  teamsFilterData: {
    id:"",
    name: "",
    email: "",
    status: "",
  },
  teamData: {
    name: "",
    email: "",
    password: "",
    position_id: undefined,
    status: "1",
  },
  teamFormData: {},
  teamFormDropdownLoading: false,
  teamFormDropdownFailed: false,
  saveTeamDataLoading: false,
  saveTeamDataSuccess: false,
  saveTeamDataFailed: false,
  updateTeamSuccess: false,
  updateTeamLoading: false,
  updateTeamFailed: false,
  isFilter: false,
};

export const reducer = (state = initialTeamState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isFilter: flag,
      };
    }

    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }

    case actionTypes.CLEAR_LIST: {
      return {
        ...state,
        totalTeamsRecords: undefined,
        teamsList: undefined,
      };
    }

    case actionTypes.SET_TEAMS_LIST: {
      const { teamsData } = action.payload;
      const totalTeamsRecords =
        state.isFilter && teamsData.recordsTotal
          ? teamsData.recordsFiltered
          : teamsData.recordsTotal;
      const teamsList = teamsData.data ? teamsData.data : undefined;
      return {
        ...state,
        totalTeamsRecords,
        teamsList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        teamsSortField: field,
        teamsSortOrder: order,
      };
    }

    case actionTypes.CHANGE_TEAMS_FILTER_DATA: {
      const { teamsFilterData } = action.payload;
      return {
        ...state,
        teamsFilterData: teamsFilterData,
      };
    }

    case actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        teamFormData: data,
      };
    }

    case actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        teamFormDropdownLoading: flag,
      };
    }

    case actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        teamFormDropdownFailed: flag,
      };
    }

    case actionTypes.CHANGE_TEAM_DATA: {
      const { teamData } = action.payload;
      return {
        ...state,
        teamData: teamData,
      };
    }

    case actionTypes.CLEAR_TEAM_DATA: {
      return {
        ...state,
        teamData: {},
      };
    }

    case actionTypes.SAVE_TEAM_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveTeamDataLoading: flag,
      };
    }

    case actionTypes.SAVE_TEAM_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveTeamDataFailed: flag,
      };
    }

    case actionTypes.SAVE_TEAM_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveTeamDataSuccess: flag,
      };
    }

    case actionTypes.CLOSE_TEAM_DIALOG: {
      return {
        ...state,
        teamData: {},
        positions: undefined,
        positionsDropdownLoading: false,
        positionsDropdownFailed: false,
        saveTeamDataLoading: false,
        saveTeamDataSuccess: false,
        saveTeamDataFailed: false,
        updateTeamFailed: false,
        updateTeamLoading: false,
        updateTeamSuccess: false,
      };
    }

    case actionTypes.UPDATE_TEAM_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateTeamSuccess: flag,
      };
    }

    case actionTypes.UPDATE_TEAM_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateTeamFailed: flag,
      };
    }

    case actionTypes.UPDATE_TEAM_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateTeamLoading: flag,
      };
    }

    case actionTypes.CLEAR_ALL: {
      return {
        teamsList: undefined,
        totalTeamsRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxTeamsPerPage: 10,
        teamsSortField: "id",
        teamsSortOrder: "desc",
        teamsFilterData: {
          name: "",
          email: "",
          status: "",
        },
        teamData: {},
        positions: undefined,
        positionsDropdownLoading: false,
        positionsDropdownFailed: false,
        saveTeamDataLoading: false,
        saveTeamDataSuccess: false,
        saveTeamDataFailed: false,
        updateTeamSuccess: false,
        updateTeamLoading: false,
        updateTeamFailed: false,
        isFilter: false,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getTeamsList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_TEAMS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setTeamsList: (teamsData) => ({
    type: actionTypes.SET_TEAMS_LIST,
    payload: { teamsData },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken },
  }),
  changeTeamsFilterData: (teamsFilterData) => ({
    type: actionTypes.CHANGE_TEAMS_FILTER_DATA,
    payload: { teamsFilterData },
  }),
  clearTeamsFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_TEAMS_FILTER_DATA,
    payload: { accessToken },
  }),
  getTeamFormDropdownsData: (accessToken) => ({
    type: actionTypes.GET_TEAMS_FORM_DROPDOWN_DATA,
    payload: { accessToken },
  }),
  setTeamFormDropdownsData: (data) => ({
    type: actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA,
    payload: { data },
  }),
  setTeamFormDropdownsLoading: (flag) => ({
    type: actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA_LOADING,
    payload: { flag },
  }),
  setTeamFormDropdownsFailed: (flag) => ({
    type: actionTypes.SET_TEAMS_FORM_DROPDOWN_DATA_FAILED,
    payload: { flag },
  }),
  getEditTeamFormDropdownsData: (id, accessToken) => ({
    type: actionTypes.GET_EDIT_TEAMS_FORM_DROPDOWN_DATA,
    payload: { id, accessToken },
  }),
  changeTeamData: (teamData) => ({
    type: actionTypes.CHANGE_TEAM_DATA,
    payload: { teamData },
  }),
  clearTeamData: () => ({ type: actionTypes.CLEAR_TEAM_DATA }),
  saveTeam: (teamData, accessToken) => ({
    type: actionTypes.SAVE_TEAM_DATA,
    payload: { teamData, accessToken },
  }),
  saveTeamDataLoading: (flag) => ({
    type: actionTypes.SAVE_TEAM_DATA_LOADING,
    payload: { flag },
  }),
  saveTeamDataFailed: (flag) => ({
    type: actionTypes.SAVE_TEAM_DATA_FAILED,
    payload: { flag },
  }),
  saveTeamDataSuccess: (flag) => ({
    type: actionTypes.SAVE_TEAM_DATA_SUCCESS,
    payload: { flag },
  }),
  closeTeamDialog: () => ({ type: actionTypes.CLOSE_TEAM_DIALOG }),
  getTeam: (id, accessToken) => ({
    type: actionTypes.GET_TEAM,
    payload: { id, accessToken },
  }),
  getTeamLoading: (flag) => ({
    type: actionTypes.GET_TEAM_LOADING,
    payload: { flag },
  }),
  getTeamSuccess: (flag) => ({
    type: actionTypes.GET_TEAM_SUCCESS,
    payload: { flag },
  }),
  getTeamFailed: (flag) => ({
    type: actionTypes.GET_TEAM_FAILED,
    payload: { flag },
  }),
  changePassword: (password) => ({
    type: actionTypes.CHANGE_PASSWORD,
    payload: { password },
  }),
  updateTeam: (id, teamData, accessToken) => ({
    type: actionTypes.UPDATE_TEAM,
    payload: { id, teamData, accessToken },
  }),
  updateTeamSuccess: (flag) => ({
    type: actionTypes.UPDATE_TEAM_SUCCESS,
    payload: { flag },
  }),
  updateTeamLoading: (flag) => ({
    type: actionTypes.UPDATE_TEAM_LOADING,
    payload: { flag },
  }),
  updateTeamFailed: (flag) => ({
    type: actionTypes.UPDATE_TEAM_FAILED,
    payload: { flag },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL }),
};

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { teamsSortField, teamsSortOrder } = yield select(
      (state) => state.manageTeams
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getTeamsList(
        teamsSortField,
        teamsSortOrder,
        page,
        length,
        accessToken
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxTeamsPerPage } = yield select(
      (state) => state.manageTeams
    );
    yield put(
      actions.getTeamsList(
        field,
        order,
        currentPage,
        maxTeamsPerPage,
        accessToken
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_TEAMS_FILTER_DATA,
    function* clearTeamsFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        teamsSortField,
        teamsSortOrder,
        currentPage,
        maxTeamsPerPage,
      } = yield select((state) => state.manageTeams);
      let teamsFilterData = {
        name: "",
        email: "",
        status: "",
      };
      yield put(actions.changeTeamsFilterData(teamsFilterData));
      yield put(
        actions.getTeamsList(
          teamsSortField,
          teamsSortOrder,
          currentPage,
          maxTeamsPerPage,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.GET_TEAMS_LIST, getTeamsListSaga);

  yield takeLatest(
    actionTypes.GET_TEAMS_FORM_DROPDOWN_DATA,
    getTeamsFormDataSaga
  );

  yield takeLatest(
    actionTypes.GET_EDIT_TEAMS_FORM_DROPDOWN_DATA,
    getTeamsFormDataSaga
  );

  yield takeLatest(actionTypes.SAVE_TEAM_DATA, saveTeamSaga);

  yield takeLatest(actionTypes.GET_TEAM, getTeamSaga);

  yield takeLatest(actionTypes.UPDATE_TEAM, updateTeamSaga);
}

function* getTeamsListSaga(action) {
  let {
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
  } = action.payload;
  yield put(actions.setListLoading(true));
  const { teamsFilterData } = yield select((state) => state.manageTeams);
  const filterParams = {
    start: page * length,
    length: length,
  };

  const orderById = (sortField === 'id')?'true':'false';
  const orderByName = (sortField === 'pm_id_text')?'true':'false';
  const orderByEmail = (sortField === 'pm_email')?'true':'false';
  const orderByStatus = (sortField === 'is_active')?'true':'false';

  const sortParams = `&columns[0][data]=id&columns[0][name]=&columns[0][searchable]=false&columns[0][orderable]=${orderById}&columns[0][search][value]=${teamsFilterData.id}&columns[0][search][regex]=false&columns[1][data]=name&columns[1][name]=&columns[1][searchable]=true&columns[1][orderable]=${orderByName}&columns[1][search][value]=${teamsFilterData.name}&columns[1][search][regex]=false&columns[2][data]=email&columns[2][name]=&columns[2][searchable]=true&columns[2][orderable]=${orderByEmail}&columns[2][search][value]=${teamsFilterData.email}&columns[2][search][regex]=false&columns[3][data]=status&columns[3][name]=&columns[3][searchable]=true&columns[3][orderable]=${orderByStatus}&columns[3][search][value]=${teamsFilterData.status}&columns[3][search][regex]=false&order[0][column]=1&order[0][dir]=${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("TEAM_LIST_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setTeamsList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Teams List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Teams List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Teams List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getTeamsFormDataSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.setTeamFormDropdownsLoading(true)),
    put(actions.setTeamFormDropdownsFailed(false)),
  ]);
  try {
    const apiUrlString = id
      ? `${apiUrl("TEAM_EDIT_FORM_DATA")}/${id}/edit`
      : apiUrl("TEAM_CREATE_FORM_DATA");
    const response = yield call(getApiToken, apiUrlString, null, accessToken);
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        const teamFormData = responseData.data || {};
        const { team } = teamFormData;
        if (team) {
          const {
            pm_id,
            pm_id_text,
            pm_is_active_text,
            pm_position_id_text,
            tls,
          } = team;
          if (pm_id) {
            teamFormData.pms = teamFormData?.pms?.map((item) => {
              if (item.id === pm_id) {
                item.name = `${pm_id_text}-(${pm_position_id_text})-(${pm_is_active_text})`;
              }
              return item;
            });
          }
          if (tls.length) {
            if (teamFormData.tls?.length) {
              teamFormData.tls = teamFormData?.tls?.map((item) => {
                const tlData = tls.find((m) => m.tl_id === item.id);
                if (tlData) {
                  const {
                    tl_id_text,
                    tl_is_active_text,
                    tl_position_id_text,
                  } = tlData;
                  item.name = `${tl_id_text}-(${tl_position_id_text})-(${tl_is_active_text})`;
                }
                return item;
              });
            }
            if (teamFormData?.designers?.length) {
              teamFormData.designers = teamFormData?.designers?.map((item) => {
                let designerData = undefined;
                for (let tl of tls) {
                  designerData = tl.designers?.find(
                    (m) => m.designer_id === item.id
                  );
                }
                if (designerData) {
                  const {
                    designer_id_text,
                    designer_is_active_text,
                    designer_position_id_text
                  } = designerData;
                  item.name = `${designer_id_text}-(${designer_position_id_text})-(${designer_is_active_text})`;
                }
                return item;
              });
            }
          }
        }

        yield all([
          put(actions.setTeamFormDropdownsData(teamFormData)),
          put(actions.setTeamFormDropdownsLoading(false)),
          put(actions.setTeamFormDropdownsFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setTeamFormDropdownsLoading(false)),
          put(actions.setTeamFormDropdownsFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.setTeamFormDropdownsLoading(false)),
          put(actions.setTeamFormDropdownsFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Team Form Dropdown",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.setTeamFormDropdownsLoading(false)),
        put(actions.setTeamFormDropdownsFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Team Form Dropdown",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setTeamFormDropdownsLoading(false)),
      put(actions.setTeamFormDropdownsFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Positions Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveTeamSaga(action) {
  let { teamData, accessToken } = action.payload;
  yield all([
    put(actions.saveTeamDataLoading(true)),
    put(actions.saveTeamDataSuccess(false)),
    put(actions.saveTeamDataFailed(false)),
  ]);
  try {
    const formattedData = {};
    teamData.members.forEach((member, index) => {
      Object.keys(member).forEach((key) => {
        if (Array.isArray(member[key])) {
          member[key].forEach((value, i) => {
            formattedData[`members[${index}][${key}][${i}]`] = value;
          });
        } else {
          formattedData[`members[${index}][${key}]`] = member[key];
        }
      });
    });

    formattedData.created_by = teamData.created_by;
    formattedData.is_active = teamData.is_active;
    formattedData.pm_id = teamData.pm_id;

    const response = yield call(
      postApiToken,
      apiUrl("SAVE_TEAM_URL"),
      accessToken,
      queryString.stringify(formattedData, {
        encode: false,
      })
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxTeamsPerPage,
        teamsSortField,
        teamsSortOrder,
      } = yield select((state) => state.manageTeams);
      yield all([
        put(
          actions.getTeamsList(
            teamsSortField,
            teamsSortOrder,
            currentPage,
            maxTeamsPerPage,
            accessToken
          )
        ),
        put(actions.saveTeamDataLoading(false)),
        put(actions.saveTeamDataSuccess(true)),
        put(actions.saveTeamDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Save Team",
          "Team saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveTeamDataLoading(false)),
          put(actions.saveTeamDataSuccess(false)),
          put(actions.saveTeamDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save Team",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.saveTeamDataLoading(false)),
        put(actions.saveTeamDataSuccess(false)),
        put(actions.saveTeamDataFailed(true)),
      ]);
      yield put(
        generalActions.addToast("Save Team", errorData.message, "error", uid())
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveTeamDataLoading(false)),
      put(actions.saveTeamDataSuccess(false)),
      put(actions.saveTeamDataFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Save Team",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getTeamSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getTeamLoading(true)),
    put(actions.getTeamSuccess(false)),
    put(actions.getTeamFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("GET_TEAM_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData?.data?.team) {
        yield all([
          put(actions.changeTeamData(responseData?.data?.team)),
          put(actions.getTeamLoading(false)),
          put(actions.getTeamSuccess(true)),
          put(actions.getTeamFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getTeamLoading(false)),
          put(actions.getTeamSuccess(false)),
          put(actions.getTeamFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getTeamLoading(false)),
          put(actions.getTeamSuccess(false)),
          put(actions.getTeamFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Team",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getTeamLoading(false)),
        put(actions.getTeamSuccess(false)),
        put(actions.getTeamFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Get Team",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getTeamLoading(false)),
      put(actions.getTeamSuccess(false)),
      put(actions.getTeamFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Get Team",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateTeamSaga(action) {
  let { id, teamData, accessToken } = action.payload;
  yield all([
    put(actions.updateTeamLoading(true)),
    put(actions.updateTeamSuccess(false)),
    put(actions.updateTeamFailed(false)),
  ]);
  try {
    const formattedData = {};
    teamData.members.forEach((member, index) => {
      Object.keys(member).forEach((key) => {
        if (Array.isArray(member[key])) {
          member[key].forEach((value, i) => {
            formattedData[`members[${index}][${key}][${i}]`] = value;
          });
        } else {
          formattedData[`members[${index}][${key}]`] = member[key];
        }
      });
    });

    formattedData.updated_by = teamData.updated_by;
    formattedData.is_active = teamData.is_active;
    formattedData.pm_id = teamData.pm_id;

    const response = yield call(
      putApiToken,
      `${apiUrl("UPDATE_TEAM_URL")}/${id}`,
      queryString.stringify(formattedData),
      accessToken
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxTeamsPerPage,
        teamsSortField,
        teamsSortOrder,
      } = yield select((state) => state.manageTeams);
      yield all([
        put(
          actions.getTeamsList(
            teamsSortField,
            teamsSortOrder,
            currentPage,
            maxTeamsPerPage,
            accessToken
          )
        ),
        put(actions.updateTeamSuccess(true)),
        put(actions.updateTeamLoading(false)),
        put(actions.updateTeamFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Update Team",
          "Team updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.updateTeamLoading(false)),
          put(actions.updateTeamSuccess(false)),
          put(actions.updateTeamFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Team",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.updateTeamLoading(false)),
        put(actions.updateTeamSuccess(false)),
        put(actions.updateTeamFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Update Team",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateTeamLoading(false)),
      put(actions.updateTeamSuccess(false)),
      put(actions.updateTeamFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Update Team",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
