const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Accept: "application/json",
};

const headersWithToken = (accessToken) => ({
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Authorization: `Bearer ${accessToken}`,
  Accept: "application/json",
});

export const postApi = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: body,
  };

  return fetch(url, requestOptions);
};

export const postApiToken = (url, accessToken, body) => {
  const requestOptions = {
    method: "POST",
    headers: headersWithToken(accessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const getApi = (url, params) => {
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}?${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const getApiToken = (url, params, accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: headersWithToken(accessToken),
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}?${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const getApiTokenSlash = (url, params, accessToken) => {
  const requestOptions = {
    method: "GET",
    headers: headersWithToken(accessToken),
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}/${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const putApiToken = (url, body, accessToken) => {
  const requestOptions = {
    method: "PUT",
    headers: headersWithToken(accessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const deleteApiToken = (url, accessToken, body) => {
  const requestOptions = {
    method: "DELETE",
    headers: headersWithToken(accessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};
