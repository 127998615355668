import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_LIST_LOADING: "SET_LIST_LOADING",
  GET_USERS_LIST: "GET_USERS_LIST",
  SET_USERS_LIST: "SET_USERS_LIST",
  CHANGE_CURRENT_PAGE: "CHANGE_CURRENT_PAGE",
  CLEAR_LIST: "CLEAR_LIST",
  GET_ROLES_DROPDOWN: "GET_ROLES_DROPDOWN",
  SET_ROLES_DROPDOWN: "SET_ROLES_DROPDOWN",
  SET_ROLES_DROPDOWN_LOADING: "SET_ROLES_DROPDOWN_LOADING",
  SET_ROLES_DROPDOWN_FAILED: "SET_ROLES_DROPDOWN_FAILED",
  CHANGE_USER_DATA: "CHANGE_USER_DATA",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  SAVE_USER_DATA: "SAVE_USER_DATA",
  SAVE_USER_DATA_LOADING: "SAVE_USER_DATA_LOADING",
  SAVE_USER_DATA_FAILED: "SAVE_USER_DATA_FAILED",
  SAVE_USER_DATA_SUCCESS: "SAVE_USER_DATA_SUCCESS",
  CLOSE_USER_DIALOG: "CLOSE_USER_DIALOG",
  GET_USER: "GET_USER",
  GET_USER_LOADING: "GET_USER_LOADING",
  GET_USER_FAILED: "GET_USER_FAILED",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_LOADING: "UPDATE_USER_LOADING",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  CHANGE_SORT: "CHANGE_SORT",
  CHANGE_USERS_FILTER_DATA: "CHANGE_USERS_FILTER_DATA",
  CLEAR_USERS_FILTER_DATA: "CLEAR_USERS_FILTER_DATA",
  CLEAR_ALL: "CLEAR_ALL",
  SET_FILTER: "SET_FILTER_USER",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
};

const initialUsersState = {
  usersList: undefined,
  totalUsersRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxUsersPerPage: 10,
  usersSortField: "id",
  usersSortOrder: "desc",
  usersFilterData: {
    name: "",
    username: "",
    email: "",
    status: null,
  },
  userData: {
    name: "",
    username: "",
    password: "",
    email: "",
    status: "1",
    role: undefined,
  },
  roles: undefined,
  rolesDropdownLoading: false,
  rolesDropdownFailed: false,
  saveUserDataLoading: false,
  saveUserDataSuccess: false,
  saveUserDataFailed: false,
  updateUserSuccess: false,
  updateUserLoading: false,
  updateUserFailed: false,
  isfilter: false,
};

export const reducer = (state = initialUsersState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }
    case actionTypes.CLEAR_LIST: {
      return {
        ...state,
        totalUsersRecords: undefined,
        usersList: undefined,
      };
    }

    case actionTypes.SET_USERS_LIST: {
      const { usersData } = action.payload;
      const totalUsersRecords =
        state.isfilter && usersData.recordsTotal
          ? usersData.recordsFiltered
          : usersData.recordsTotal;

      const usersList = usersData.data ? usersData.data : undefined;
      return {
        ...state,
        totalUsersRecords,
        usersList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        usersSortField: field,
        usersSortOrder: order,
      };
    }

    case actionTypes.CHANGE_USERS_FILTER_DATA: {
      const { usersFilterData } = action.payload;
      return {
        ...state,
        usersFilterData: usersFilterData,
      };
    }

    case actionTypes.SET_ROLES_DROPDOWN: {
      const { data } = action.payload;
      let rolesData = [];
      for (var key of Object.keys(data)) {
        let role = {
          id: key,
          name: data[key],
        };
        rolesData.push(role);
      }
      return {
        ...state,
        roles: rolesData && rolesData.length !== 0 ? rolesData : undefined,
      };
    }

    case actionTypes.SET_ROLES_DROPDOWN_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        rolesDropdownLoading: flag,
      };
    }

    case actionTypes.SET_ROLES_DROPDOWN_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        rolesDropdownFailed: flag,
      };
    }

    case actionTypes.CHANGE_USER_DATA: {
      const { userData } = action.payload;
      if (!userData.role && userData.roles) {
        userData.role =
          userData.roles && userData.roles[0] && userData.roles[0].id
            ? userData.roles[0].id
            : undefined;
      }
      return {
        ...state,
        userData: {
          name: userData.name ? userData.name : "",
          username: userData.username ? userData.username : "",
          password: userData.password ? userData.password : "",
          email: userData.email ? userData.email : "",
          status:
            userData.status !== null && userData.status !== undefined
              ? userData.status
              : "1",
          role: userData.role ? userData.role : undefined,
        },
      };
    }

    case actionTypes.CLEAR_USER_DATA: {
      return {
        ...state,
        userData: {
          name: "",
          username: "",
          password: "",
          email: "",
          status: "1",
          role: undefined,
        },
      };
    }

    case actionTypes.SAVE_USER_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataLoading: flag,
      };
    }

    case actionTypes.SAVE_USER_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataFailed: flag,
      };
    }

    case actionTypes.SAVE_USER_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveUserDataSuccess: flag,
      };
    }

    case actionTypes.CLOSE_USER_DIALOG: {
      return {
        ...state,
        userData: {
          name: "",
          username: "",
          password: "",
          email: "",
          status: "1",
          role: undefined,
        },
        roles: undefined,
        rolesDropdownLoading: false,
        rolesDropdownFailed: false,
        saveUserDataLoading: false,
        saveUserDataSuccess: false,
        saveUserDataFailed: false,
        updateUserFailed: false,
        updateUserLoading: false,
        updateUserSuccess: false,
      };
    }

    case actionTypes.CHANGE_PASSWORD: {
      const { password } = action.payload;
      return {
        ...state,
        userData: {
          ...state.userData,
          password: password,
        },
      };
    }

    case actionTypes.UPDATE_USER_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserSuccess: flag,
      };
    }

    case actionTypes.UPDATE_USER_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserFailed: flag,
      };
    }

    case actionTypes.UPDATE_USER_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateUserLoading: flag,
      };
    }

    case actionTypes.CLEAR_ALL: {
      return {
        usersList: undefined,
        totalUsersRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxUsersPerPage: 10,
        usersSortField: "id",
        usersSortOrder: "desc",
        usersFilterData: {
          name: "",
          username: "",
          email: "",
          status: null,
        },
        userData: {
          name: "",
          username: "",
          password: "",
          email: "",
          status: "1",
          role: undefined,
        },
        roles: undefined,
        rolesDropdownLoading: false,
        rolesDropdownFailed: false,
        saveUserDataLoading: false,
        saveUserDataSuccess: false,
        saveUserDataFailed: false,
        updateUserSuccess: false,
        updateUserLoading: false,
        updateUserFailed: false,
        isfilter: false,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_LIST_LOADING,
    payload: { flag },
  }),
  getUsersList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_USERS_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setUsersList: (usersData) => ({
    type: actionTypes.SET_USERS_LIST,
    payload: { usersData },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT,
    payload: { field, order, accessToken },
  }),
  changeUsersFilterData: (usersFilterData) => ({
    type: actionTypes.CHANGE_USERS_FILTER_DATA,
    payload: { usersFilterData },
  }),
  clearUsersFilterData: (accessToken) => ({
    type: actionTypes.CLEAR_USERS_FILTER_DATA,
    payload: { accessToken },
  }),
  getRolesDropdown: (accessToken) => ({
    type: actionTypes.GET_ROLES_DROPDOWN,
    payload: { accessToken },
  }),
  setRolesDropdown: (data) => ({
    type: actionTypes.SET_ROLES_DROPDOWN,
    payload: { data },
  }),
  setRolesDropdownLoading: (flag) => ({
    type: actionTypes.SET_ROLES_DROPDOWN_LOADING,
    payload: { flag },
  }),
  setRolesDropdownFailed: (flag) => ({
    type: actionTypes.SET_ROLES_DROPDOWN_FAILED,
    payload: { flag },
  }),
  changeUserData: (userData) => ({
    type: actionTypes.CHANGE_USER_DATA,
    payload: { userData },
  }),
  clearUserData: () => ({ type: actionTypes.CLEAR_USER_DATA }),
  saveUser: (userData, accessToken) => ({
    type: actionTypes.SAVE_USER_DATA,
    payload: { userData, accessToken },
  }),
  saveUserDataLoading: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_LOADING,
    payload: { flag },
  }),
  saveUserDataFailed: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_FAILED,
    payload: { flag },
  }),
  saveUserDataSuccess: (flag) => ({
    type: actionTypes.SAVE_USER_DATA_SUCCESS,
    payload: { flag },
  }),
  closeUserDialog: () => ({ type: actionTypes.CLOSE_USER_DIALOG }),
  getUser: (id, accessToken) => ({
    type: actionTypes.GET_USER,
    payload: { id, accessToken },
  }),
  getUserLoading: (flag) => ({
    type: actionTypes.GET_USER_LOADING,
    payload: { flag },
  }),
  getUserSuccess: (flag) => ({
    type: actionTypes.GET_USER_SUCCESS,
    payload: { flag },
  }),
  getUserFailed: (flag) => ({
    type: actionTypes.GET_USER_FAILED,
    payload: { flag },
  }),
  changePassword: (password) => ({
    type: actionTypes.CHANGE_PASSWORD,
    payload: { password },
  }),
  updateUser: (id, userData, accessToken) => ({
    type: actionTypes.UPDATE_USER,
    payload: { id, userData, accessToken },
  }),
  updateUserSuccess: (flag) => ({
    type: actionTypes.UPDATE_USER_SUCCESS,
    payload: { flag },
  }),
  updateUserLoading: (flag) => ({
    type: actionTypes.UPDATE_USER_LOADING,
    payload: { flag },
  }),
  updateUserFailed: (flag) => ({
    type: actionTypes.UPDATE_USER_FAILED,
    payload: { flag },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL }),
};

export function* saga() {
  yield takeLatest(actionTypes.CHANGE_CURRENT_PAGE, function* changePageSaga(
    action
  ) {
    const { usersSortField, usersSortOrder } = yield select(
      (state) => state.manageUsers
    );
    const { page, length, accessToken } = action.payload;
    yield put(
      actions.getUsersList(
        usersSortField,
        usersSortOrder,
        page,
        length,
        accessToken
      )
    );
  });

  yield takeLatest(actionTypes.CHANGE_SORT, function* changeSortSaga(action) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxUsersPerPage } = yield select(
      (state) => state.manageUsers
    );
    yield put(
      actions.getUsersList(
        field,
        order,
        currentPage,
        maxUsersPerPage,
        accessToken
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_USERS_FILTER_DATA,
    function* clearUsersFilterDataSaga(action) {
      let { accessToken } = action.payload;
      let {
        usersSortField,
        usersSortOrder,
        currentPage,
        maxUsersPerPage,
      } = yield select((state) => state.manageUsers);
      let usersFilterData = {
        name: "",
        username: "",
        email: "",
        status: null,
      };
      yield put(actions.setFilter(false));
      yield put(actions.changeUsersFilterData(usersFilterData));
      yield put(
        actions.getUsersList(
          usersSortField,
          usersSortOrder,
          currentPage,
          maxUsersPerPage,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.GET_USERS_LIST, getUsersListSaga);

  yield takeLatest(actionTypes.GET_ROLES_DROPDOWN, getRolesListSaga);

  yield takeLatest(actionTypes.SAVE_USER_DATA, saveUserSaga);

  yield takeLatest(actionTypes.GET_USER, getUserSaga);

  yield takeLatest(actionTypes.UPDATE_USER, updateUserSaga);
}

function* getUsersListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setListLoading(true));
  const { usersFilterData } = yield select((state) => state.manageUsers);
  const filterParams = {
    ...usersFilterData,
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("USER_ADMIN_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setUsersList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Users List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Users List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Users List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getRolesListSaga(action) {
  let { accessToken } = action.payload;
  yield all([
    put(actions.setRolesDropdownLoading(true)),
    put(actions.setRolesDropdownFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("ROLES_DROPDOWN_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setRolesDropdown(responseData.data)),
          put(actions.setRolesDropdownLoading(false)),
          put(actions.setRolesDropdownFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setRolesDropdownLoading(false)),
          put(actions.setRolesDropdownFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.setRolesDropdownLoading(false)),
          put(actions.setRolesDropdownFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Roles Dropdown",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.setRolesDropdownLoading(false)),
        put(actions.setRolesDropdownFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Roles Dropdown",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setRolesDropdownLoading(false)),
      put(actions.setRolesDropdownFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Roles Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveUserSaga(action) {
  let { userData, accessToken } = action.payload;
  yield all([
    put(actions.saveUserDataLoading(true)),
    put(actions.saveUserDataSuccess(false)),
    put(actions.saveUserDataFailed(false)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("USER_ADMIN_URL"),
      accessToken,
      queryString.stringify(userData)
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxUsersPerPage,
        usersSortField,
        usersSortOrder,
      } = yield select((state) => state.manageUsers);
      yield all([
        put(
          actions.getUsersList(
            usersSortField,
            usersSortOrder,
            currentPage,
            maxUsersPerPage,
            accessToken
          )
        ),
        put(actions.saveUserDataLoading(false)),
        put(actions.saveUserDataSuccess(true)),
        put(actions.saveUserDataFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Save User",
          "User saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveUserDataLoading(false)),
          put(actions.saveUserDataSuccess(false)),
          put(actions.saveUserDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save User",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else if (response.status === 422) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 422) {
        yield all([
          put(actions.saveUserDataLoading(false)),
          put(actions.saveUserDataSuccess(false)),
          put(actions.saveUserDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Save User",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.saveUserDataLoading(false)),
        put(actions.saveUserDataSuccess(false)),
        put(actions.saveUserDataFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Save User",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveUserDataLoading(false)),
      put(actions.saveUserDataSuccess(false)),
      put(actions.saveUserDataFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Save User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getUserSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getUserLoading(true)),
    put(actions.getUserSuccess(false)),
    put(actions.getUserFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("USER_ADMIN_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.changeUserData(responseData.data)),
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(true)),
          put(actions.getUserFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(false)),
          put(actions.getUserFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getUserLoading(false)),
          put(actions.getUserSuccess(false)),
          put(actions.getUserFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Get User",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.getUserLoading(false)),
        put(actions.getUserSuccess(false)),
        put(actions.getUserFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Get User",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getUserLoading(false)),
      put(actions.getUserSuccess(false)),
      put(actions.getUserFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Get User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateUserSaga(action) {
  let { id, userData, accessToken } = action.payload;
  let body = { ...userData };
  if (!body.password || body.password === "") {
    delete body.password;
  }

  yield all([
    put(actions.updateUserLoading(true)),
    put(actions.updateUserSuccess(false)),
    put(actions.updateUserFailed(false)),
  ]);
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("USER_ADMIN_URL")}/${id}`,
      queryString.stringify(body),
      accessToken
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxUsersPerPage,
        usersSortField,
        usersSortOrder,
      } = yield select((state) => state.manageUsers);
      yield all([
        put(
          actions.getUsersList(
            usersSortField,
            usersSortOrder,
            currentPage,
            maxUsersPerPage,
            accessToken
          )
        ),
        put(actions.updateUserSuccess(true)),
        put(actions.updateUserLoading(false)),
        put(actions.updateUserFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Update User",
          "User updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.updateUserLoading(false)),
          put(actions.updateUserSuccess(false)),
          put(actions.updateUserFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Update User",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.updateUserLoading(false)),
        put(actions.updateUserSuccess(false)),
        put(actions.updateUserFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Update User",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateUserLoading(false)),
      put(actions.updateUserSuccess(false)),
      put(actions.updateUserFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Update User",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
