/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo } from "react";
import FeedbackSmileyReport from "./FeedbackSmileyReport";
import { useSelector } from "react-redux";

const FeedbackReportContainer = () => {
  const { feedbackReport } = useSelector(
    (state) => state.feedbackSettings
  );
  const {report_type} = feedbackReport;

  return (
    <div className="report-container">
        {report_type === "chart" && <FeedbackSmileyReport feedbackReport={feedbackReport}/>}
    </div>
  );
};

export default memo(FeedbackReportContainer);
