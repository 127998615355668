import React from "react";
import { Modal } from "react-bootstrap";

import UserDialogHeader from "./UserDialogHeader";
import { ClientForm } from "./ClientForm";

export default function UserDialog({ id, onHide, show, edit, saveUser }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <UserDialogHeader userEdit={edit} id={id} />
      <ClientForm
        userID={id}
        actionsLoading={false}
        onHide={onHide}
        edit={edit}
      />
    </Modal>
  );
}
