import React from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as manageDesignersActions } from "../redux/manageDesignersRedux";
import { useCookies } from "react-cookie";

export default function DesignersFilterForm({ onHide, applyFilter }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const { designersFilterData } = useSelector((state) => state.manageDesigners);

  const handleFormChange = (e) => {
    let data = designersFilterData;
    data[e.target.name] = e.target.value;
    dispatch(manageDesignersActions.changeDesignersFilterData(data));
  };

  const handleClearFilter = () => {
    dispatch(manageDesignersActions.setCurrentPage(0));
    dispatch(
      manageDesignersActions.clearDesignersFilterData(cookies.accessToken)
    );
    onHide();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={designersFilterData}
        handleChange={(e) => handleFormChange(e)}
        onSubmit={() => {
          applyFilter();
          onHide();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <div style={{ padding: "20px 10px 0px 10px" }}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-12">
                    <Field
                      value={designersFilterData.name}
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      value={designersFilterData.email}
                      onChange={(e) => handleFormChange(e)}
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  {/* Status */}
                  <div className="col-lg-12">
                    <Select
                      onChange={(e) => handleFormChange(e)}
                      value={designersFilterData.status}
                      name="status"
                      label="Status"
                    >
                      <option value={""}>All</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </Select>
                  </div>
                </div>
              </Form>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                flexDirection: "row",
                paddingRight: "10px",
              }}
            >
              <button
                style={{ marginRight: "10px" }}
                type="button"
                onClick={() => handleClearFilter()}
                className="btn btn-light btn-elevate"
              >
                Clear Filter
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Apply
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
