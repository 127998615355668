import React from 'react';
import PropTypes from 'prop-types';
import './StarRating.scss';

const StarRating = ({ averageRating, maxStars = 5 }) => {
  const fullStars = Math.floor(averageRating); // Integer part for full stars
  const hasHalfStar = averageRating % 1 > 0; // Check if there is a decimal part
  const emptyStars = maxStars - fullStars - (hasHalfStar ? 1 : 0);

  const renderStar = (type, index) => {
    switch (type) {
      case 'full':
        return <span key={index} className="star full">★</span>;
      case 'half':
        return <span key={index} className="star half">★</span>;
      case 'empty':
        return <span key={index} className="star empty">☆</span>;
      default:
        return null;
    }
  };

  return (
    <div className={`star-rating ${Number(averageRating)>=3?'success':'danger'}`}>
      {Array(fullStars)
        .fill(null)
        .map((_, i) => renderStar('full', i))}
      {hasHalfStar && renderStar('half', fullStars)}
      {Array(emptyStars)
        .fill(null)
        .map((_, i) => renderStar('empty', fullStars + hasHalfStar + i))}
    </div>
  );
};

StarRating.propTypes = {
  averageRating: PropTypes.number.isRequired,
  maxStars: PropTypes.number,
};

export default StarRating;
