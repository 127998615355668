/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { CustomPhoneInput } from "../../../components/CustomPhoneInput";
import ClientFormMaxRevisionInput from './ClientFormMaxRevisionInput';
import "react-phone-input-2/lib/style.css";
// Validation schema
const ClientAddSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company_name: Yup.string().required("Company Name is required"),
    email: Yup.string().required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .matches(new RegExp("[0-9]{7}"), "Please enter valid Phone."),
    max_revision_request: Yup.number()
      .nullable(true)
      .max(99, "Max Revisions must be less than or equal to 99"),
  });
};
export function ClientForm({ userID, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const {
    clientData,
    saveClientDataLoading,
    saveClientDataSuccess,
    clientsSortField,
    clientsSortOrder,
    currentPage,
    maxClientsPerPage,
  } = useSelector((state) => state.manageClients);
  const [formLoading, setFormLoading] = useState(true);

  const handleFormChange = (e) => {
    let data = clientData;
    if(data['client_type'] == 'enterprise'){
      data['is_allow_free_sample_design'] = 0;
      data['max_free_sample_design'] = 3;
      data['free_sample_designs'] = "";
    }
    data[e.target.name] = e.target.type == "checkbox"?e.target.checked:e.target.value;
    dispatch(manageClientsActions.changeClientData(data));
  };

  const changePhone = (v, c, e, f) => {
    let data = clientData;
    data["phone"] = v;
    data["phone_code"] = c["dialCode"];
    dispatch(manageClientsActions.changeClientData(data));
  };

  useEffect(() => {
    if (saveClientDataLoading) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [saveClientDataLoading]);

  useEffect(() => {
    if (saveClientDataSuccess) {
      onHide();
      dispatch(
        manageClientsActions.getClientsList(
          clientsSortField,
          clientsSortOrder,
          currentPage,
          maxClientsPerPage,
          cookies.accessToken
        )
      );
    }
  }, [saveClientDataSuccess]);

  const saveClient = () => {
    dispatch(manageClientsActions.saveClientDataLoading(true));
    dispatch(manageClientsActions.saveClient(clientData, cookies.accessToken));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={clientData}
        validationSchema={ClientAddSchema()}
        handleChange={(e) => handleFormChange(e)}
        onSubmit={() => {
          saveClient();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {formLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-4">
                    <Field
                      value={clientData.name}
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      onChange={(e) => handleFormChange(e)}
                      disabled={formLoading}
                    />
                  </div>
                  {/* Login */}
                  <div className="col-lg-4">
                    <Field
                      value={clientData.company_name}
                      onChange={(e) => handleFormChange(e)}
                      name="company_name"
                      component={Input}
                      placeholder="Company Name"
                      label="Company Name"
                      disabled={formLoading}
                    />
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      value={clientData.email}
                      onChange={(e) => handleFormChange(e)}
                      type="email"
                      name="email"
                      component={Input}
                      placeholder="Email"
                      label="Email"
                      disabled={formLoading}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      value={clientData.phone}
                      country={clientData.phone_code}
                      onChange={(v, c, e, f) => changePhone(v, c, e, f)}
                      name="phone"
                      component={CustomPhoneInput}
                      label="Phone"
                      disabled={formLoading}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    {/* Max Revision Count */}
                    <ClientFormMaxRevisionInput
                      Value={clientData.max_revision_request}
                      handleFormChange={handleFormChange}
                      formLoading={formLoading}
                    />
                  </div>
                  <div className="col-lg-4">
                  <label>Enter Max Request Prioritization</label>
                    <Field
                        type="number"
                        min={0}
                        value={clientData.max_request_prioritisation}
                        onChange={(e) => handleFormChange(e)}
                        name="max_request_prioritisation"
                        component={Input}
                        placeholder="Max request prioritization"
                        disabled={formLoading}
                      />
                      <div class="feedback">Please enter <b>Max Request Prioritization</b></div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4"> 
                    <Select
                      id="client_type"
                      onChange={(e) => handleFormChange(e)}
                      value={clientData.client_type}
                      name="client_type"
                      label="Client Type"
                      disabled={formLoading}
                    >
                      <option value="standard">Standard</option>
                      <option value="enterprise">Enterprise</option>
                    </Select>
                  </div>
                  {clientData.client_type == "standard"&&<div className="col-lg-4"> 
                    <Field style={{marginTop: "5px",marginBottom: "8px"}} label={"Allow Sample Designs"} type="checkbox" name="is_allow_free_sample_design" onChange={(e) => handleFormChange(e)} 
                           checked={clientData.is_allow_free_sample_design == 1}/>
                      <span style={{marginLeft: "5px", position: "relative", bottom: "3px"}}>Allow Sample Designs</span>
                      {clientData.is_allow_free_sample_design == 1 ? <><Field
                        type="number"
                        min={0}
                        value={clientData.max_free_sample_design}
                        onChange={(e) => handleFormChange(e)}
                        name="max_free_sample_design"
                        component={Input}
                        placeholder="Max free sample design"
                        disabled={formLoading}
                      />
                      <div class="feedback">Please enter <b>Sample Designs</b></div></>
                      :null}
                      
                  </div>}
                </div>
                <div className="form-group row">
                <div className="col-lg-4"> 
                <Field style={{marginTop: "5px",marginBottom: "8px"}} 
                        label={"Enable sequencing in All tab"} 
                        type="checkbox" 
                        name="is_all_tab_prioritisation_enabled" onChange={(e) => handleFormChange(e)} 
                           checked={clientData.is_all_tab_prioritisation_enabled == 1}/>
                      <span style={{marginLeft: "5px", position: "relative", bottom: "3px"}}>Enable sequencing in All tab</span>
                      </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={!formLoading ? onHide : undefined}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => !formLoading && handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
