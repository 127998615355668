/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import Svg from "react-inlinesvg";
import PropTypes from "prop-types";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* System */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text" style={{ fontSize: "1.23rem" }}>
            SYSTEM
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/config-settings",
            true
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/config-settings">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Configurations Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-parent menu-item-open"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Configurations Settings</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/config-settings/manage-configs",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/config-settings/manage-configs"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Intake Form</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/config-settings/email-notification",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/config-settings/email-notification"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Email Notification</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/config-settings/feedback-form",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/config-settings/feedback-form"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Feedback Form</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/feedback-settings",
            false
          )}`}
        >
          <NavLink className="menu-link" to="/feedback-settings">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Feedback Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-open  menu-item-parent">
                <span className="menu-link">
                  <span className="menu-text">Feedback Settings</span>
                </span>
              </li>
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/feedback-settings/feedback-configs",
                  true
                )}`}
              >
                <NavLink className="menu-link" to="/feedback-settings/feedback-configs">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Feedback</span>
                </NavLink>
                <NavLink className="menu-link" to="/feedback-settings/feedback-report">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Feedback Report</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/user-settings",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-settings">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Users Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item menu-item-open  menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Users Settings</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/user-settings/manage-users",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/user-settings/manage-users">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Manage Users</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/user-settings/manage-roles",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/user-settings/manage-roles">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Manage Roles</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text" style={{ fontSize: "1.2rem" }}>
            CLIENTS
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/client-settings",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client-settings">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Clients Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-open menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Clients Settings</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/client-settings/manage-clients",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/client-settings/manage-clients"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">All Clients</span>
                </NavLink>
                <NavLink
                  className="menu-link"
                  to="/client-settings/announcement"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Announcement</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="menu-section ">
          <h4 className="menu-text" style={{ fontSize: "1.2rem" }}>
            DESIGNERS
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/designer-settings",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/designer-settings">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Designers Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-open menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Designers Settings</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/designer-settings/manage-designers",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/designer-settings/manage-designers"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">All Designers</span>
                </NavLink>
              </li>
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/designer-settings/manage-teams",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link"
                  to="/designer-settings/manage-teams"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">All Teams</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        {/* <li className="menu-section ">
          <h4 className="menu-text" style={{ fontSize: "1.2rem" }}>
            REPORTS
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        <li
          className={`menu-item menu-item-open ${getMenuItemActive(
            "/reports",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/reports">
            <span className="svg-icon menu-icon">
              <Svg src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">Reports</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li
                className="menu-item  menu-item-open menu-item-parent"
                aria-haspopup="true"
              >
                <span className="menu-link">
                  <span className="menu-text">Reports</span>
                </span>
              </li>

              {/* Inputs */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-open menu-item-submenu ${getMenuItemActive(
                  "/reports/search",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link" to="/reports/search">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Search</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

AsideMenuList.propTypes = {
  layoutProps: PropTypes.object.isRequired,
};
