/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-restricted-imports
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import StatusColumnFormatter from "../components/StatusColumnFormatter";
import TeamDialog from "../components/TeamDialog";
import { actions as manageTeamActions } from "../redux/manageTeamRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import TeamFilterForm from "../components/TeamFilterForm";
import { RenderDesigners, RenderTeamLead } from "../components/RenderColumns";

export default function ManageTeams() {
  const dispatch = useDispatch();
  const {
    listLoading,
    teamsList,
    totalTeamsRecords,
    currentPage,
    maxTeamsPerPage,
    teamsSortField,
    teamsSortOrder,
  } = useSelector((state) => state.manageTeams);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(
      manageTeamActions.getTeamsList(
        teamsSortField,
        teamsSortOrder,
        currentPage,
        maxTeamsPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalTeamsRecords ? totalTeamsRecords : 0,
    sizePerPage: maxTeamsPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          manageTeamActions.changeCurrentPage(
            page - 1,
            maxTeamsPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== teamsSortField || sortOrder !== teamsSortOrder) {
        dispatch(
          manageTeamActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const applyFilter = () => {
    dispatch(manageTeamActions.setFilter(true));
    dispatch(manageTeamActions.setCurrentPage(0));
    dispatch(
      manageTeamActions.getTeamsList(
        teamsSortField,
        teamsSortOrder,
        0,
        maxTeamsPerPage,
        cookies.accessToken
      )
    );
  };

  const onHideTeamDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageTeamActions.closeTeamDialog());
  };

  const openEditTeamDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "pm_id_text",
      text: "PM",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "pm_position_id_text",
      text: "Position",
      // sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "tls",
      text: "Team Lead(s)",
      // sort: true,
      sortCaret: sortCaret,
      formatter: RenderTeamLead,
      headerSortingClasses,
    },
    {
      dataField: "designers",
      text: "Designers",
      // sort: true,
      sortCaret: sortCaret,
      formatter: RenderDesigners,
      headerSortingClasses,
    },
    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditTeamDialog: (id) => openEditTeamDialog(true, id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Teams list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <TeamFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-black-primary"
            onClick={() => openEditTeamDialog(false, null)}
          >
            New Team
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Table
          listLoading={listLoading}
          entities={teamsList ? teamsList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalTeamsRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{
            dataField: teamsSortField,
            order: teamsSortOrder,
          }}
        />
      </CardBody>
      <TeamDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideTeamDialog()}
      />
    </Card>
  );
}
