/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";

import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { actions as emailNotificationActions } from "../redux/emailNotificationRedux";
const EmailNotificationSchema = () =>
  Yup.object().shape({
    portaladminname: Yup.string().required("Name is required"),
    portaladminemail: Yup.string().required("Email is required"),
    supportname: Yup.string().required("Name is required"),
    supportemail: Yup.string().required("Email is required"),
  });
export default function EmailNotification(props) {
  const {
    getEmailNotificationDataLoading,
    emailNotificationData,
    emailNotificationDatai,
  } = useSelector((state) => state.emailNotification);

  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(
      emailNotificationActions.getEmailNotificationData(cookies.accessToken)
    );
  }, []);

  const saveEmailNotificationData = () => {
    dispatch(
      emailNotificationActions.updateEmailNotificationData(
        emailNotificationDatai,
        emailNotificationData,
        cookies.accessToken
      )
    );
  };
  const handleFormChange = (e) => {
    let data = emailNotificationDatai;
    data[e.target.name] = e.target.value;
    dispatch(emailNotificationActions.setEmailNotificationDatai(data));
  };
  return (
    <Card>
      <CardHeader title={"Email Notification"}></CardHeader>
      <CardBody>
        <Formik
          enableReinitialize={true}
          validationSchema={EmailNotificationSchema}
          initialValues={emailNotificationDatai}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveEmailNotificationData();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {getEmailNotificationDataLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <h3>Portal Admin</h3>
                  <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <Field
                        type="text"
                        value={emailNotificationDatai.portaladminname}
                        name="portaladminname"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={getEmailNotificationDataLoading}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field
                        type="email"
                        value={emailNotificationDatai.portaladminemail}
                        name="portaladminemail"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                        onChange={(e) => handleFormChange(e)}
                        disabled={getEmailNotificationDataLoading}
                      />
                    </div>
                  </div>
                  <h3>Support</h3>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field
                        type="text"
                        value={emailNotificationDatai.supportname}
                        name="supportname"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={getEmailNotificationDataLoading}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field
                        type="email"
                        value={emailNotificationDatai.supportemail}
                        name="supportemail"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                        onChange={(e) => handleFormChange(e)}
                        disabled={getEmailNotificationDataLoading}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <> </>
                <button
                  type="submit"
                  onClick={() =>
                    !getEmailNotificationDataLoading && handleSubmit()
                  }
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
}
