/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, useFormikContext, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { Select } from "../../../../_metronic/_partials/controls";
import { actions as manageTeamActions } from "../redux/manageTeamRedux";
import TeamFormLeaderRow from "./TeamFormLeaderRow";

// Validation schema
const TeamEditSchema = () =>
  Yup.object().shape({
    pm_id: Yup.string().required("Project Manager is required"),
    is_active: Yup.string().required("Status is required"),
    members: Yup.array().of(
      Yup.object().shape({
        tl_id: Yup.string(),
        designer_ids: Yup.array().required("Designer selection is required"),
      })
    ),
  });

const ValueChangeListener = ({ updateFormValues }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    updateFormValues(values);
  }, [values]);

  return null;
};

export function TeamForm({ id, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const auth = useSelector((state) => state.auth);
  const {
    teamFormData = {},
    teamFormDropdownLoading,
    teamFormDropdownFailed,
    saveTeamDataLoading,
    saveTeamDataFailed,
    saveTeamDataSuccess,
    updateTeamLoading,
    updateTeamFailed,
    updateTeamSuccess,
  } = useSelector((state) => state.manageTeams);
  const {
    pms = [],
    tls = [],
    designers = [],
    team: teamData = {},
  } = teamFormData;

  const [formLoading, setFormLoading] = useState(true);
  const [currentFormValues, updateFormValues] = useState({});
  const initialMemberValues = {
    tl_id: "",
    designer_ids: [],
  };

  const [initialValues, setInitialValues] = useState({
    pm_id: "",
    is_active: "",
    members: [initialMemberValues],
  });

  useEffect(() => {
    if (Object.keys(teamData).length) {
      const { pm_id, tls = [], is_active } = teamData;
      const members = [];
      for (let tl of tls) {
        members.push({
          tl_id: Number(tl.tl_id) > 0 ? tl.tl_id : "",
          designer_ids: tl.designers?.map((designer) => ({
            label: `${designer.designer_id_text}-(${designer.designer_position_id_text})-(${designer.designer_is_active_text})`,
            value: designer.designer_id,
          })),
        });
      }
      const editInitialValues = {
        ...initialValues,
        pm_id: pm_id,
        is_active,
      };
      if (members.length) {
        editInitialValues.members = members;
      }
      setInitialValues(editInitialValues);
    }
  }, [teamData]);

  const handleFormChange = (e) => {
    let data = teamData;
    data[e.target.name] = e.target.value;
    dispatch(manageTeamActions.changeTeamData(data));
  };

  useEffect(() => {
    if (teamFormDropdownLoading || saveTeamDataLoading || updateTeamLoading) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [teamFormDropdownLoading, saveTeamDataLoading, updateTeamLoading]);

  useEffect(() => {
    if (!saveTeamDataLoading && !saveTeamDataFailed && saveTeamDataSuccess) {
      onHide();
    }
  }, [saveTeamDataLoading, saveTeamDataFailed, saveTeamDataSuccess]);

  useEffect(() => {
    if (!updateTeamLoading && !updateTeamFailed && updateTeamSuccess) {
      onHide();
    }
  }, [updateTeamLoading, updateTeamFailed, updateTeamSuccess]);

  useEffect(() => {
    if (id && edit) {
      dispatch(
        manageTeamActions.getEditTeamFormDropdownsData(id, cookies.accessToken)
      );
    } else {
      dispatch(manageTeamActions.getTeamFormDropdownsData(cookies.accessToken));
    }
  }, [id, edit, cookies.accessToken, dispatch]);

  const saveTeam = (values) => {
    if (id && edit) {
      const teamData = {
        ...values,
        updated_by: auth.userID,
      };
      dispatch(manageTeamActions.updateTeam(id, teamData, cookies.accessToken));
    } else {
      const teamData = {
        ...values,
        created_by: auth.userID,
      };
      dispatch(manageTeamActions.saveTeam(teamData, cookies.accessToken));
    }
  };

  const handleAddRemoveRow = (action, index, arrayHelpers) => {
    if (action === "add") {
      arrayHelpers.push(initialMemberValues);
    } else {
      arrayHelpers.remove(index);
    }
  };

  const getFilteredTLs = useCallback(
    (index) => {
      const { members = [] } = currentFormValues;
      const selectedTeamLeaders = members.map((row) => row.tl_id?.toString());
      return tls.filter(
        (tl) =>
          !selectedTeamLeaders.includes(tl.id?.toString()) ||
          tl.id === Number(members[index]?.tl_id)
      );
    },
    [tls, currentFormValues]
  );

  const getFilteredDesigners = useCallback(
    (index) => {
      const { members = [] } = currentFormValues;
      const selectedDesigners = members.flatMap((row) => row?.designer_ids);
      return designers.filter(
        (d) =>
          !selectedDesigners.includes(d.id) ||
          members[index]?.designer_ids?.includes(d.id)
      );
    },
    [designers, currentFormValues]
  );

  // if (
  //   id &&
  //   edit &&
  //   initialValues.members.filter((m) => m.designer_ids?.length)?.length === 0
  // ) {
  //   return (
  //     <Modal.Body className="overlay overlay-block cursor-default">
  //       <div className="overlay-layer bg-transparent">
  //         <div className="spinner spinner-lg spinner-success" />
  //       </div>
  //     </Modal.Body>
  //   );
  // }

  if (!teamFormDropdownLoading && teamFormDropdownFailed) {
    return (
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <i className="flaticon-warning"></i>
        <p>
          Something went wrong. Please check your internet connection and try
          again.
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={TeamEditSchema()}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={(values) => {
            saveTeam(values);
          }}
        >
          {({ values, errors, handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {formLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-10">
                      <Select
                        name="pm_id"
                        label="Project Manager"
                        disabled={formLoading}
                        withFeedbackLabel={false}
                      >
                        <option value="">Select</option>
                        {pms.map((p) => (
                          <option key={p.id} value={p.id}>
                            {p.name}
                          </option>
                        ))}
                      </Select>
                      {errors?.pm_id && (
                        <span className="invalid-feedback">
                          {errors?.pm_id}
                        </span>
                      )}
                    </div>
                  </div>
                  <FieldArray name="members">
                    {(arrayHelpers) => (
                      <>
                        {values?.members?.map((member, index) => (
                          <TeamFormLeaderRow
                            key={index}
                            member={member}
                            formLoading={formLoading}
                            index={index}
                            handleAddRemoveRow={handleAddRemoveRow}
                            arrayHelpers={arrayHelpers}
                            getFilteredTLs={getFilteredTLs}
                            getFilteredDesigners={getFilteredDesigners}
                            errors={errors}
                          />
                        ))}
                      </>
                    )}
                  </FieldArray>
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Select
                        id="status"
                        name="is_active"
                        label="Status"
                        disabled={formLoading}
                        withFeedbackLabel={false}
                      >
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Select>
                      {errors?.is_active && (
                        <span className="invalid-feedback">
                          {errors?.is_active}
                        </span>
                      )}
                    </div>
                  </div>
                  <ValueChangeListener updateFormValues={updateFormValues} />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => !formLoading && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
