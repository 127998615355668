// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
// import overlayFactory from 'react-bootstrap-table2-overlay';

import { Pagination } from "../../_metronic/_partials/controls";

export default function Table({ totalCount, entities, listLoading, columns, paginationOptions, handleTableChange, sortState=null}) {
  return (
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                remote
                sort={sortState}
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                onTableChange={(type, newState) => handleTableChange(type, newState)}
                noDataIndication={() => !listLoading && <div>No Data Found...</div>}
                {...paginationTableProps}
              />
            </Pagination>
          );
        }}
      </PaginationProvider>
  );
}