/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import SVG from "react-inlinesvg";
// import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { removeClientResource, setAsPM, clientID }
) {
  if (row.designation === "Project Manager") {
    return (
      <>
        <a
          style={{ marginRight: "10px" }}
          title="Edit user"
          className="btn btn-light btn-hover-primary"
          onClick={() => removeClientResource(row.id, clientID)}
        >
          Remove{}
        </a>
      </>
    );
  } else {
    return (
      <>
        <a
          style={{ marginRight: "10px" }}
          title="Edit user"
          className="btn btn-light btn-hover-primary"
          onClick={() => removeClientResource(row.id, clientID)}
        >
          Remove{}
        </a>
        <a
          title="Edit user"
          className="btn btn-light btn-hover-primary"
          onClick={() => setAsPM(row.id, clientID)}
        >
          Set as PM
        </a>
      </>
    );
  }
}
