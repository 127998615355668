import React, { useEffect, useState } from "react";
import { Select } from "../../../../_metronic/_partials/controls";
import { IconButton } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import MultiSelect from "react-multi-select-component";
import { useFormikContext } from "formik";

export const TeamFormLeaderRow = ({
  member,
  formLoading,
  index,
  arrayHelpers,
  errors,
  getFilteredTLs,
  getFilteredDesigners,
}) => {
  const { values, setFieldValue } = useFormikContext();
  const [selectedDesigners, setSelectedDesigners] = useState([]);

  useEffect(() => {
    if (member?.designer_ids?.filter((d) => d.label && d.value)?.length) {
      setSelectedDesigners(member.designer_ids);
    }
  }, [member]);

  useEffect(() => {
    setTimeout(() => {
      setFieldValue(
        `members.${index}.designer_ids`,
        selectedDesigners.map((sd) => sd.value)
      );
    }, 300);
  }, [index, selectedDesigners, setFieldValue]);

  const designerOptions = getFilteredDesigners(index).map((d) => ({
    label: d.name,
    value: d.id,
  }));

  return (
    <div className="form-group row">
      <div className="col-lg-4">
        <Select
          name={`members.${index}.tl_id`}
          label="Team Leader"
          disabled={formLoading}
          withFeedbackLabel={false}
          value={values?.members?.[index]?.tl_id}
        >
          <option value="">Select</option>
          {getFilteredTLs(index).map((tl) => (
            <option key={tl.id} value={tl.id}>
              {tl.name}
            </option>
          ))}
        </Select>
        {errors.members?.[index]?.tl_id && (
          <span className="invalid-feedback">
            {errors.members?.[index]?.tl_id}
          </span>
        )}
      </div>
      <div className="col-lg-6">
        <label>Select Designers</label>
        <MultiSelect
          className="react-multi-select-component"
          name={`members.${index}.designer_ids`}
          disabled={formLoading}
          options={designerOptions}
          value={selectedDesigners}
          onChange={setSelectedDesigners}
          labelledBy={"Select Designers"}
          overrideStrings={{
            selectSomeItems: "Select...",
            allItemsAreSelected: "All designers are selected.",
            selectAll: "Select All",
            search: "Search",
            clearSearch: "Clear Search",
          }}
        />
        {errors.members?.[index]?.designer_ids && (
          <span className="invalid-feedback">
            {errors.members?.[index]?.designer_ids}
          </span>
        )}
      </div>
      <div className="col-lg-2" style={{ paddingTop: 22 }}>
        {index > 0 ? (
          <IconButton type="button" onClick={() => arrayHelpers.remove(index)}>
            <Delete />
          </IconButton>
        ) : (
          <IconButton
            type="button"
            onClick={() => {
              arrayHelpers.insert(values.members.length + 1, {
                tl_id: "",
                designer_ids: [],
              });
            }}
          >
            <Add />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export default TeamFormLeaderRow;
