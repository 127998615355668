/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import PasswordGenerator from "./PasswordGenerator";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as manageDesignersActions } from "../redux/manageDesignersRedux";

// Validation schema
const DesignerEditSchema = (password) =>
  Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required"),
    password: password
      ? Yup.string()
          .min(8, "Password must be at least 8 characters")
          .matches(/^\S*$/, { message: "No whitespaces allowed in Password" })
          .matches(/[a-z]/, {
            message: "Password should contain minimum one lowercase letter",
          })
          .matches(/[A-Z]/, {
            message: "Password should contain minimum one uppercase letter",
          })
          .matches(/[0-9]/, {
            message: "Password should contain minimum one number",
          })
          .matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, {
            message: "Password should contain minimum one special character",
          })
          .required("Password is required")
      : null,
  });

export function DesignerForm({ id, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const {
    positionsDropdownFailed,
    positionsDropdownLoading,
    positions,
    designerData,
    getDesignerLoading,
    // getDesignerSuccess,
    getDesignerFailed,
    saveDesignerDataLoading,
    saveDesignerDataFailed,
    saveDesignerDataSuccess,
    updateDesignerFailed,
    updateDesignerLoading,
    updateDesignerSuccess,
  } = useSelector((state) => state.manageDesigners);
  const [changePassword, setChangePassword] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [positionsSuccess, setPositionsSuccess] = useState(false);

  const handleFormChange = (e) => {
    let data = designerData;
    data[e.target.name] = e.target.value;
    dispatch(manageDesignersActions.changeDesignerData(data));
  };

  useEffect(() => {
    if (positions && !positionsSuccess && (!edit || !id)) {
      let data = designerData;
      data.position_id = positions[0].id;
      dispatch(manageDesignersActions.changeDesignerData(data));
    }
  }, [positions]);

  useEffect(() => {
    if (
      positionsDropdownLoading ||
      getDesignerLoading ||
      saveDesignerDataLoading ||
      updateDesignerLoading
    ) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [
    positionsDropdownLoading,
    getDesignerLoading,
    saveDesignerDataLoading,
    updateDesignerLoading,
  ]);

  useEffect(() => {
    if (id && edit) {
      dispatch(manageDesignersActions.getDesigner(id, cookies.accessToken));
    }
    dispatch(manageDesignersActions.getPositionsDropdown(cookies.accessToken));
  }, []);

  useEffect(() => {
    if (
      !saveDesignerDataLoading &&
      !saveDesignerDataFailed &&
      saveDesignerDataSuccess
    ) {
      onHide();
    }
  }, [
    saveDesignerDataFailed,
    saveDesignerDataLoading,
    saveDesignerDataSuccess,
  ]);

  useEffect(() => {
    if (
      !updateDesignerLoading &&
      !updateDesignerFailed &&
      updateDesignerSuccess
    ) {
      onHide();
    }
  }, [updateDesignerFailed, updateDesignerLoading, updateDesignerSuccess]);

  const saveDesigner = () => {
    if (id && edit) {
      dispatch(
        manageDesignersActions.updateDesigner(
          id,
          designerData,
          cookies.accessToken
        )
      );
    } else {
      dispatch(
        manageDesignersActions.saveDesigner(designerData, cookies.accessToken)
      );
    }
  };

  const renderPasswordField = () => {
    if (edit && id) {
      if (changePassword) {
        return (
          <div>
            <Field
              value={designerData.password}
              onChange={(e) => handleFormChange(e)}
              type="password"
              name="password"
              component={Input}
              placeholder="Password"
              label="Password"
              disabled={formLoading}
            />
            <PasswordGenerator
              setAutoPassword={(password) =>
                dispatch(manageDesignersActions.changePassword(password))
              }
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#FFA500",
            }}
            onClick={() => !formLoading && setChangePassword(true)}
          >
            Change Password
          </div>
        );
      }
    }
    return (
      <div>
        <Field
          value={designerData.password}
          onChange={(e) => handleFormChange(e)}
          type="password"
          name="password"
          component={Input}
          placeholder="Password"
          label="Password"
          disabled={formLoading}
        />
        <PasswordGenerator
          setAutoPassword={(password) =>
            dispatch(manageDesignersActions.changePassword(password))
          }
        />
      </div>
    );
  };

  if (
    (!positionsDropdownLoading && positionsDropdownFailed) ||
    (!getDesignerLoading && getDesignerFailed)
  ) {
    return (
      <div
        style={{ marginTop: "20px", marginBottom: "20px", textAlign: "center" }}
      >
        <i className="flaticon-warning"></i>
        <p>
          Something went wrong. Please check your internet connection and try
          again.
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={designerData}
          validationSchema={DesignerEditSchema(
            (edit && id && changePassword) || !edit || !id
          )}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveDesigner();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {formLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <Field
                        value={designerData.name}
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Field
                        value={designerData.email}
                        onChange={(e) => handleFormChange(e)}
                        type="email"
                        name="email"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                        disabled={formLoading}
                      />
                    </div>
                  </div>

                  {/* Password */}
                  <div className="form-group row">
                    <div className="col-lg-4">{renderPasswordField()}</div>
                  </div>
                  <div className="form-group row">
                    {/* Position */}
                    <div className="col-lg-4">
                      <Select
                        value={designerData.position_id}
                        name="position_id"
                        label="Position"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      >
                        {positions &&
                          positions.map((positionsData) => (
                            <option
                              key={positionsData.id}
                              value={positionsData.id}
                            >
                              {positionsData.name}
                            </option>
                          ))}
                      </Select>
                    </div>
                    {/* Status */}
                    <div className="col-lg-4">
                      <Select
                        id="status"
                        onChange={(e) => handleFormChange(e)}
                        value={designerData.status}
                        name="status"
                        label="Status"
                        disabled={formLoading}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Select>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={!formLoading ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => !formLoading && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
