/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import MultiSelect from "react-multi-select-component";

import { actions as manageRolesActions } from "../redux/manageRolesRedux";
let entities = [
  {
    id: 1,
    display_name: "Admin",
    status: 1,
  },
  {
    id: 2,
    display_name: "Sub Admin",
    status: 0,
  },
];

// Validation schema
const RoleEditSchema = Yup.object().shape({
  display_name: Yup.string().required("Role Name is required"),
});

export function RoleForm({ id, actionsLoading, onHide, edit }) {
  const dispatch = useDispatch();
  const {
    permissionList,
    selectedPermission,
    roleData,
    roleDisplayName,
    getRoleDataLoading,
    getRoleDataSuccess,
    getRoleDataFailed,
    saveRoleDataSuccess,
    saveRoleDataLoading,
    saveRoleDataFailed,
    rolesSortField,
    rolesSortOrder,
    currentPage,
    maxRolesPerPage,
  } = useSelector((state) => state.manageRoles);

  const [formLoading, setFormLoading] = useState(true);

  const [updateMode, setupdateMode] = useState(false);

  const [roleId, setroleId] = useState(null);

  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (edit) {
      if (id) {
        setroleId(id);
        dispatch(manageRolesActions.selectedPermissionList([]));
        showRole();
      }
    } else {
      setupdateMode(false);
      dispatch(manageRolesActions.setRoleDisplayName(""));
      dispatch(manageRolesActions.selectedPermissionList([]));
    }
  }, []);

  useEffect(() => {
    dispatch(manageRolesActions.getPermissionList(cookies.accessToken));
  }, []);

  useEffect(() => {
    if (getRoleDataLoading || saveRoleDataLoading) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [getRoleDataLoading, saveRoleDataLoading]);

  useEffect(() => {
    if (saveRoleDataSuccess) {
      onHide();
      dispatch(
        manageRolesActions.getRolesList(
          rolesSortField,
          rolesSortOrder,
          currentPage,
          maxRolesPerPage,
          cookies.accessToken
        )
      );
    }
  }, [saveRoleDataSuccess]);

  function createNewRole() {
    if (updateMode) {
      let tempArr = [];
      selectedPermission.forEach(function(item) {
        tempArr.push(item.value);
      });
      let roleDatai = queryString.stringify(
        { permission: tempArr, display_name: roleDisplayName },
        { arrayFormat: "index" }
      );
      dispatch(manageRolesActions.saveRoleDataLoading(true));
      dispatch(
        manageRolesActions.updateRole(roleId, roleDatai, cookies.accessToken)
      );
    } else {
      let tempArr = [];
      selectedPermission.forEach(function(item) {
        tempArr.push(item.value);
      });
      let roleDatai = queryString.stringify(
        { permission: tempArr, display_name: roleDisplayName },
        { arrayFormat: "index" }
      );
      dispatch(manageRolesActions.saveRoleDataLoading(true));
      dispatch(manageRolesActions.createRole(roleDatai, cookies.accessToken));
    }
  }

  function showRole() {
    setupdateMode(true);
    dispatch(manageRolesActions.getRole(id, cookies.accessToken));
  }

  function setSelectedPermission(val) {
    dispatch(manageRolesActions.selectedPermissionList(val));
  }

  function updateRolename(e) {
    let tempRoleData = {
      display_name: e.target.value,
      permission: roleData.permission,
      status: roleData.status,
    };
    dispatch(manageRolesActions.setRoleDisplayName(e.target.value));
    dispatch(manageRolesActions.changeRoleData(tempRoleData));
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={roleData}
        validationSchema={RoleEditSchema}
        onSubmit={(values) => {
          createNewRole();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {(getRoleDataLoading || saveRoleDataLoading) && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Role Name */}
                  <div className="col-lg-12">
                    <Field
                      value={roleData.display_name}
                      name="display_name"
                      component={Input}
                      placeholder="Role Name"
                      label="Role Name"
                      onChange={updateRolename}
                      disabled={formLoading}
                    />
                  </div>
                  {/* Permission */}
                  <div className="col-lg-12">
                    <br></br>
                    <label>Select Permission</label>
                    <MultiSelect
                      disabled={formLoading}
                      options={permissionList}
                      value={selectedPermission}
                      onChange={setSelectedPermission}
                      labelledBy={"Select"}
                      overrideStrings={{
                        selectSomeItems: "Select...",
                        allItemsAreSelected: "All pemissions are selected.",
                        selectAll: "Select All",
                        search: "Search",
                        clearSearch: "Clear Search",
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={!formLoading ? onHide : undefined}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => !formLoading && handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
