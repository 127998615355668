import React, { memo } from "react";
import PropTypes from "prop-types";
import StarRating from "./StarRating";
import "../feedback.scss";
import ResponsesCount from "./ResponsesCount";

const FeedbackSmileyReport = ({ feedbackReport = {} }) => {
  const { reports_average = [], reports_count = [] } = feedbackReport;

  return (
    <>
      {reports_average.map((report) => (
        <div
          key={report?.feedback_question_id}
          className="questions-responses-container"
        >
          <div className="questions-responses-row row">
            <div className="questions-responses-col col-xs-12 col-md-12">
              <h4>{report?.question}</h4>
            </div>
          </div>
          <div className="questions-responses-row row">
            <div className="questions-responses-col col-xs-12 col-md-6">
              <div className="responses-average-rating">
                <div className="average-rating">
                  <strong
                    className={
                      Number(report?.response_avg) >= 3 ? "success" : "danger"
                    }
                  >
                    {report?.response_avg}{" "}
                  </strong>
                  <p>Average Rating</p>
                </div>
                <StarRating averageRating={report?.response_avg} maxStars={5} />
              </div>
            </div>
            <div className="questions-responses-col col-xs-12 col-md-6">
              <div className="responses-report">
                <ResponsesCount
                  reports_count={reports_count}
                  questionId={report?.feedback_question_id}
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="separator separator-dashed my-5" />
          </div>
        </div>
      ))}
    </>
  );
};
FeedbackSmileyReport.propTypes = {
  feedbackReport: PropTypes.shape({
    reports_average: PropTypes.arrayOf(
      PropTypes.shape({
        feedback_question_id: PropTypes.number,
      })
    ),
  }),
};

export default memo(FeedbackSmileyReport);
