/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from 'react-cookie';
import PasswordGenerator from './PasswordGenerator'

import {
  Input,
  Select,
} from "../../../../_metronic/_partials/controls";
import { actions as manageUsersActions } from '../redux/manageUsersRedux';

// Validation schema
const UserEditSchema = (password) => Yup.object().shape({
  name: Yup.string()
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  username: Yup.string()
    .matches(/^\S*$/, { message: 'No whitespaces allowed in Username' })
    .required("Username is required"),
  password: password ? Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/^\S*$/, { message: 'No whitespaces allowed in Password' })
    .matches(/[a-z]/, { message: 'Password should contain minimum one lowercase letter' })
    .matches(/[A-Z]/, { message: 'Password should contain minimum one uppercase letter' })
    .matches(/[0-9]/, { message: 'Password should contain minimum one number' })
    .matches(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, { message: 'Password should contain minimum one special character' })
    .required("Password is required")
    :
    null,
});

export function UserForm({
  userID,
  actionsLoading,
  onHide,
  edit
}) {
  const dispatch = useDispatch();
  const [ cookies ] = useCookies(['accessToken']);
  const {
    rolesDropdownFailed,
    rolesDropdownLoading,
    roles,
    userData,
    getUserLoading,
    getUserSuccess,
    getUserFailed,
    saveUserDataLoading,
    saveUserDataFailed,
    saveUserDataSuccess,
    updateUserFailed,
    updateUserLoading,
    updateUserSuccess
  } = useSelector(state => state.manageUsers)
  const [ changePassword, setChangePassword ] = useState(false)
  const [ formLoading, setFormLoading ] = useState(true);
  const [ rolesSuccess, setRolesSuccess] = useState(false);

  const handleFormChange = (e) => {
    let data = userData;
    data[e.target.name] = e.target.value;
    dispatch(manageUsersActions.changeUserData(data));
  }

  useEffect(() => {
    if (roles && !rolesSuccess && (!edit || !userID)) {
      let data = userData;
      data.role = roles[0].id
      dispatch(manageUsersActions.changeUserData(data))
    }
  }, [roles])


  useEffect(() => {
    if (rolesDropdownLoading || getUserLoading || saveUserDataLoading || updateUserLoading) {
      setFormLoading(true)
    } else {
      setFormLoading(false)
    }
  }, [rolesDropdownLoading, getUserLoading, saveUserDataLoading, updateUserLoading])

  useEffect(() => {
    if (userID && edit) {
      dispatch(manageUsersActions.getUser(userID, cookies.accessToken));
    }
    dispatch(manageUsersActions.getRolesDropdown(cookies.accessToken));
  }, [])

  useEffect(() => {
    if (!saveUserDataLoading && !saveUserDataFailed && saveUserDataSuccess) {
      onHide();
    }
  }, [saveUserDataFailed, saveUserDataLoading, saveUserDataSuccess])

  useEffect(() => {
    if (!updateUserLoading && !updateUserFailed && updateUserSuccess) {
      onHide();
    }
  }, [updateUserFailed, updateUserLoading, updateUserSuccess])

  const saveUser = () => {
    if (userID && edit) {
      dispatch(manageUsersActions.updateUser(userID, userData, cookies.accessToken));
    } else {
      dispatch(manageUsersActions.saveUser(userData, cookies.accessToken))
    }
  }

  const renderPasswordField = () => {
    if (edit && userID) {
      if (changePassword) {
        return (
          <div>
            <Field
              value={userData.password}
              onChange={(e) => handleFormChange(e)}
              type="password"
              name="password"
              component={Input}
              placeholder="Password"
              label="Password"
              disabled={formLoading}
            />
            <PasswordGenerator
              setAutoPassword={(password => dispatch(manageUsersActions.changePassword(password)))}
            />
          </div>
        );
      } else {
        return (
          <div
            style={{
              textDecoration:'underline',
              cursor: 'pointer',
              color:'#FFA500'
            }} 
            onClick={() => (!formLoading) && setChangePassword(true)}
          >
            Change Password
          </div>
        );
      }
    }
    return (
      <div>
        <Field
          value={userData.password}
          onChange={(e) => handleFormChange(e)}
          type="password"
          name="password"
          component={Input}
          placeholder="Password"
          label="Password"
          disabled={formLoading}
        />
        <PasswordGenerator
          setAutoPassword={(password => dispatch(manageUsersActions.changePassword(password)))}
        />
      </div>
    );
  }

  if ((!rolesDropdownLoading && rolesDropdownFailed) || (!getUserLoading && getUserFailed)) {
    return (
      <div style={{marginTop: '20px', marginBottom: '20px', textAlign: 'center'}}>
        <i className="flaticon-warning"></i>
        <p>Something went wrong. Please check your internet connection and try again.</p>
      </div>
    );
  } else {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={userData}
          validationSchema={UserEditSchema((edit && userID && changePassword) || (!edit || !userID))}
          handleChange={(e) => handleFormChange(e)}
          onSubmit={() => {
            saveUser();
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {(formLoading) && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    {/* Name */}
                    <div className="col-lg-4">
                      <Field
                        value={userData.name}
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      />
                    </div>
                    {/* Login */}
                    <div className="col-lg-4">
                      <Field
                        value={userData.username}
                        onChange={(e) => handleFormChange(e)}
                        name="username"
                        component={Input}
                        placeholder="Username"
                        label="Username"
                        disabled={formLoading}
                      />
                    </div>
                  </div>
                  {/* Email */}
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <Field
                        value={userData.email}
                        onChange={(e) => handleFormChange(e)}
                        type="email"
                        name="email"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                        disabled={formLoading}
                      />
                    </div>
                  </div>
                  {/* Password */}
                  <div className="form-group row">
                    <div className="col-lg-4">
                      {renderPasswordField()}
                    </div>
                  </div>
                  <div className="form-group row">
                    {/* Role */}
                    <div className="col-lg-4">
                      <Select
                        value={userData.role}
                        name="role"
                        label="Role"
                        onChange={(e) => handleFormChange(e)}
                        disabled={formLoading}
                      >
                        { roles && roles.map((rolesData) => 
                          <option key={rolesData.id} value={rolesData.id}>{rolesData.name}</option>
                        )}
                      </Select>
                    </div>
                    {/* Status */}
                    <div className="col-lg-4">
                      <Select
                        id="status"
                        onChange={(e) => handleFormChange(e)}
                        value={userData.status}
                        name="status" 
                        label="Status"
                        disabled={formLoading}
                      >
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </Select>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={(!formLoading) ? onHide : undefined}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => (!formLoading) && handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
}
