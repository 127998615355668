import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import FeedbackSettings from "./pages/FeedbackSettings";
import FeedbackReport from "./pages/FeedbackReport";

function FeedbackSettingsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/feedback-settings"
          to="/feedback-settings/feedback-configs"
        />
        <ContentRoute
          exact={true}
          path="/feedback-settings/feedback-configs"
          component={FeedbackSettings}
        />
        <ContentRoute
          exact={true}
          path="/feedback-settings/feedback-report"
          component={FeedbackReport}
        />
      </Switch>
    </div>
  );
}

export default FeedbackSettingsPage;
