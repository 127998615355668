import React, { useEffect, useState } from "react";
import { Tab, Nav, Col, Row } from "react-bootstrap";
import ClientEditForm from "../components/ClientEditForm";
import ClientApiAccessKey from "../components/ClientApiAccessKey";
import ClientResources from "../components/ClientResources";
import ClientSubuserList from "../components/ClientSubuserList";
import ClientActivityLogs from "../components/ClientActivityLogs";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import config from "../../../../configs/config";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import FeedbackReOpen from "../../FeedbackSettings/components/FeedbackReOpen";

export default function ClientEdit(props) {
  const {
    clientName,
    accessTokenForLoginAsClient,
    loginAsClientSuccess,
    clientData
  } = useSelector((state) => state.manageClients);
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const [activeTab, setActiveTab] = useState("accountdetails");
  const tabSelect = (k) => {
    if (k === "accountdetails") {
      setActiveTab("accountdetails");
    }
    if (k === "apiaccesskeys") {
      setActiveTab("apiaccesskeys");
    }
    if (k === "resources") {
      setActiveTab("resources");
    }
    if (k === "subusers") {
      setActiveTab("subusers");
    }
    if (k === "activitylogs") {
      setActiveTab("activitylogs");
    }
  };
  const loginAsClientClick = () => {
    dispatch(
      manageClientsActions.loginAsClient(
        props.match.params.id,
        cookies.accessToken
      )
    );
  };
  useEffect(() => {
    if (loginAsClientSuccess) {
      window.open(
        config.FRONT_URL +
          "login?clientAccessToken=" +
          accessTokenForLoginAsClient,
        "_blank"
      );
      dispatch(manageClientsActions.clearLoginAsClientFlags());
    }
  }, [accessTokenForLoginAsClient, dispatch, loginAsClientSuccess]);
  return (
    <Card>
      <CardHeader title={clientName}>
        <CardHeaderToolbar>
          <FeedbackReOpen clientID={clientData?.id}/>
          <button className="btn btn-success" onClick={loginAsClientClick}>
            Login as Client
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Tab.Container
          onSelect={tabSelect}
          id="left-tabs-example"
          defaultActiveKey="accountdetails"
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="accountdetails">Account Details</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="resources">Resources</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="subusers">Sub Users</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="apiaccesskeys">API Access Keys</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="activitylogs">Activity Logs</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="accountdetails">
                  <ClientEditForm id={props.match.params.id} />
                </Tab.Pane>
                <Tab.Pane eventKey="resources">
                  <ClientResources
                    id={props.match.params.id}
                    activetab={activeTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="subusers">
                  <ClientSubuserList
                    clientID={props.match.params.id}
                    activetab={activeTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="apiaccesskeys">
                  <ClientApiAccessKey
                    id={props.match.params.id}
                    activetab={activeTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="activitylogs">
                  <ClientActivityLogs
                    clientID={props.match.params.id}
                    activetab={activeTab}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </CardBody>
    </Card>
  );
}
