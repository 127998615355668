import { put, takeLatest, call, all, select } from "redux-saga/effects";
import ShortUniqueId from "short-unique-id";
import queryString from "query-string";

import { getApiToken, putApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_FEEDBACK_LINK_DATA: "GET_FEEDBACK_LINK_DATA",
  SET_FEEDBACK_LINK_DATA: "SET_FEEDBACK_LINK_DATA",
  GET_FEEDBACK_LINK_DATA_LOADING: "GET_FEEDBACK_LINK_DATA_LOADING",
  UPDATE_FEEDBACK_LINK_DATA: "UPDATE_FEEDBACK_LINK_DATA",
  GET_NEXT_FEEDBACK_SCHEDULE_DATE: "GET_NEXT_FEEDBACK_SCHEDULE_DATE",
  SET_NEXT_FEEDBACK_SCHEDULE_DATE: "SET_NEXT_FEEDBACK_SCHEDULE_DATE",
  SET_FEEDBACK_LIST_LOADING: "SET_FEEDBACK_LIST_LOADING",
  GET_FEEDBACK_LIST: "GET_FEEDBACK_LIST",
  SET_FEEDBACK_LIST: "SET_FEEDBACK_LIST",
  CLEAR_FEEDBACK_LIST: "CLEAR_FEEDBACK_LIST",
  SET_FEEDBACK_CURRENT_PAGE: "SET_FEEDBACK_CURRENT_PAGE",
  CHANGE_FEEDBACK_CURRENT_PAGE: "CHANGE_FEEDBACK_CURRENT_PAGE",
  CHANGE_FEEDBACK_SORT: "CHANGE_FEEDBACK_SORT",
  OPEN_FEEDBACK_MORE_DETAIL_POPUP: "OPEN_FEEDBACK_MORE_DETAIL_POPUP",
  SET_FEEDBACK_CLIENT_LIST_LOADING: "SET_FEEDBACK_CLIENT_LIST_LOADING",
  GET_FEEDBACK_CLIENT_LIST: "GET_FEEDBACK_CLIENT_LIST",
  SET_FEEDBACK_CLIENT_LIST: "SET_FEEDBACK_CLIENT_LIST",
  CLEAR_FEEDBACK_CLIENT_LIST: "CLEAR_FEEDBACK_CLIENT_LIST",
  SET_FEEDBACK_CLIENT_CURRENT_PAGE: "SET_FEEDBACK_CLIENT_CURRENT_PAGE",
  CHANGE_FEEDBACK_CLIENT_CURRENT_PAGE: "CHANGE_FEEDBACK_CLIENT_CURRENT_PAGE",
  CHANGE_FEEDBACK_CLIENT_SORT: "CHANGE_FEEDBACK_CLIENT_SORT",
  SET_FEEDBACK_CLIENT_FILTER: "SET_FEEDBACK_CLIENT_FILTER",
  CHANGE_FEEDBACK_CLIENT_FILTER_DATA: "CHANGE_FEEDBACK_CLIENT_FILTER_DATA",
};

const initialFeedbackLinkState = {
  feedbackLink: "",
  feedbackLinkLoading: false,
  nextFeedbackScheduled: "",
  feedbackList: [],
  totalFeedbackRecords: 0,
  feedbackListLoading: false,
  currentPage: 0,
  maxFeedbackPerPage: 10,
  feedbackSortField: "id",
  feedbackSortOrder: "desc",
  isFilter: false,
  moreDetailPopup: false,
  feedbackClientListLoading: false,
  feedbackClientList: [],
  totalFeedbackClientRecords: 0,
  currentClientPage: 0,
  maxFeedbackClientPerPage: 10,
  feedbackClientSortField: "email",
  feedbackClientSortOrder: "asc",
  isClientFilter: false,
  feedbackClientFilterData: {
    filterEmail: "",
    filterStatus: "",
  },
};

export const reducer = (state = initialFeedbackLinkState, action) => {
  switch (action.type) {
    case actionTypes.SET_FEEDBACK_LINK_DATA: {
      const { feedbackLink } = action.payload;
      return {
        ...state,
        feedbackLink: feedbackLink,
      };
    }
    case actionTypes.SET_NEXT_FEEDBACK_SCHEDULE_DATE: {
      const { nextFeedbackScheduled } = action.payload;
      return {
        ...state,
        nextFeedbackScheduled: nextFeedbackScheduled,
      };
    }
    case actionTypes.GET_FEEDBACK_LINK_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackLinkLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }
    case actionTypes.SET_FEEDBACK_LIST_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackListLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_LIST: {
      const { feedbackData } = action.payload;
      const totalFeedbackRecords =
        state.isFilter && feedbackData.recordsTotal
          ? feedbackData.recordsFiltered
          : feedbackData.recordsTotal;

      const feedbackList = feedbackData.data ? feedbackData.data : [];
      return {
        ...state,
        totalFeedbackRecords,
        feedbackList,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        feedbackSortField: field,
        feedbackSortOrder: order,
      };
    }
    case actionTypes.CLEAR_FEEDBACK_LIST: {
      return {
        ...state,
        totalFeedbackRecords: undefined,
        feedbackList: [],
      };
    }
    case actionTypes.OPEN_FEEDBACK_MORE_DETAIL_POPUP: {
      const { flag } = action.payload;
      return {
        ...state,
        moreDetailPopup: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_CLIENT_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentClientPage: data,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_CLIENT_CURRENT_PAGE: {
      const { page } = action.payload;
      return {
        ...state,
        currentClientPage: page,
      };
    }
    case actionTypes.SET_FEEDBACK_CLIENT_LIST_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        feedbackClientListLoading: flag,
      };
    }
    case actionTypes.SET_FEEDBACK_CLIENT_LIST: {
      const { feedbackClientData } = action.payload;
      const totalFeedbackClientRecords =
        state.isClientFilter && feedbackClientData.recordsTotal
          ? feedbackClientData.recordsFiltered
          : feedbackClientData.recordsTotal;

      const feedbackClientList = feedbackClientData.data
        ? feedbackClientData.data
        : [];
      return {
        ...state,
        totalFeedbackClientRecords,
        feedbackClientList,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_CLIENT_SORT: {
      const { field, order } = action.payload;
      return {
        ...state,
        feedbackClientSortField: field,
        feedbackClientSortOrder: order,
      };
    }
    case actionTypes.SET_FEEDBACK_CLIENT_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isClientFilter: flag,
      };
    }
    case actionTypes.CHANGE_FEEDBACK_CLIENT_FILTER_DATA: {
      const { filterData } = action.payload;
      return {
        ...state,
        feedbackClientFilterData: filterData,
      };
    }
    case actionTypes.CLEAR_FEEDBACK_CLIENT_LIST: {
      return {
        ...state,
        totalFeedbackClientRecords: undefined,
        feedbackClientList: [],
      };
    }
    default:
      return state;
  }
};

export const actions = {
  getFeedbackLink: (accessToken) => ({
    type: actionTypes.GET_FEEDBACK_LINK_DATA,
    payload: { accessToken },
  }),
  setFeedbackLink: (feedbackLink) => ({
    type: actionTypes.SET_FEEDBACK_LINK_DATA,
    payload: { feedbackLink },
  }),
  getFeedbackLinkLoading: (flag) => ({
    type: actionTypes.GET_FEEDBACK_LINK_DATA_LOADING,
    payload: { flag },
  }),
  updateFeedbackLinkData: (feedbackLink, accessToken) => ({
    type: actionTypes.UPDATE_FEEDBACK_LINK_DATA,
    payload: { feedbackLink, accessToken },
  }),
  getNextFeedbackScheduledDate: (accessToken) => ({
    type: actionTypes.GET_NEXT_FEEDBACK_SCHEDULE_DATE,
    payload: { accessToken },
  }),
  setNextFeedbackScheduledDate: (nextFeedbackScheduled) => ({
    type: actionTypes.SET_NEXT_FEEDBACK_SCHEDULE_DATE,
    payload: { nextFeedbackScheduled },
  }),
  setFeedbackListLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_LIST_LOADING,
    payload: { flag },
  }),
  getFeedbackList: (sortField, sortOrder, page, length, accessToken) => ({
    type: actionTypes.GET_FEEDBACK_LIST,
    payload: { page, length, accessToken, sortField, sortOrder },
  }),
  setFeedbackList: (feedbackData) => ({
    type: actionTypes.SET_FEEDBACK_LIST,
    payload: { feedbackData },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_FEEDBACK_LIST }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_CURRENT_PAGE,
    payload: { page, accessToken, length },
  }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_SORT,
    payload: { field, order, accessToken },
  }),
  toggleFeedbackMoreDetailPopup: (flag) => ({
    type: actionTypes.OPEN_FEEDBACK_MORE_DETAIL_POPUP,
    payload: { flag },
  }),
  setFeedbackClientListLoading: (flag) => ({
    type: actionTypes.SET_FEEDBACK_CLIENT_LIST_LOADING,
    payload: { flag },
  }),
  getFeedbackClientList: (
    id,
    sortField,
    sortOrder,
    page,
    length,
    accessToken
  ) => ({
    type: actionTypes.GET_FEEDBACK_CLIENT_LIST,
    payload: { id, page, length, accessToken, sortField, sortOrder },
  }),
  setFeedbackClientList: (feedbackClientData) => ({
    type: actionTypes.SET_FEEDBACK_CLIENT_LIST,
    payload: { feedbackClientData },
  }),
  clearClientList: () => ({ type: actionTypes.CLEAR_FEEDBACK_CLIENT_LIST }),
  changeCurrentClientPage: (id, page, length, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_CLIENT_CURRENT_PAGE,
    payload: { id, page, accessToken, length },
  }),
  setCurrentClientPage: (data) => ({
    type: actionTypes.SET_FEEDBACK_CLIENT_CURRENT_PAGE,
    payload: { data },
  }),
  setClientFilter: (flag) => ({
    type: actionTypes.SET_FEEDBACK_CLIENT_FILTER,
    payload: { flag },
  }),
  changeClientSort: (id, field, order, accessToken) => ({
    type: actionTypes.CHANGE_FEEDBACK_CLIENT_SORT,
    payload: { id, field, order, accessToken },
  }),
  changeFeedbackClientFilterData: (filterData) => ({
    type: actionTypes.CHANGE_FEEDBACK_CLIENT_FILTER_DATA,
    payload: { filterData },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_FEEDBACK_LINK_DATA, getFeedbackLinkSaga);
  yield takeLatest(
    actionTypes.UPDATE_FEEDBACK_LINK_DATA,
    updateFeedbackLinkSaga
  );
  yield takeLatest(
    actionTypes.GET_NEXT_FEEDBACK_SCHEDULE_DATE,
    getNextFeedbackScheduledSaga
  );
  yield takeLatest(
    actionTypes.CHANGE_FEEDBACK_CURRENT_PAGE,
    function* changePageSaga(action) {
      const { feedbackSortField, feedbackSortOrder } = yield select(
        (state) => state.feedbackForm
      );
      const { page, length, accessToken } = action.payload;
      yield put(
        actions.getFeedbackList(
          feedbackSortField,
          feedbackSortOrder,
          page,
          length,
          accessToken
        )
      );
    }
  );

  yield takeLatest(actionTypes.CHANGE_FEEDBACK_SORT, function* changeSortSaga(
    action
  ) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxFeedbackPerPage } = yield select(
      (state) => state.feedbackForm
    );
    yield put(
      actions.getFeedbackList(
        field,
        order,
        currentPage,
        maxFeedbackPerPage,
        accessToken
      )
    );
  });
  yield takeLatest(actionTypes.GET_FEEDBACK_LIST, getFeedbackListSaga);
  yield takeLatest(
    actionTypes.CHANGE_FEEDBACK_CLIENT_CURRENT_PAGE,
    function* changeClientPageSaga(action) {
      const { feedbackClientSortField, feedbackClientSortOrder } = yield select(
        (state) => state.feedbackForm
      );
      const { id, page, length, accessToken } = action.payload;
      yield put(
        actions.getFeedbackClientList(
          id,
          feedbackClientSortField,
          feedbackClientSortOrder,
          page,
          length,
          accessToken
        )
      );
    }
  );

  yield takeLatest(
    actionTypes.CHANGE_FEEDBACK_CLIENT_SORT,
    function* changeClientSortSaga(action) {
      let { id, field, order, accessToken } = action.payload;
      let { currentClientPage, maxFeedbackClientPerPage } = yield select(
        (state) => state.feedbackForm
      );
      yield put(
        actions.getFeedbackClientList(
          id,
          field,
          order,
          currentClientPage,
          maxFeedbackClientPerPage,
          accessToken
        )
      );
    }
  );
  yield takeLatest(
    actionTypes.GET_FEEDBACK_CLIENT_LIST,
    getFeedbackClientListSaga
  );
}

function* getFeedbackLinkSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.getFeedbackLinkLoading(true))]);

  try {
    const response = yield call(
      getApiToken,
      apiUrl("FEEDBACK_FORM_LINK"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        let feedbackLink = responseData.data["feedback-form-link"];
        yield all([
          put(actions.setFeedbackLink(feedbackLink)),
          put(actions.getFeedbackLinkLoading(false)),
        ]);
      } else {
        yield all([put(actions.getFeedbackLinkLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getFeedbackLinkLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Feedback Link",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.getFeedbackLinkLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Feedback Link",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getFeedbackLinkLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Feedback Link",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateFeedbackLinkSaga(action) {
  let { feedbackLink, accessToken } = action.payload;
  yield all([put(actions.getFeedbackLinkLoading(true))]);

  let formData = {};
  formData["feedback-form-link"] = feedbackLink;

  try {
    const response = yield call(
      putApiToken,
      apiUrl("FEEDBACK_FORM_LINK"),
      queryString.stringify(formData),
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        const {
          feedbackSortField,
          feedbackSortOrder,
          currentPage,
          maxFeedbackPerPage,
        } = yield select((state) => state.feedbackForm);
        yield all([
          put(actions.getNextFeedbackScheduledDate(accessToken)),
          put(
            actions.getFeedbackList(
              feedbackSortField,
              feedbackSortOrder,
              currentPage,
              maxFeedbackPerPage,
              accessToken
            )
          ),
          put(actions.getFeedbackLinkLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Update Feedback Link",
            "Feedback Link data updated successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.getFeedbackLinkLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getFeedbackLinkLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Feedback Link",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.getFeedbackLinkLoading(false))]);
      yield put(
        generalActions.addToast(
          "Update Feedback Link",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getFeedbackLinkLoading(false))]);
    yield put(
      generalActions.addToast(
        "Update Feedback Link",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getNextFeedbackScheduledSaga(action) {
  let { accessToken } = action.payload;
  try {
    const response = yield call(
      getApiToken,
      apiUrl("NEXT_FEEDBACK_SCHEDULED_ON"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        let nextFeedbackSchedule =
          responseData.data["feedback-form-next-scheduled-on"];
        yield all([
          put(actions.setNextFeedbackScheduledDate(nextFeedbackSchedule)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(
          generalActions.addToast(
            "Get Next Feedback Schedule Date",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(
        generalActions.addToast(
          "Get Next Feedback Schedule Date",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    yield put(
      generalActions.addToast(
        "Get Next Feedback Schedule Date",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackListSaga(action) {
  let { page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setFeedbackListLoading(true));
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]= &columns[0][name]= &columns[0][searchable]= false&columns[0][orderable]= false&columns[0][search][value]= &columns[0][search][regex]= false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("FEEDBACK_LIST"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([
          put(actions.setFeedbackList(responseData.data)),
          put(actions.setFeedbackListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setFeedbackListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setFeedbackListLoading(false));
        yield put(
          generalActions.addToast(
            "Feedback List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setFeedbackListLoading(false));
      yield put(
        generalActions.addToast(
          "Feedback List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    yield put(actions.setFeedbackListLoading(false));
    yield put(
      generalActions.addToast(
        "Feedback List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getFeedbackClientListSaga(action) {
  let { id, page, length, accessToken, sortField, sortOrder } = action.payload;
  yield put(actions.setFeedbackClientListLoading(true));
  let { feedbackClientFilterData } = yield select(
    (state) => state.feedbackForm
  );
  const filterParams = {
    start: page * length,
    length: length,
  };

  const sortParams = `&columns[0][data]=email&columns[0][name]= &columns[0][searchable]=true&columns[0][orderable]=true&columns[0][search][value]=${feedbackClientFilterData.filterEmail} &columns[0][search][regex]= false&columns[1][data]=status&columns[1][name]= &columns[1][searchable]= true&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]=${sortField==='email'?'0':'1'}&order[0][dir]= ${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("FEEDBACK_CLIENT_LIST")}/${id}`,
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData?.data) {
        yield all([
          put(actions.setFeedbackClientList(responseData.data)),
          put(actions.setFeedbackClientListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearClientList()),
          put(actions.setFeedbackClientListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData?.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setFeedbackClientListLoading(false));
        yield put(
          generalActions.addToast(
            "Feedback Client List",
            "Something went wrong!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield put(actions.setFeedbackClientListLoading(false));
      yield put(
        generalActions.addToast(
          "Feedback Client List",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setFeedbackClientListLoading(false));
    yield put(
      generalActions.addToast(
        "Feedback Client List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
