import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import ManageConfig from "./pages/ManageConfig";
import EmailNotification from "./pages/EmailNotification";
import FeedbackForm from "./pages/FeedbackForm";

function ConfigSettingsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/config-settings"
          to="/config-settings/manage-configs"
        />
        <ContentRoute
          exact={true}
          path="/config-settings/manage-configs"
          component={ManageConfig}
        />
        <ContentRoute
          exact={true}
          path="/config-settings/email-notification"
          component={EmailNotification}
        />
        <ContentRoute
          exact={true}
          path="/config-settings/feedback-form"
          component={FeedbackForm}
        />
      </Switch>
    </div>
  );
}

export default ConfigSettingsPage;
