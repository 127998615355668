import React from "react";
import { FieldFeedbackLabel } from "../../_metronic/_partials/controls/forms/FieldFeedbackLabel";
import MultiSelect from "react-multi-select-component";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["multi-select"];
  if (touched && errors) {
    classes.push("errorMultiSelect");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function CustomMultiSelect({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  meta,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <MultiSelect
        inputProps={{
          name: field.name,
          required: true,
        }}
        labelledBy={props.labelledBy}
        options={props.options}
        overrideStrings={props.overrideStrings}
        value={props.value}
        onChange={props.onChange}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        disabled={props.disabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
