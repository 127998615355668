/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import Input from "../../../../_metronic/_partials/controls/forms/Input";
import { Select } from "../../../../_metronic/_partials/controls";
import { actions as feedbackSettingActions } from "../redux/feedbackSettingRedux";
import { useCookies } from "react-cookie";

const FeedbackQuestionSchema = () =>
  Yup.object().shape({
    question: Yup.string("Question must be a string").required(
      "Question is required"
    ),
    type: Yup.string("Answer type must be a string").required(
      "Answer type is required"
    ),
  });

const FeedbackQuestionDialog = ({
  selectedQuestion = {},
  setSelectedQuestion,
}) => {
  const [cookies] = useCookies(["accessToken"]);
  const formikRef = useRef(null);
  const dispatch = useDispatch();
  const {
    feedback,
    showQuestionPopup,
    feedbackQuestionFormLoading,
    feedbackQuestionFormData,
  } = useSelector((state) => state.feedbackSettings);

  useEffect(() => {
    if (showQuestionPopup) {
      if (selectedQuestion) {
        dispatch(
          feedbackSettingActions.getFeedbackQuestionFormData(
            feedback?.id,
            selectedQuestion.id,
            cookies.accessToken
          )
        );
      } else {
        dispatch(
          feedbackSettingActions.getFeedbackQuestionFormData(
            feedback?.id,
            null,
            cookies.accessToken
          )
        );
      }
    }
  }, [showQuestionPopup, selectedQuestion]);

  const createUpdateQuestion = (values) => {
    if (selectedQuestion) {
      dispatch(
        feedbackSettingActions.updateFeedbackQuestionData(
          feedback?.id,
          selectedQuestion.id,
          values,
          cookies.accessToken
        )
      );
    } else {
      dispatch(
        feedbackSettingActions.createFeedbackQuestionData(
          feedback?.id,
          values,
          cookies.accessToken
        )
      );
    }
    setSelectedQuestion(null);
    dispatch(feedbackSettingActions.toggleFeedbackQuestionPopup(false));
  };

  const questionTypeOptions = useMemo(() => {
    return feedbackQuestionFormData?.["feedback-question-types"]?.filter(
      (type) => type?.status === 1
    );
  }, [feedbackQuestionFormData]);

  const initialFQData = useMemo(() => {
    const fq = feedbackQuestionFormData?.["feedback-question"];
    const { question = "", type = "Rating with Smiley" } = fq || {};
    return { question, type };
  }, [feedbackQuestionFormData]);

  return (
    <Modal
      size="lg"
      show={showQuestionPopup}
      onHide={() => {
        setSelectedQuestion(null);
        dispatch(feedbackSettingActions.toggleFeedbackQuestionPopup(false));
      }}
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          {selectedQuestion ? "Edit Question" : "Add Question"}
        </Modal.Title>
      </Modal.Header>

      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        validationSchema={FeedbackQuestionSchema}
        initialValues={initialFQData}
        onSubmit={(values) => {
          createUpdateQuestion(values);
        }}
      >
        {({ handleSubmit, values, handleChange, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="form-group">
                      <Field
                        type="text"
                        value={values.question}
                        name="question"
                        component={Input}
                        placeholder="Question"
                        label="Question"
                        onChange={handleChange}
                        disabled={feedbackQuestionFormLoading}
                      />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                    <Select
                      id="type"
                      onChange={handleChange}
                      value={values.type}
                      name="type"
                      label="Answer Type"
                      disabled={feedbackQuestionFormLoading}
                    >
                      {questionTypeOptions?.map((type) => (
                        <option key={type.id} value={type.title}>
                          {type.title}
                        </option>
                      ))}
                    </Select>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={
                    !feedbackQuestionFormLoading
                      ? () => {
                          setSelectedQuestion(null);
                          dispatch(
                            feedbackSettingActions.toggleFeedbackQuestionPopup(
                              false
                            )
                          );
                        }
                      : undefined
                  }
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success btn-elevate">
                  Save
                </button>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

FeedbackQuestionDialog.propTypes = {
  selectedQuestion: PropTypes.shape({
    id: PropTypes.number,
  }),
  setSelectedQuestion: PropTypes.func.isRequired,
};

export default memo(FeedbackQuestionDialog);
