// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React from "react";
import { Toast } from "react-bootstrap";

export default function ToastComponent({ headerText, text, type, removeToast }) {
  const color = () => {
    if (type === "error") {
      return "#ff0039"
    } else if (type === "info") {
      return "#2780e3"
    } else if (type === "warning") {
      // return "#18bc9c"
      return "#f39c12"
    } else if (type === "success") {
      return "#28a745"
    } else {
      return "#18bc9c"
    }
  }

  return (
    <Toast animation={true} autohide={true} delay={6000} onClose={removeToast} style={{width: '100%'}}>
      <Toast.Header style={{ backgroundColor: color() }}>
        <strong style={{color: "#ffffff"}} className="mr-auto">{headerText}</strong>
      </Toast.Header>
    <Toast.Body>{text}</Toast.Body>
    </Toast>      
  );
}