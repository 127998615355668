import { Add } from "@material-ui/icons";
import React from "react";
import { Badge, Tooltip, withStyles } from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
  },
}))(Tooltip);

const StyledBadge = withStyles((theme) => ({
  badge: {
    position: "relative",
    right: 15,
    top: 13,
    padding: "13px",
    color: "#F58220",
    backgroundColor: "#fcd4b2",
  },
}))(Badge);

export const RenderTeamLead = (item) => {
  const [firstTl, ...restTl] = item;
  return (
    <div>
      <span>{firstTl?.tl_id_text}</span>
      {restTl?.length > 0 ? (
        <HtmlTooltip
          placement="top-end"
          arrow
          title={
            <ul style={{ paddingLeft: 15, margin: 0 }}>
              {restTl.map((tl, index) => (
                <li key={index}>{tl.tl_id_text || 'NA'}</li>
              ))}
            </ul>
          }
        >
          <StyledBadge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Add />
                <b style={{ fontSize: 15 }}>{restTl.length}</b>
              </div>
            }
            color="secondary"
          >
            {/* <Add /> */}
          </StyledBadge>
        </HtmlTooltip>
      ) : null}
    </div>
  );
};

export const RenderDesigners = (itemData, rowData) => {
  const [firstTl = {}, ...restTl] = rowData.tls;
  const { designers = [] } = firstTl;
  const [firstDesigner, ...restDesigners] = designers;

  const designerCount = () => {
    const count =
      restTl?.reduce((acc, item) => {
        return acc + item?.designers?.length;
      }, 0) || 0;
    return count + restDesigners?.length;
  };

  const restCount = designerCount();

  return (
    <div>
      <span>{firstDesigner?.designer_id_text}</span>
      {restCount > 0 ? (
        <HtmlTooltip
          placement="top-end"
          arrow
          title={
            <div>
              {/* <ul style={{ paddingLeft: 15, margin: 0 }}>
                {restDesigners.map((d, index) => (
                  <li key={index}>{d.designer_id_text}</li>
                ))}
              </ul> */}
              {rowData.tls.length ? (
                <>
                  {/* <b>Rest All Team Leads and designers</b> */}
                  <ul style={{ paddingLeft: 15, margin: 0 }}>
                    {rowData.tls?.map((tl, index) => (
                      <li key={index}>
                        <b>{tl.tl_id_text || 'NA'}</b>
                        <ul>
                          {tl?.designers?.map((d, index) => (
                            <li key={index}>{d.designer_id_text}</li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </>
              ) : null}
            </div>
          }
        >
          <StyledBadge
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            badgeContent={
              <div style={{ display: "flex", alignItems: "center" }}>
                <Add />
                <b style={{ fontSize: 15 }}>{restCount}</b>
              </div>
            }
            color="secondary"
          >
            {/* <Add /> */}
          </StyledBadge>
        </HtmlTooltip>
      ) : null}
    </div>
  );
};
