export const actionTypes = {
  ADD_TOAST: 'ADD_TOAST',
  REMOVE_TOAST: 'REMOVE_TOAST',
  CLEAR_TOASTS: 'CLEAR_TOASTS'
}

const initialGeneralState = {
  toasts : []
};

export const reducer = (state = initialGeneralState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TOAST: {
      const { headerText, text, type, id } = action.payload;
      const toast = {
        headerText,
        text,
        type,
        id
      };
      const toasts = [...state.toasts, toast];
      return {
        ...state,
        toasts: toasts
      };
    }

    case actionTypes.REMOVE_TOAST: {
      const { id } = action.payload;
      const toasts = [...state.toasts];
      let toastIndex = toasts.findIndex((toast) => toast.id === id);
      if (toastIndex !== -1) {
        toasts.splice(toastIndex, 1);
      }
      return {
        ...state,
        toasts: toasts
      };
    }

    case actionTypes.CLEAR_TOASTS: {
      return {
        ...state,
        toasts: []
      }
    }

    default:
      return state;
  }
};

export const actions = {
  addToast: (headerText, text, type, id) => ({ type: actionTypes.ADD_TOAST, payload: { headerText, text, type, id } }),
  removeToast: (id) => ({ type: actionTypes.REMOVE_TOAST, payload: { id } }),
  clearToasts: () => ({ type: actionTypes.CLEAR_TOASTS })
}