/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import generator from 'generate-password';

export default function PasswordGenerator({ setAutoPassword }) {
  const [password, setPassword] = useState(null)
  const isStrongEnough = (password) => {
    const match = password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})/)
    return match
  }
  useEffect(() => {
    let newPassword = "";
    while (!isStrongEnough(newPassword)) {
      newPassword = generator.generate({
        length: 10,
        numbers: true,
        symbols: true,
        uppercase: true,
        lowercase: true
      });
    };
    setPassword(newPassword)
  }, [])
  return (
    <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", backgroundColor:"#DEE4E7", padding: "10px 7px 10px 7px", border: '0px', borderRadius: '0.42rem'}}>
      <p style={{marginBottom: "0rem", color: "grey"}}>{password}</p>
      <p
        style={{fontWeight: "bold", marginBottom: "0rem", cursor: "pointer"}}
        onClick={() => setAutoPassword(password)}
      >
        Use Password
      </p>
    </div>
  );
}