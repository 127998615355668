import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import ClientListing from "./pages/ClientListing";
import ClientEdit from "./pages/ClientEdit";
import Announcement from "./pages/Announcement";

function ClientSettingsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/client-settings"
          to="/client-settings/manage-clients"
        />
        <ContentRoute
          exact={true}
          path="/client-settings/manage-clients"
          component={ClientListing}
        />
        <ContentRoute
          path="/client-settings/manage-clients/:id"
          component={ClientEdit}
        />
        <ContentRoute
          path="/client-settings/announcement"
          component={Announcement}
        />
      </Switch>
    </div>
  );
}

export default ClientSettingsPage;
