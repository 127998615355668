import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import ManageUsers from "./pages/ManageUsers";
import ManageRoles from "./pages/ManageRoles";

function UserSettingsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/user-settings"
          to="/user-settings/manage-users"
        />
        <ContentRoute
          path="/user-settings/manage-users"
          component={ManageUsers}
        />
        <ContentRoute
          path="/user-settings/manage-roles"
          component={ManageRoles}
        />
      </Switch>
    </div>
  );
}

export default UserSettingsPage;
