import React from "react";
import { Modal } from "react-bootstrap";

import TeamDialogHeader from "./TeamDialogHeader";
import { TeamForm } from "./TeamForm";

export default function TeamDialog({ id, onHide, show, edit, saveUser }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <TeamDialogHeader userEdit={edit} id={id} />
      <TeamForm
        id={id}
        actionsLoading={false}
        onHide={onHide}
        edit={edit}
      />
    </Modal>
  );
}
