import React from "react";
import { Modal } from "react-bootstrap";

import RoleDialogHeader from "./RoleDialogHeader";
import { RoleForm } from "./RoleForm";

export default function RoleDialog({ id, onHide, show, edit }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <RoleDialogHeader userEdit={edit} id={id} />
      <RoleForm id={id} actionsLoading={false} onHide={onHide} edit={edit} />
      {/* {edit ? 'Dialog edit' : 'Dialog display'} */}
    </Modal>
  );
}
