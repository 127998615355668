import { put, takeLatest, call, all } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_CLIENT_TYPE: "SET_CLIENT_TYPE",
  SET_CLIENT_STATUS: "SET_CLIENT_STATUS",
  SET_CLIENT_SELECTED_TYPE: "SET_CLIENT_SELECTED_TYPE",
  SET_CLIENT_SELECTED_STATUS: "SET_CLIENT_SELECTED_STATUS",
  SET_COMPANIES: "SET_COMPANIES",
  SET_USER_STATUS: "SET_USER_STATUS",
  SET_COMPANIES_DROPDOWN: "SET_COMPANIES_DROPDOWN",
  SET_USER_STATUS_DROPDOWN: "SET_USER_STATUS_DROPDOWN",
  SET_CURRENT_PLAN_DROPDOWN: "SET_CURRENT_PLAN_DROPDOWN",
  SET_CURRENT_PLAN: "SET_CURRENT_PLAN",
  SET_REQUEST_STATUS_DROPDWON: "SET_REQUEST_STATUS_DROPDWON",
  SET_REQUEST_STATUS: "SET_REQUEST_STATUS",
  SET_PM_DROPDOWN: "SET_PM_DROPDOWN",
  SET_PM: "SET_PM",
  SET_DESIGNER_DROPDOWN: "SET_DESIGNER_DROPDOWN",
  SET_DESIGNER: "SET_DESIGNER",
  CREATED_ON_START: "CREATED_ON_START",
  CREATED_ON_END: "CREATED_ON_END",
  API_LOADING: "API_LOADING",
  GET_FILTERS: "GET_FILTERS",
  SET_FILTERS: "SET_FILTERS",
  DOWNLOAD_REPORT: "DOWNLOAD_REPORT",
  SET_EXCEL_DATA: "SET_EXCEL_DATA",
  CLEAR_REPORT_FILTER: "CLEAR_REPORT_FILTER"
};

const initialDesirnersState = {
    reportsFilters: [],
    clientTypeDropDown:[],
    clientStatusDropDown:[],
    clientSelectedType: [],
    clientSelectedStatus: null,
    companiesDropDown: [],
    companies:[],
    userStatusDropDown: [],
    userStatus: null,
    currentPlanDropDown:[],
    currentPlan: [],
    requestStatusDropDown:[],
    requestStatus:null,
    pmDropDown:[],
    pm:[],
    designerDropDown:[],
    designer:[],
    createdOnStartDate: "",
    createdOnEndDate: "",
    excelData: null,
    apiLoading: false,
};

export const reducer = (state = initialDesirnersState, action) => {
  switch (action.type) {
    case actionTypes.SET_CLIENT_TYPE: {
      return {
        ...state,
        clientTypeDropDown: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_STATUS: {
      return {
        ...state,
        clientStatusDropDown: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_SELECTED_TYPE: {
      return {
        ...state,
        clientSelectedType: action.payload.data,
      };
    }

    case actionTypes.SET_CLIENT_SELECTED_STATUS: {
      return {
        ...state,
        clientSelectedStatus: action.payload.data,
      };
    }

    case actionTypes.SET_COMPANIES_DROPDOWN: {
      let modifyKeys = [];
      action.payload.data.forEach(element => {
        modifyKeys.push({label:element.name,value:element.id})
      });
      return {
        ...state,
        companiesDropDown: modifyKeys,
      };
    }

    case actionTypes.SET_USER_STATUS_DROPDOWN: {
      return {
        ...state,
        userStatusDropDown: action.payload.data,
      };
    }

    case actionTypes.SET_COMPANIES: {
      return {
        ...state,
        companies: action.payload.data,
      };
    }

    case actionTypes.SET_USER_STATUS: {
      return {
        ...state,
        userStatus: action.payload.data,
      };
    }

    case actionTypes.SET_CURRENT_PLAN_DROPDOWN: {
      let modifyKeys = [];
      action.payload.data.forEach(element => {
        modifyKeys.push({label:element.name,value:element.id})
      });
      return {
        ...state,
        currentPlanDropDown: modifyKeys,
      };
    }

    case actionTypes.SET_CURRENT_PLAN: {
      return {
        ...state,
        currentPlan: action.payload.data,
      };
    }

    case actionTypes.SET_REQUEST_STATUS_DROPDWON: {
      let modifyKeys = [];
      action.payload.data.forEach(element => {
        modifyKeys.push({label:element.name,value:element.id})
      });
      return {
        ...state,
        requestStatusDropDown: modifyKeys,
      };
    }

    case actionTypes.SET_REQUEST_STATUS: {
      return {
        ...state,
        requestStatus: action.payload.data,
      };
    }

    case actionTypes.SET_PM_DROPDOWN: {
      let modifyKeys = [];
      action.payload.data.forEach(element => {
        modifyKeys.push({label:element.name,value:element.id})
      });
      return {
        ...state,
        pmDropDown: modifyKeys,
      };
    }

    case actionTypes.SET_PM: {
      return {
        ...state,
        pm: action.payload.data,
      };
    }

    case actionTypes.SET_DESIGNER_DROPDOWN: {
      let modifyKeys = [];
      action.payload.data.forEach(element => {
        modifyKeys.push({label:element.name,value:element.id})
      });
      return {
        ...state,
        designerDropDown: modifyKeys,
      };
    }

    case actionTypes.SET_DESIGNER: {
      return {
        ...state,
        designer: action.payload.data,
      };
    }

    case actionTypes.CREATED_ON_START: {
      return {
        ...state,
        createdOnStartDate: action.payload.data,
      };
    }

    case actionTypes.CREATED_ON_END: {
      return {
        ...state,
        createdOnEndDate: action.payload.data,
      };
    }

    case actionTypes.SET_FILTERS: {
      return {
        ...state,
        reportsFilters: action.payload.data,
      };
    }

    case actionTypes.SET_EXCEL_DATA: {
      return {
        ...state,
        excelData: action.payload.data,
      };
    }

    case actionTypes.API_LOADING: {
      return {
        ...state,
        apiLoading: action.payload.data,
      };
    }

    case actionTypes.CLEAR_REPORT_FILTER: {
      return {
        ...state,
        clientSelectedType:[],
        clientSelectedStatus:null,
        companies:[],
        userStatus:null,
        currentPlan:[],
        requestStatus:null,
        pm:[],
        designer:[],
        createdOnStartDate: "",
        createdOnEndDate: "",
        apiLoading: false,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setClientType: (data) => ({
    type: actionTypes.SET_CLIENT_TYPE,
    payload: { data },
  }),
  setClientStatus: (data) => ({
    type: actionTypes.SET_CLIENT_STATUS,
    payload: { data },
  }),
  setClientSelectedType: (data) => ({
    type: actionTypes.SET_CLIENT_SELECTED_TYPE,
    payload: { data },
  }),
  setClientSelectedStatus: (data) => ({
    type: actionTypes.SET_CLIENT_SELECTED_STATUS,
    payload: { data },
  }),
  setCompaniesDropDown: (data) => ({
    type: actionTypes.SET_COMPANIES_DROPDOWN,
    payload: { data },
  }),
  setUserStatusDropDown: (data) => ({
    type: actionTypes.SET_USER_STATUS_DROPDOWN,
    payload: { data },
  }),
  setCompanies: (data) => ({
    type: actionTypes.SET_COMPANIES,
    payload: { data },
  }),
  setUserStatus: (data) => ({
    type: actionTypes.SET_USER_STATUS,
    payload: { data },
  }),
  setCurrentPlanDropDown: (data) => ({
    type: actionTypes.SET_CURRENT_PLAN_DROPDOWN,
    payload: { data },
  }),
  setCurrentPlan: (data) => ({
    type: actionTypes.SET_CURRENT_PLAN,
    payload: { data },
  }),
  setRequestStatusDropdown: (data) => ({
    type: actionTypes.SET_REQUEST_STATUS_DROPDWON,
    payload: { data },
  }),
  setRequestStatus: (data) => ({
    type: actionTypes.SET_REQUEST_STATUS,
    payload: { data },
  }),
  setPmDropDown: (data) => ({
    type: actionTypes.SET_PM_DROPDOWN,
    payload: { data },
  }),
  setPm: (data) => ({
    type: actionTypes.SET_PM,
    payload: { data },
  }),
  setDeignerDropDown: (data) => ({
    type: actionTypes.SET_DESIGNER_DROPDOWN,
    payload: { data },
  }),
  setDeigner: (data) => ({
    type: actionTypes.SET_DESIGNER,
    payload: { data },
  }),
  setCreatedOnStartDate: (data) => ({
    type: actionTypes.CREATED_ON_START,
    payload: { data },
  }),
  setCreatedOnEndDate: (data) => ({
    type: actionTypes.CREATED_ON_END,
    payload: { data },
  }),
  setFilters: (data) => ({
    type: actionTypes.SET_FILTERS,
    payload: { data },
  }),
  getFilters: (accessToken) => ({
    type: actionTypes.GET_FILTERS,
    payload: { accessToken },
  }),
  setApiLoading: (data) => ({
    type: actionTypes.API_LOADING,
    payload: { data },
  }),
  setExcelData: (data) => ({
    type: actionTypes.SET_EXCEL_DATA,
    payload: { data },
  }),
  downloadExcel: (data, accessToken) => ({
    type: actionTypes.DOWNLOAD_REPORT,
    payload: { data, accessToken },
  }),
  clearReportFilter: () => ({
    type: actionTypes.CLEAR_REPORT_FILTER,
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.GET_FILTERS, getFiltersSaga);
  yield takeLatest(actionTypes.DOWNLOAD_REPORT, downloadExcelSaga);
}

function* getFiltersSaga(action) {
  let { accessToken } = action.payload;
  yield all([
    put(actions.setApiLoading(true)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_FILTER_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setFilters(responseData.data)),
          put(actions.setApiLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.setApiLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.setApiLoading(false)),
        ]);
        yield put(
          generalActions.addToast(
            "Get Filters",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([
        put(actions.setApiLoading(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Get Filters",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setApiLoading(false)),
    ]);
    yield put(
      generalActions.addToast(
        "Get Filters",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* downloadExcelSaga(action) {
  let { data, accessToken } = action.payload;
  yield all([
    put(actions.setApiLoading(true)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("DOWNLOAD_EXCEL_URL"),
      accessToken,
      queryString.stringify(data)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.text]);
      if (responseData) {
         const link = document.createElement("a");
        link.href = responseData
        link.setAttribute("download", "Report.xlsx");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        yield all([
          put(actions.setApiLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setApiLoading(false))]);
        yield put(
          generalActions.addToast(
            "Download Excel",
            errorData.message,
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.setApiLoading(false))]);
      yield put(
        generalActions.addToast(
          "Download Excel",
          errorData.message,
          "error",
          uid()
        )
      );
      yield all([
        put(actions.setApiLoading(false)),
      ]);
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setApiLoading(false)),
    ]);
  }
}