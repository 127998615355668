import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import ManageDesigners from "./pages/ManageDesigners";
import ManageTeams from "../TeamSettings/pages/ManageTeams";

function DesignerSettingsPage() {
  return (
    <div>
      <Switch>
        <Redirect
          exact={true}
          from="/designer-settings"
          to="/designer-settings/manage-designers"
        />
        <ContentRoute
          path="/designer-settings/manage-designers"
          component={ManageDesigners}
        />
        <Redirect
          exact={true}
          from="/designer-settings"
          to="/designer-settings/manage-teams"
        />
        <ContentRoute
          path="/designer-settings/manage-teams"
          component={ManageTeams}
        />
      </Switch>
    </div>
  );
}

export default DesignerSettingsPage;
